import React, { createContext, useEffect, useReducer } from 'react';
import { PublicClientApplication } from '@azure/msal-browser';
import { loginRequest, msalConfig } from './authConfig';
import { callMsGraph, callMsGraphProfilePhoto, callMsGraphSecurityGroups } from './graph';
import { getUserInfo } from './Utilities/msalUtilities';

const initialAuthState = {
  isAuthenticated: false,
  isInitialized: true,
  isAuthorized: false,
  user: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALIZE': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialized: true,
        isAuthorized: false,
        user: user,
      };
    }
    case 'LOGIN': {
      const { user, isAuthorized } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        isAuthorized: isAuthorized,
        user: user,
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        isAuthorized: false,
        user: null,
      };
    }
    default: {
      return { ...state, isInitialized: false };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  loginWithRedirect: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const instance = new PublicClientApplication(msalConfig);

  const HandleLogin = async () => {
    await instance.handleRedirectPromise();
    if (state.isInitialized) {
      if (!state.user) {
        await instance.loginRedirect();
      }
      dispatch({
        payload: {
          isAuthenticated: true,
        },
      });
    }
  };

  useEffect(async () => {
    var serverTelemetry = JSON.parse(
      localStorage.getItem(Object.keys(localStorage).filter((x) => localStorage.getItem(x).startsWith('{"failedRequests'))[0])
    );
    if (serverTelemetry?.failedRequests.length > 0) {
      localStorage.clear();
    }
    const initialize = async () => {
      if (state.user) {
        dispatch({
          payload: {
            isAuthenticated: true,
          },
        });
        return;
      }

      try {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: state.user,
          },
        });
        await getUserInfo().then(async (response) => {
          await dispatch({
            type: 'LOGIN',
            payload: {
              user: response.user,
              isAuthorized: response.isAuthorized,
            },
          });
        });
      } catch (error) {
        await HandleLogin();
      }
    };
    await initialize();
  }, []);

  const handleLogout = async () => {
    await instance.handleRedirectPromise();
    await instance.logoutRedirect({
      postLogoutRedirectUri: '/',
    });
    dispatch({
      payload: {
        isAuthenticated: false,
        user: null,
      },
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        loginWithRedirect: () => HandleLogin(),
        logout: () => handleLogout(),
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
export default AuthContext;
