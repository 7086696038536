import React, { useState } from 'react';
import BasicConfirmationModal from './ConfirmationModal';
import CopyAllOutlinedIcon from '@mui/icons-material/CopyAllOutlined';
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import { Tooltip, Grid } from '@mui/material';

export default function BasicDeleteModal({
  name,
  modalType,
  tipBody,
  warningMessage,
  setShowModal,
  showModal,
  confirmedDeleteAction,
  referenceText,
  message,
  isSwitch,
}) {
  const [isCopied, setIsCopied] = useState(false);
  const handleCopyClick = async () => {
    await navigator.clipboard.writeText(referenceText);
    setIsCopied(true);
  };

  return (
    <>
      <BasicConfirmationModal
        modalHeader={<>Delete {`${modalType}`}</>}
        modalBody={
          <>
            <Grid container>
              <Grid item xs={11} align="left">
                <p>{message ? message : `Are you sure you want to delete the ${modalType} "${name}"?`}</p>
              </Grid>
              <Grid item xs={1} align="left">
                {!isCopied ? (
                  <Tooltip title={`copy ${modalType} `} placement="top">
                    <CopyAllOutlinedIcon
                      fontSize="large"
                      onClick={() => {
                        handleCopyClick();
                      }}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip title={`copied !`} placement="top">
                    <TaskOutlinedIcon fontSize="large" />
                  </Tooltip>
                )}
              </Grid>
            </Grid>
          </>
        }
        tipBody={tipBody ? tipBody : ""}
        warningMessage={warningMessage}
        setShowModal={setShowModal}
        showModal={showModal}
        referenceText={referenceText}
        confirmedAction={confirmedDeleteAction}
        isDelete={true}
        isSwitch={isSwitch}
      />
    </>
  );
}
