/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardBody, CardTitle, Col, Spinner } from 'reactstrap';
import { useCookies } from 'react-cookie';
import { generateAndSaveAmazonRefreshToken } from '../Utilities/ForemanApiClient';
import { AMAZON_ADVERTISING_API_REDIRECT_URI } from '../api-config';
import HarvestLogo from '../resources/harvest_logomark.svg';

export default function AmazonAdvertisingApiRefreshToken() {
  const [isLoading, setIsLoading] = useState(true);
  const [refreshToken, setRefreshToken] = useState(null);

  const [cookies] = useCookies(['credentialId']);
  const credentialId = cookies.credentialId;
  const requestParameters = new URLSearchParams(window.location.search);
  const oauthCode = requestParameters.get('code');

  const genericErrorMessage =
    'ERROR: Something went wrong when trying to authenticate Harvest Group to access data on your behalf.';
  const successMessage = 'Harvest Group can now access data on your behalf. Please keep the following code for your records:';

  const handleRefreshTokenGeneration = async () => {
    setRefreshToken(
      await generateAndSaveAmazonRefreshToken(
        credentialId,
        oauthCode,
        AMAZON_ADVERTISING_API_REDIRECT_URI,
        'AmazonAdvertisingApi'
      )
    );
  };

  useEffect(() => {
    handleRefreshTokenGeneration();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (refreshToken) {
      setIsLoading(false);
    }
  }, [refreshToken]);

  return (
    <>
      <div className="content compact-centered">
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">
                Authorize Harvest Group
                <img className="float-right" alt="Logo" src={HarvestLogo} />
              </CardTitle>
            </CardHeader>
            <CardBody className="form-modal-body">
              {isLoading ? (
                <Spinner className="form-button-spinner" color="primary" />
              ) : (
                <div>
                  {refreshToken ? (
                    <div id="advertising-api-result-message-container">
                      <p>{successMessage}</p>
                      <p className="float-bottom word-wrap border border-dark">{refreshToken}</p>
                    </div>
                  ) : (
                    <p>{genericErrorMessage}</p>
                  )}
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </div>
    </>
  );
}
