import BackupTableOutlinedIcon from '@mui/icons-material/BackupTableOutlined';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import EditNoteIcon from '@mui/icons-material/EditNote';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import HealthAndSafetyOutlinedIcon from '@mui/icons-material/HealthAndSafetyOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import PlaylistPlayOutlinedIcon from '@mui/icons-material/PlaylistPlayOutlined';
import RuleOutlinedIcon from '@mui/icons-material/RuleOutlined';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import React from 'react';
import TaskGroupAssignmentDetails from './V2/Components/dacq/task-assignment-details/taskAssignmentDetails';
import Credentials from './V2/Pages/credentials/credentials';
import ManageTaskGroups from './V2/Pages/dacq/manageTaskGroups';
import TaskGroupsWeeklyView from './V2/Pages/dacq/taskGroupsWeeklyView';
import DataRuns from './V2/Pages/data-runs/dataRuns';
import HealthCheck from './V2/Pages/health-check/healthCheck';
import JobGroups from './V2/Pages/job-groups/jobGroups';
import JobRuns from './V2/Pages/job-runs/jobRuns';
import JobTemplates from './V2/Pages/job-templates/jobTemplates';
import JobsExtraInfo from './V2/Pages/jobs-extraInfo/JobsExtraInfo';
import LogEntries from './V2/Pages/log-entries/LogEntries';
import RunningJobs from './V2/Pages/running-jobs/runningJobs';
import UploadFiles from './V2/Pages/upload-files/uploadFiles';
import DacqDashboard from './V2/Pages/dacq/dashboard/DacqDashboard';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import { ClaimDacqTaskPage } from './V2/Pages/dacq/dacq_member_task/ClaimDacqTaskPage';
import DacqMemberTasksSummary from './V2/Pages/dacq/dacq_member_task/DacqMemberTasksSummary';

const formatDate = () => {
  const today = new Date();
  const month = today.getMonth() + 1;
  const day = today.getDate();
  const year = today.getFullYear();
  return `${month}-${day}-${year}`;
};

const routesV2 = [
  {
    path: '/clientmanager',
    name: 'Client Manager',
    icon: <ManageAccountsOutlinedIcon />,
    component: Credentials,
    layout: '/V2',
    isAccessibleOnSidebar: true,
  },
  {
    path: '/uploadfiles',
    name: 'Upload Files',
    icon: <UploadFileIcon />,
    component: UploadFiles,
    layout: '/V2',
    isAccessibleOnSidebar: true,
  },
  {
    path: '/jobgroups',
    name: 'Job Groups',
    icon: <BackupTableOutlinedIcon />,
    component: JobGroups,
    layout: '/V2',
    isAccessibleOnSidebar: true,
  },
  {
    path: '/jobtemplates',
    name: 'Job Templates',
    icon: <InsertDriveFileOutlinedIcon />,
    component: JobTemplates,
    layout: '/V2',
    isAccessibleOnSidebar: true,
  },
  {
    path: '/runningjobs',
    name: 'Running Jobs',
    icon: <PlaylistPlayOutlinedIcon />,
    component: RunningJobs,
    layout: '/V2',
    isAccessibleOnSidebar: true,
  },
  {
    path: '/jobruns',
    name: 'Job Runs',
    icon: <RuleOutlinedIcon />,
    component: JobRuns,
    layout: '/V2',
    isAccessibleOnSidebar: true,
  },
  {
    path: '/jobruns/:jobRunId',
    name: 'Job Runs',
    icon: <RuleOutlinedIcon />,
    component: JobRuns,
    layout: '/V2',
    isAccessibleOnSidebar: false,
  },
  {
    path: '/dataruns/:id/:startTime',
    name: 'Data Runs',
    icon: <DriveFolderUploadOutlinedIcon />,
    component: DataRuns,
    layout: '/V2',
    isAccessibleOnSidebar: false,
  },
  {
    path: '/healthcheck',
    name: 'Health Check',
    icon: <HealthAndSafetyOutlinedIcon />,
    component: HealthCheck,
    layout: '/V2',
    isAccessibleOnSidebar: true,
  },
  {
    path: '/log-entries/:id/:jobId?/:jobName?',
    name: 'Log Entries',
    component: LogEntries,
    layout: '/V2',
    isAccessibleOnSidebar: false,
  },
  {
    path: '/job-extraInfo/:jobId/:jobName/:jobRunId?',
    name: 'Job Insights',
    component: JobsExtraInfo,
    layout: '/V2',
    isAccessibleOnSidebar: false,
  },
  {
    path: '/clientmanager/:parentClientId?/:childClientId?',
    name: 'Client Manager',
    icon: <ManageAccountsOutlinedIcon />,
    component: Credentials,
    layout: '/V2',
    isAccessibleOnSidebar: false,
  },
  {
    path: '/dacq/taskGroups',
    name: 'Task Groups',
    icon: <CalendarMonthIcon />,
    component: TaskGroupsWeeklyView,
    layout: '/V2',
    isAccessibleOnSidebar: true,
  },
  {
    path: '/dacq/taskGroups/details/:date',
    name: 'Task Group Assignment Details',
    icon: <CalendarMonthIcon />,
    component: TaskGroupAssignmentDetails,
    layout: '/V2',
    isAccessibleOnSidebar: false,
  },
  {
    path: '/dacq/manageTaskGroups',
    name: 'Manage Task Groups',
    icon: <EditNoteIcon />,
    component: ManageTaskGroups,
    layout: '/V2',
    isAccessibleOnSidebar: true,
  },
  {
    path: '/dacq/memberTasks',
    name: `My Work for the day : ${formatDate()}`,
    icon: <FormatListNumberedIcon />,
    component: ClaimDacqTaskPage,
    layout: '/V2',
    isAccessibleOnSidebar: true,
  },
  {
    path: '/dacq/memberTasks/summary',
    name: `Tasks summary for  : ${formatDate()}`,
    icon: <FormatListNumberedIcon />,
    component: DacqMemberTasksSummary,
    layout: '/V2',
    isAccessibleOnSidebar: false,
  },
  {
    path: '/dacq/dashboard',
    name: `Dashboard for : ${formatDate()}`,
    icon: <DashboardOutlinedIcon />,
    component: DacqDashboard,
    layout: '/V2',
    isAccessibleOnSidebar: true,
  },
];
export default routesV2;