import React, { useEffect, useState } from 'react';
import CustomModal from '../../Components/Modals/CustomModal';
import { getJobInterfaces } from '../../../Utilities/ForemanApiClient';
import JobTemplateForm from './jobTemplateForm';
import { allRequiredFieldsHaveValues } from '../../../Utilities/Utilities';

export default function JobTemplateFormModal(props) {
  const [selectedRetailer, setSelectedRetailer] = useState(null);
  const [jobInterfaces, setJobInterfaces] = useState([]);
  const [selectedInterface, setSelectedInterface] = useState({});
  const [originalJobTemplate, setOriginalJobTemplate] = useState({});
  const [modifiedJobTemplate, setModifiedJobTemplate] = useState({});
  const [isMetadataValid, setIsMetadataValid] = useState(true);
  const [harvester, setHarvester] = useState({});
  const [disableButtons, setDisableButtons] = useState(false);

  const {
    submit,
    handleCloseModal,
    isModalOpen,
    mode,
    jobTemplate,
    retailers,
    isSubmitting,
    setIsSubmitting,
    isCreateMode,
    isEditMode,
    isCopyMode,
  } = props;

  useEffect(() => {
    setOriginalJobTemplate(jobTemplate);
    setModifiedJobTemplate({ ...jobTemplate, name: isCopyMode ? 'Copy ' + jobTemplate.name : jobTemplate.name });
  }, [jobTemplate]);

  useEffect(() => {
    const fetchData = async () => {
      if (jobTemplate.retailerType) {
        const jobInterfaces = await getJobInterfaces(jobTemplate.retailerType);
        setJobInterfaces(jobInterfaces);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const className = `${jobTemplate.className}`;
    const harvesterObj = { value: className, label: className };
    setHarvester(harvesterObj);
  }, [jobTemplate.className, originalJobTemplate]);

  const handleRetailerSelection = async (retailer) => {
    setSelectedRetailer(retailer);
    setHarvester({});
    initializeJobInterfaces(retailer?.retailerType);
  };

  const initializeJobInterfaces = async (retailerId) => {
    const interfaces = await getJobInterfaces(retailerId);
    setJobInterfaces(interfaces);
  };

  const handleConfirm = async () => {
    setIsSubmitting(true);
    setDisableButtons(true);
    submit(modifiedJobTemplate);
    setIsSubmitting(false);
    setDisableButtons(false);
    handleCloseModal();
  };

  const getModifiedParameterOrDefault = (name) => {
    return (modifiedJobTemplate.parameterDetail && modifiedJobTemplate.parameterDetail[name]) || '';
  };

  const disabled =
    !isMetadataValid || disableButtons || !allRequiredFieldsHaveValues(selectedInterface, getModifiedParameterOrDefault);

  return (
    <>
      <CustomModal
        open={isModalOpen}
        handleClose={handleCloseModal}
        handleConfirm={handleConfirm}
        title={mode + ' Job Template'}
        isCreateMode={isCreateMode}
        isEditMode={isEditMode}
        isCopyMode={isCopyMode}
        selectedRetailer={selectedRetailer}
        retailers={retailers}
        selectedInterface={selectedInterface}
        disableButtons={disableButtons}
        isMetadataValid={isMetadataValid}
        modifiedJobTemplate={modifiedJobTemplate}
        disabled={disabled}
        isSubmitting={isSubmitting}
        confirmEnabled={true}
      >
        <JobTemplateForm
          mode={mode}
          jobTemplate={jobTemplate}
          retailers={retailers}
          harvester={harvester}
          setHarvester={setHarvester}
          selectedRetailer={selectedRetailer}
          jobInterfaces={jobInterfaces}
          handleRetailerSelection={handleRetailerSelection}
          modifiedJobTemplate={modifiedJobTemplate}
          setModifiedJobTemplate={setModifiedJobTemplate}
          selectedInterface={selectedInterface}
          setSelectedInterface={setSelectedInterface}
          isMetadataValid={isMetadataValid}
          setIsMetadataValid={setIsMetadataValid}
          disableButtons={disableButtons}
          setDisableButtons={setDisableButtons}
        />
      </CustomModal>
    </>
  );
}
