/* eslint-disable eqeqeq */
import React, { useState } from 'react';
import ReactDatetime from 'react-datetime';
import cronstrue from 'cronstrue';
import * as moment from 'moment';
import { Autocomplete, Grid, InputLabel, MenuItem, Select, Switch, TextField, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import ErrorIcon from '@mui/icons-material/Error';
import { styles } from './AdditionalPropertiesForm.styles';
import { isValidJson } from '../../Utils/Utilities';
import {
  interfaceBooleanFieldValue,
  interfaceInputBooleanOnChange,
  interfaceInputDateOnChange,
  interfaceInputEnumOnChange,
  interfaceInputFieldOnChange,
  interfaceInputFieldValue,
  interfaceInputMultiSelectOnChange,
} from '../../Utils/JobTemplateFormUtils';

export default function AdditionalPropertiesForm(props) {
  const { properties, modifiedJobTemplate, setDisableButtons, className, setModifiedJobTemplate, selectedInterface } = props;
  const { formType } = props;
  const [isValidDate, setIsValidDate] = useState(true);

  function validateQuartzCron(expression) {
    let isValid = false;
    try {
      cronstrue.toString(expression);
      isValid = true;
    } catch (error) {
      isValid = false;
    }
    return isValid;
  }

  const descriptiveCronExpression = () => {
    const cron = interfaceInputFieldValue('CronExpression', modifiedJobTemplate);
    if (cron) {
      const isValidCron = cron && validateQuartzCron(cron);
      const isDisabled = isValidCron ? false : true;
      setDisableButtons(isDisabled);

      return isValidCron ? (
        <>
          {`This Job will run ${cronstrue.toString(cron).replace('At', 'at').replace('Every', 'every')} (All times UTC)`}
          <br />
        </>
      ) : (
        <span className="warning-message">
          Invalid Cron Expression!
          <br />
        </span>
      );
    }
  };

  const descriptiveQueryParams = () => {
    const queryParam = interfaceInputFieldValue('QueryParameters', modifiedJobTemplate);

    if (queryParam && queryParam.length != 0) {
      if (!isValidJson(queryParam)) {
        setDisableButtons(true);
        return (
          <span className="warning-message">
            <div>Please provide a valid JSON value</div>
          </span>
        );
      }
      if (JSON.parse(queryParam).hasOwnProperty('marketplaceIds')) {
        setDisableButtons(true);
        return (
          <span className="warning-message">
            marketplaceIds should not be included in the queryParameters for
            <strong> {className}</strong> .
          </span>
        );
      }
    }
    setDisableButtons(false);
  };

  const descriptiveReportOptions = () => {
    const reportOptions = interfaceInputFieldValue('ReportOptions', modifiedJobTemplate);
    return !reportOptions || isValidJson(reportOptions) ? (
      ''
    ) : (
      <span className="warning-message">
        ReportOptions must be valid JSON (do <strong>not</strong> include the "ReportOptions" key found in the documentation
        examples)
        <br />
      </span>
    );
  };

  const descriptiveStartDate = () => {
    const startDate = interfaceInputFieldValue('StartDate', modifiedJobTemplate);

    return startDate && isValidDate ? (
      ''
    ) : (
      <span className="warning-message">
        Invalid Start Date!
        <br />
      </span>
    );
  };

  const interfacePropertiesExtraInfo = {
    QueryParameters: {
      specialText: (
        <Typography color="default" tag="span">
          {descriptiveQueryParams()}
        </Typography>
      ),
    },
    CronExpression: {
      specialText: (
        <Typography color="default" tag="span">
          {descriptiveCronExpression()}
          Need help building a Cron Expression? Try{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.freeformatter.com/cron-expression-generator-quartz.html"
            alt="none"
          >
            FreeFormatter
          </a>
          .
        </Typography>
      ),
    },
    StartDate: {
      specialText: (
        <Typography color="default" tag="span">
          {descriptiveStartDate()}
        </Typography>
      ),
    },
    ShouldObserveDaylightSavingsTime: {
      label: 'ObserveDST',
    },
    ReportOptions: {
      specialText: (
        <Typography color="default" tag="span">
          {descriptiveReportOptions()}
          Necessary report options for each report type can be found in the &nbsp;
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://developer-docs.amazon.com/sp-api/docs/report-type-values"
            alt="none"
          >
            Selling Partner API Documentation
          </a>
          .
        </Typography>
      ),
    },
  };

  const isConflicting = (parameter) => {
    const mustNotExistProperty = properties.find((prop) => prop.name == parameter);

    if (
      formType == 'Job' &&
      mustNotExistProperty != undefined &&
      mustNotExistProperty.defaultValue != undefined &&
      mustNotExistProperty.defaultValue != '' &&
      mustNotExistProperty.defaultValue != 'false'
    ) {
      return true;
    }

    return (
      interfaceInputFieldValue(parameter, modifiedJobTemplate) != '' &&
      interfaceInputFieldValue(parameter, modifiedJobTemplate) != 'false'
    );
  };

  const shouldDisableField = (mustNotExist) => {
    if (mustNotExist) {
      const mustNotExistList = mustNotExist.split(',');
      return mustNotExistList.some((parameter) => {
        return isConflicting(parameter);
      });
    }

    return false;
  };

  const getConflicts = (mustNotExist) => {
    let conflicts = [];

    if (mustNotExist) {
      const mustNotExistList = mustNotExist.split(',');
      conflicts = mustNotExistList.filter((parameter) => {
        return isConflicting(parameter);
      });
    }

    return conflicts;
  };

  const isRequiredValue = (requiredField, requiredValue) => {
    const requiredIfProperty = properties.find((prop) => prop.name == requiredField);

    if (
      formType == 'Job' &&
      requiredIfProperty != undefined &&
      requiredIfProperty.defaultValue != undefined &&
      requiredIfProperty.defaultValue != '' &&
      requiredIfProperty.defaultValue != 'false' &&
      requiredValue == interfaceInputFieldValue(requiredField, modifiedJobTemplate)
    ) {
      return true;
    }

    return (
      interfaceInputFieldValue(requiredField, modifiedJobTemplate) != '' &&
      interfaceInputFieldValue(requiredField, modifiedJobTemplate) != 'false'
    );
  };

  const shouldRequireField = (requiredIf) => {
    if (requiredIf) {
      const requiredField = requiredIf.key;
      const requiredValue = requiredIf.value;
      return isRequiredValue(requiredField, requiredValue);
    }
  };

  const interfaceEnumFieldValue = (property) => {
    const value = interfaceInputFieldValue(property.name, modifiedJobTemplate);
    return { value: value || property.defaultValue, label: value || property.defaultValue };
  };

  const interfaceMultiSelectFieldValue = (property) => {
    let value = interfaceInputFieldValue(property.name, modifiedJobTemplate) || property.defaultValue;

    if (!value) {
      return [];
    }

    return value.split(',').map((item) => ({
      value: item,
      label: item,
    }));
  };

  const interfaceDateTimeInputField = (property) => {
    return (
      <ReactDatetime
        disabled={shouldDisableField(property.mustNotExist)}
        inputProps={{
          className: 'form-control',
          placeholder: 'yyyy-mm-dd',
        }}
        value={
          interfaceInputFieldValue(property.name, modifiedJobTemplate)
            ? new Date(interfaceInputFieldValue(property.name, modifiedJobTemplate))
            : null
        }
        onChange={(value) => {
          const date = moment(value, 'YYYY-MM-DD', true);
          setIsValidDate(date.isValid());

          if (date.isValid()) {
            interfaceInputDateOnChange(property.name, date.toDate(), setModifiedJobTemplate, modifiedJobTemplate);
          }
        }}
        dateFormat="YYYY-MM-DD"
        timeFormat={false}
      />
    );
  };

  const interfaceEnumInputField = (property) => {
    return (
      <Select
        sx={styles.Inputs}
        className="react-select primary"
        disabled={shouldDisableField(property.mustNotExist)}
        name="singleSelect"
        value={interfaceEnumFieldValue(property).value || ''}
        onChange={(value, event) => {
          interfaceInputEnumOnChange(property.name, event, setModifiedJobTemplate, modifiedJobTemplate);
        }}
      >
        {property.enumValues
          .filter((item) => item != 'NotSet')
          .map((item) => (
            <MenuItem key={'item_' + item} id={item} value={item}>
              {item}
            </MenuItem>
          ))}
      </Select>
    );
  };

  const interfaceMultiSelectInputField = (property) => {
    return (
      <>
        <Autocomplete
          sx={styles.Inputs}
          multiple
          filterSelectedOptions
          disableCloseOnSelect
          id="tags-outlined"
          options={property.selectAllThatApply || ''}
          getOptionLabel={(option) => option || ''}
          value={modifiedJobTemplate.parameterDetail[property.name]?.split(',') || []}
          onChange={(value, event) => {
            interfaceInputMultiSelectOnChange(property.name, event, setModifiedJobTemplate, modifiedJobTemplate);
          }}
          className="basic-multi-select"
          renderInput={(params) => <TextField {...params} label="" />}
        />
      </>
    );
  };

  const interfaceBooleanInputField = (property) => {
    if (modifiedJobTemplate.parameterDetail[property.name] === undefined) {
      setModifiedJobTemplate({
        ...modifiedJobTemplate,
        parameterDetail: {
          ...modifiedJobTemplate.parameterDetail,
          [property.name]: property.defaultValue,
        },
      });
    }
    return (
      <Switch
        sx={{ m: 1 }}
        checked={interfaceBooleanFieldValue(property.name, modifiedJobTemplate, selectedInterface)}
        value={interfaceBooleanFieldValue(property.name, modifiedJobTemplate, selectedInterface)}
        disabled={shouldDisableField(property.mustNotExist)}
        onChange={() =>
          interfaceInputBooleanOnChange(property.name, modifiedJobTemplate, selectedInterface, setModifiedJobTemplate)
        }
      />
    );
  };

  const interfaceDefaultInputField = (property) => {
    const disabled = shouldDisableField(property.mustNotExist);
    const defaultValue = disabled ? property.defaultValue : '';

    return (
      <TextField
        id="outlined-number"
        sx={styles.Inputs}
        type={property.type.includes('Int') ? 'number' : 'text'}
        placeholder={defaultValue !== null ? defaultValue.toString() : ''}
        autoComplete="off"
        disabled={disabled}
        value={interfaceInputFieldValue(property.name, modifiedJobTemplate)}
        onChange={(e) => interfaceInputFieldOnChange(property.name, e, setModifiedJobTemplate, modifiedJobTemplate)}
      />
    );
  };

  const interfacePropertyInputField = (property) => {
    if (property.selectAllThatApply && property.selectAllThatApply.length > 0) {
      return interfaceMultiSelectInputField(property);
    }
    if (property.type === 'DateTime') {
      return interfaceDateTimeInputField(property);
    }
    if (property.enumValues && property.enumValues.length > 0) {
      return interfaceEnumInputField(property);
    }
    if (property.type === 'Boolean') {
      return interfaceBooleanInputField(property);
    }
    return interfaceDefaultInputField(property);
  };

  return (
    <>
      {properties.map((prop) => (
        <React.Fragment key={prop.name}>
          <Grid item xs={2} align="left">
            <InputLabel htmlFor="format-input" sx={{ display: 'contents' }}>
              {(interfacePropertiesExtraInfo[prop.name] && interfacePropertiesExtraInfo[prop.name].label) || prop.name}
              {!shouldDisableField(prop.mustNotExist) ? (
                !shouldRequireField(prop.requiredIf) ? (
                  <>
                    <Tooltip title={`${prop.required ? 'REQUIRED: ' : ''}${prop.description}`} arrow placement="right">
                      <InfoIcon sx={prop.required ? styles.critical : styles.info} />
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <Tooltip
                      title={`${prop.description} (This parameter is required if ${prop.requiredIf.key} is set to ${prop.requiredIf.value}
                      .)`}
                      arrow
                      placement="right"
                    >
                      <ErrorIcon sx={styles.alert} />
                    </Tooltip>
                  </>
                )
              ) : (
                <>
                  <Tooltip
                    title={`This parameter is disabled because it cannot exist alongside the following parameter(s):
                    ${getConflicts(prop.mustNotExist).join(', ')}`}
                    arrow
                    placement="right"
                  >
                    <ErrorIcon sx={styles.alert} />
                  </Tooltip>
                </>
              )}
            </InputLabel>
          </Grid>

          <Grid item xs={10} align="left">
            {interfacePropertyInputField(prop)}
          </Grid>

          <Grid item xs={12} align="left">
            <Typography sx={{ ml: 32 }}>
              {interfacePropertiesExtraInfo[prop.name] && interfacePropertiesExtraInfo[prop.name].specialText}
            </Typography>
          </Grid>
        </React.Fragment>
      ))}
    </>
  );
}
