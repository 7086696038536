import { getDesignTokens } from '../../Theme/theme';

const designTokens = getDesignTokens(localStorage.getItem('mode'));
export const taskGroupWeeklyViewStyles = {
    dayCardWrapper: {
        textDecoration: 'none',
        display: 'flex',
        flexDirection: 'column',
        gap: '2rem',
        cursor: 'pointer',
        padding: '0.7rem',
        borderRadius: '0.5rem',
        maxHeight: '75vh',
        width: '100%',
        minWidth: '250px',
        backgroundColor: designTokens.palette.taskGroupColors.grey
    },
    cardsWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1.5rem',
        width: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
        scrollbarWidth: 'thin',
    },
};