import { loginRequest } from '../authConfig';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from '../authConfig';
import { callMsGraph, callMsGraphProfilePhoto, callMsGraphSecurityGroups } from '../graph';

const instance = new PublicClientApplication(msalConfig);

export async function getUserInfo() {
  var user;
  var isAuthorized;
  await instance
    .acquireTokenSilent({
      ...loginRequest,
      account: instance.getAllAccounts()[0],
    })
    .then(async (response) => {
      await callMsGraph(response.accessToken).then((response) => (user = response));
      await callMsGraphProfilePhoto(response.accessToken).then((response) => {
        user.avatar = response;
      });
      await callMsGraphSecurityGroups(response.accessToken).then((response) => {
        user.groups = response.value.map((group) => group.displayName);
      });
      isAuthorized = user.groups.includes('Foreman Admins');
      if (localStorage.getItem('auth') !== response.accessToken) {
        localStorage.setItem('auth', response.accessToken);
      }
    });
  return { user, isAuthorized };
}

export async function saveFreshAccessTokenInLocalStorage() {
  await instance
    .acquireTokenSilent({
      ...loginRequest,
      account: instance.getAllAccounts()[0],
    })
    .then(async (response) => {
      localStorage.setItem('auth', response.accessToken);
    });
}
