import React, { useState, useEffect } from 'react';
import CustomModal from '../../Components/Modals/CustomModal';
import JobGroupForm from './JobGroupForm';

export default function JobGroupsFormModal({
  open,
  handleCloseModal,
  mode,
  isCreateMode,
  isEditMode,
  isCopyMode,
  jobGroup,
  submitJobGroupForm,
  retailers,
  submit,
}) {
  const [ModifiedJobGroup, setModifiedJobGroup] = useState({});
  const [selectedRetailer, setSelectedRetailer] = useState(null);

  const handleConfirm = async () => {
    submit(ModifiedJobGroup);
    handleCloseModal();
  };

  useEffect(() => {
    setModifiedJobGroup(jobGroup);
  }, [jobGroup]);

  const handleRetailerSelection = async (retailer) => {
    setSelectedRetailer(retailer);
  };
  return (
    <CustomModal
      open={open}
      handleClose={handleCloseModal}
      handleConfirm={handleConfirm}
      title={mode + ' Job Group'}
      isCreateMode={isCreateMode}
      isEditMode={isEditMode}
      isCopyMode={isCopyMode}
      retailerType={jobGroup?.retailerType}
      selectedRetailer={selectedRetailer}
      retailers={retailers}
      confirmEnabled={true}
    >
      <JobGroupForm
        mode={mode}
        isCreateMode={isCreateMode}
        isEditMode={isEditMode}
        jobGroup={jobGroup}
        handleRetailerSelection={handleRetailerSelection}
        submitJobGroupForm={submitJobGroupForm}
        ModifiedJobGroup={ModifiedJobGroup}
        setModifiedJobGroup={setModifiedJobGroup}
        selectedRetailer={selectedRetailer}
        retailers={retailers}
      />
    </CustomModal>
  );
}
