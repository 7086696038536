import { Button, Grid, Toolbar, Tooltip, Typography, alpha } from '@mui/material';
import React, { useState, useEffect } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { styles } from './Table.styles';
import DeleteMultipleModal from '../DeleteComponent/DeleteMultipleModal';

function TableToolbar({
  selected,
  setSelected,
  showModal,
  setShowModal,
  modalType,
  warningMessage,
  handleDeleteMultiple,
  filteredItems,
}) {
  const [selectedItems, setSelectedItems] = useState([]);
  useEffect(async () => {
    let items = [];
    await selected.map((item) => {
      items.push(filteredItems.filter((filteredItem) => filteredItem.id === item)?.[0]);
    });
    setSelectedItems(items.reverse());
  }, [selected]);

  return (
    <>
      {selected?.length > 0 && (
        <Toolbar
          sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
            ...(selected?.length > 0 && {
              bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
            }),
          }}
        >
          <Grid container rowSpacing={0}>
            <Grid item xs={10} align="left">
              <Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle1" component="div">
                {selected.length} selected
              </Typography>
            </Grid>
            <Grid item xs={2} align="right">
              <Tooltip title="Delete">
                <Button
                  variant="contained"
                  sx={styles.CreateButton}
                  startIcon={<DeleteIcon />}
                  onClick={() => setShowModal(true)}
                >
                  DELETE ALL
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </Toolbar>
      )}
      {showModal && (
        <DeleteMultipleModal
          showModal={showModal}
          setShowModal={setShowModal}
          isMultipleDelete={true}
          modalType={modalType}
          warningMessage={warningMessage}
          handleDeleteMultiple={handleDeleteMultiple}
          selectedItems={selectedItems}
          selected={selected}
          setSelected={setSelected}
        />
      )}
    </>
  );
}

export default TableToolbar;
