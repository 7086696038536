import { Button, Divider, Grid, Paper, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getDataRuns, getJobRuns } from '../../../Utilities/ForemanApiClient';
import { BasicTable } from '../../Components/Table/Table';
import { jobRunsColumns } from '../../Components/TableColumns/jobRunsColumns';
import ShareIcon from '@mui/icons-material/Share';
import RuleOutlinedIcon from '@mui/icons-material/RuleOutlined';
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import CloudDownloadTwoToneIcon from '@mui/icons-material/CloudDownloadTwoTone';
import { dataRunsColumns } from '../../Components/TableColumns/dataRunsColumns';
import EmptyBox from '../../Assets/EmptyBox';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import { styles } from './jobsExtraInfo.styles';
import RotateLeftOutlinedIcon from '@mui/icons-material/RotateLeftOutlined';
import moment from 'moment';
import CustomModal from '../../Components/Modals/CustomModal';
import DateRangeSelector from '../../Components/DateRangeSelector/DateRangePicker';
import { getDate, downloadArrayDataCSV } from '../../Utils/JobRunUtilities';
import { filterTableData } from '../../Utils/filterTableData';

function JobsExtraInfo() {
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedJobRun, setSelectedJobRun] = useState(null);
  const [dataRuns, setDataRuns] = useState(undefined);
  const [sortDirection, setSortDirection] = useState('ascending');
  const [sort, setSort] = useState('');
  const [filters, setFilters] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [searchValues, setSearchValues] = useState({});
  const [isJobRunsLoading, setIsJobRunsLoading] = useState(true);
  const [isDataRunsLoading, setIsDataRunsLoading] = useState(true);
  const [isCopied, setIsCopied] = useState(false);
  const [startDate, setStartDate] = useState(moment(new Date()).format('MM-DD-YYYY'));
  const [endDate, setEndDate] = useState(moment(new Date()).format('MM-DD-YYYY'));
  const [timer, setTimer] = useState(null);
  const [filteredJobRuns, setFilteredJobRuns] = useState(null);
  const [exportLoading, setExportLoading] = useState(false);
  const [filteredDataRuns, setFilteredDataRuns] = useState(null);
  const [dataRunsSearchValues, setDataRunsSearchValues] = useState({});
  const offset = page * rowsPerPage;
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  const params = useParams();
  const jobId = params.jobId;
  const jobName = params.jobName;
  const selectedJobRunId = params.jobRunId;

  const LoadDataRuns = async (jobRunId) => {
    const drfilters = selectedJobRunId ? `&jobRunId=${selectedJobRunId}` : `&jobRunId=${jobRunId}`;
    const response = await getDataRuns(startDate, endDate, rowsPerPage, offset, drfilters, '', 'Ascending', false);
    setDataRuns(response.records);
    setFilteredDataRuns(response.records);
    setIsDataRunsLoading(false);
  };

  const filterJobRuns = () => {
    let filters = searchValues
      ? Object.entries(searchValues)
          .map(([key, value]) => {
            if (value?.length > 0) return `&${key}=${value}`;
          })
          .join('')
      : '';
    if (!filters.includes('jobId')) filters += `&jobId=${jobId}`;
    return filters;
  };

  useEffect(async () => {
    clearTimeout(timer);

    const newTimer = setTimeout(async () => {
      setIsJobRunsLoading(true);
      let response = await getFilteredRuns(rowsPerPage, page * rowsPerPage, filterJobRuns(), '', 'Ascending');
      if (response) {
        setCount(response.totalFilteredRecords ? response.totalFilteredRecords : 0);
        setFilteredJobRuns(response.records);
      }
      setIsJobRunsLoading(false);
    }, 500);

    setTimer(newTimer);
  }, [searchValues, page, rowsPerPage]);

  useEffect(() => {
    const tableAsArray = filterTableData(dataRuns, dataRunsSearchValues);
    setFilteredDataRuns(tableAsArray);
  }, [dataRunsSearchValues]);

  useEffect(async () => {
    if (selectedJobRunId) {
      await LoadDataRuns(selectedJobRunId);
    }
  }, [selectedJobRunId]);

  const getRunsForDownload = async () => {
    setExportLoading(true);
    const detailedStartDate = getDate(startDate);
    const detailedEndDate = getDate(endDate);

    await getJobRuns(startDate, endDate, 0, 0, filters, sort, sortDirection, false).then((response) => {
      downloadArrayDataCSV(
        false,
        response.records,
        `JobRuns_${detailedStartDate.year}${detailedStartDate.month}${detailedStartDate.day}_${detailedEndDate.year}${detailedEndDate.month}${detailedEndDate.day}.csv`
      );
    });
    setExportLoading(false);
  };

  const handleCloseCalendar = () => {
    setShowCalendar(false);
  };

  const handleSelectRange = async () => {
    setIsJobRunsLoading(true);

    let response = await getFilteredRuns(rowsPerPage, page * rowsPerPage, filterJobRuns(), '', 'Ascending');

    if (response) {
      setCount(response.totalFilteredRecords ? response.totalFilteredRecords : 0);
      setFilteredJobRuns(response.records);
    }

    setIsJobRunsLoading(false);
    setShowCalendar(false);
  };

  const getFilteredRuns = async (limit, offset, paginationFilters, paginationSort, paginationSortDirection, excludeFilter) => {
    setSort(paginationSort);
    setSortDirection(paginationSortDirection);
    setFilters(paginationFilters);

    return await getJobRuns(
      startDate,
      endDate,
      limit,
      offset,
      paginationFilters,
      paginationSort,
      paginationSortDirection,
      excludeFilter ? excludeFilter : false
    );
  };

  const clearSelection = () => {
    setSelectedJobRun(null);
  };

  const handleCopyClick = async () => {
    await navigator.clipboard.writeText(window.location.href + `/${selectedJobRun?.id}`);
    setIsCopied(true);
  };
  return (
    <>
      <Grid container rowSpacing={0}>
        <Grid item xs={12} align="left">
          <Typography variant="h5" sx={{ mt: 2 }}>
            {jobId} - {jobName}
            <Tooltip title={isCopied ? `copied !` : `Copy page URL!`} placement="top">
              <ShareIcon
                onClick={() => {
                  handleCopyClick();
                }}
              />
            </Tooltip>
          </Typography>
        </Grid>
        <Grid item xs={12} align="left">
          <Divider sx={{ mt: 2, mb: 1 }} />
        </Grid>
        <Grid item xs={10} align="right">
          <CloudDownloadTwoToneIcon
            onClick={() => {
              !exportLoading && getRunsForDownload();
            }}
            sx={{ cursor: 'pointer' }}
          />
        </Grid>

        <Grid item xs={10} align="left">
          <Paper elevation={0}>
            <Typography sx={{ mt: 1 }}>
              <RuleOutlinedIcon sx={{ mr: 1 }} />
              JOB RUNS
            </Typography>
            <BasicTable
              data={filteredJobRuns}
              columns={jobRunsColumns}
              actionButtons={null}
              setSearchValues={setSearchValues}
              searchValues={searchValues}
              isLoading={isJobRunsLoading}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              page={page}
              setPage={setPage}
              count={count}
              isFullHeight={true}
              hasSelectionCheckboxes={false}
              noPagination={false}
              hasMoreInfo={true}
              selectedProperty={selectedJobRun}
              setSelectedProperty={setSelectedJobRun}
              handleMoreInfoOnClick={LoadDataRuns}
              extraInfoParams={{ jobId: params.jobId, jobName: params.jobName, jobRunId: params.jobRunId }}
            />
          </Paper>
        </Grid>
        <Grid item xs={2}>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 1 }}>
            <Button
              variant="contained"
              sx={styles.CalendarButton}
              startIcon={<EditCalendarIcon />}
              onClick={() => setShowCalendar(true)}
            >
              Select Dates
            </Button>
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 1 }}>
            <Button
              variant="contained"
              sx={styles.CalendarButton}
              startIcon={<RotateLeftOutlinedIcon />}
              onClick={() => {
                clearSelection();
              }}
            >
              Clear Selection
            </Button>
          </Grid>
        </Grid>

        {selectedJobRunId || (selectedJobRun && dataRuns) ? (
          <>
            <Grid item xs={12} align="right" sx={{ mt: 3 }}>
              <CloudDownloadTwoToneIcon />
            </Grid>
            <Grid item xs={12} align="left">
              <Paper elevation={0}>
                <Typography sx={{ mt: 1 }}>
                  <DriveFolderUploadOutlinedIcon sx={{ mr: 1 }} />
                  DATA RUNS : Job Run {selectedJobRun?.id}
                </Typography>
                <BasicTable
                  data={filteredDataRuns}
                  columns={dataRunsColumns}
                  actionButtons={null}
                  setSearchValues={setDataRunsSearchValues}
                  searchValues={dataRunsSearchValues}
                  isLoading={isDataRunsLoading}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  page={page}
                  setPage={setPage}
                  count={count}
                  hasSelectionCheckboxes={false}
                  noPagination={true}
                  isFullHeight={true}
                  extraInfoParams={{ jobId: params.jobId, jobName: params.jobName, jobRunId: params.jobRunId }}
                />
              </Paper>
            </Grid>
          </>
        ) : (
          <Grid item xs={12} align="right" sx={{ mt: 3 }}>
            <EmptyBox />
          </Grid>
        )}
      </Grid>
      <CustomModal
        open={showCalendar}
        handleClose={handleCloseCalendar}
        handleConfirm={handleSelectRange}
        title={'Select Date Range'}
        isCalendarMode={true}
        customSize={'sm'}
        confirmEnabled={true}
      >
        <DateRangeSelector
          setDateRange={setDateRange}
          dateRange={dateRange}
          setEndDate={setEndDate}
          setStartDate={setStartDate}
        />
      </CustomModal>
    </>
  );
}

export default JobsExtraInfo;
