import { Box, Typography } from '@mui/material';
import React from 'react';
import './claimButtonStyles.css';
import DacqTaskClaimButton from './DacqTaskClaimButton';
import { grey } from '@mui/material/colors';


export default function NoDacqTasksComponent({ onCLaimClick }) {
  return (
    <Box display="flex"  flexDirection="column" justifyContent="center" alignItems="center" height="100%" width="100%" py={8}>
      <Typography variant="h5" color="textSecondary" align="center" fontWeight={600}>
        There are no tasks assigned to you at the moment.
      </Typography>
      <Typography variant="h6" textTransform={'none'} color={grey} align="center" mb={2}>
        Please click on the button below to claim a task.
      </Typography>
      <DacqTaskClaimButton onClick={onCLaimClick} buttonText={'Claim Task'} />
    </Box>
  );
}
