import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { BarChart } from '@mui/x-charts/BarChart';
import { PieChart } from '@mui/x-charts/PieChart';
import React, { useEffect, useState } from 'react';
import {
  getDacqMembers,
  getClaimedDacqTasksPerMember,
  getDacqTaskStatsCount,
  getDacqTasksClaimedPerDay,
  getDacqTasksPerRetailer,
} from '../../../../Utilities/ForemanApiClient';
import { stringAvatar, stringToColor } from '../taskGroupUtils';
import { retailers } from '../../../Assets/Retailers';
import { getRetailerColor } from '../../../Components/Icons/RetailerIcon';
import NoDataFound from '../../../Assets/NoDataFound';
import { ErrorComponent } from '../../../Components/dacq/errorComponent';
import DacqDashboardSkeleton from './DashboardSkeleton';
import { cardStyles } from '../../../Components/dacq/dacqDashboardStyles';
import DacqTaskStatsItem from '../dacq_member_task/MemberDacqTasksStatsItem';

export default function DacqDashboard() {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [tasksCount, setTasksCount] = useState({
    total: 0,
    completed: 0,
    blocked: 0,
  });
  const [tasksClaimedPerDay, setTasksClaimedPerDay] = useState([]);
  const [tasksClaimedPerMember, setTasksClaimedPerMember] = useState([]);
  const [dacqTasksPerRetailer, setDacqTasksPerRetailer] = useState([]);
  const [dacqMembersList, setDacqMembersList] = useState({});
  useEffect(() => {

    const fetchTaskData = async () => {
      setIsLoading(true);
      try {
        const [
          taskStatsCount,
          tasksClaimedPerDayResponse,
          tasksClaimedPerMemberResponse,
          dacqTasksPerRetailerResponse,
          dacqMembersListResponse,
        ] = await Promise.all([
          getDacqTaskStatsCount(),
          getDacqTasksClaimedPerDay(),
          getClaimedDacqTasksPerMember(),
          getDacqTasksPerRetailer(),
          getDacqMembers({
            memberEmails: [],
          }),
        ]);

        const taskStatusCountData = await taskStatsCount.json();
        const tasksClaimedPerDayData = await tasksClaimedPerDayResponse.json();
        const tasksClaimedPerMemberData = await tasksClaimedPerMemberResponse.json();
        const dacqTasksPerRetailerData = await dacqTasksPerRetailerResponse.json();
        const groupmembersList = await dacqMembersListResponse;

        setTasksCount(taskStatusCountData);
        setTasksClaimedPerDay(tasksClaimedPerDayData);
        setTasksClaimedPerMember(tasksClaimedPerMemberData);
        setDacqTasksPerRetailer(dacqTasksPerRetailerData);
        setDacqMembersList(groupmembersList);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTaskData();
  }, []);

  if (isLoading) {
    return <DacqDashboardSkeleton />;
  }

  if (error) {
    return <ErrorComponent />;
  }

  if (!tasksCount.total || !tasksClaimedPerMember.length) {
    return <NoDataFound />;
  }

  return (
    <Box padding={'3rem 2rem'} gap={4} display={'flex'} flexDirection={'column'}>
      <Box width="100%">
        <Grid container spacing={3} alignItems="stretch">
          <Grid item xs={12} md={8}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
              }}
            >
              <Grid container spacing={2}>
                {Object.entries(tasksCount).map(([key, value]) => (
                  <Grid item xs={12} sm={6} key={key}>
                    <DacqTaskStatsItem name={key} value={value} />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={12} md={4}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
              }}
            >
              <Card sx={{ ...cardStyles, flex: 1 }}>
                <CardContent
                  sx={{
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <Typography
                    variant="h6"
                    fontWeight={600}
                    textTransform={'none'}
                    sx={{
                      alignSelf: 'flex-start',
                      marginBottom: 2,
                    }}
                  >
                    Tasks Per Retailer
                  </Typography>
                  <PieChart
                    slotProps={{
                      legend: {
                        direction: 'column',
                        position: { vertical: 'middle', horizontal: 'right' },
                      },
                    }}
                    series={[
                      {
                        outerRadius: 70,
                        data: dacqTasksPerRetailer.map((item, index) => ({
                          id: item.retailerId,
                          value: item.count,
                          color: getRetailerColor(item.retailerId),
                          label: retailers.find((retailer) => retailer.retailerType === item.retailerId)?.retailerName,
                        })),
                      },
                    ]}
                    height={200}
                  />
                </CardContent>
              </Card>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box display={'flex'} justifyContent={'between'} gap={3} width={'100%'}>
        <Card sx={{ ...cardStyles, flex: 17, padding: '2rem', alignItems: 'start', gap: '1rem' }}>
          <Typography variant="h6" fontWeight={600} textTransform={'none'}>
            Tasks Claimed Per Day
          </Typography>
          <BarChart
            slotProps={{
              bar: {
                style: {
                  clipPath: 'none',
                  borderRadius: '20px',
                },
              },
            }}
            colors={['#1f3041']}
            xAxis={[{ scaleType: 'band', data: Object.keys(tasksClaimedPerDay).map((key) => key.toUpperCase()) }]}
            series={[{ data: Object.values(tasksClaimedPerDay) }]}
            height={500}
          />
        </Card>
        <Card sx={{ ...cardStyles, flex: 17, padding: '2rem', alignItems: 'start', gap: '1rem' }}>
          <Typography variant="h6" fontWeight={600} textTransform={'none'}>
            Tasks Claimed Per Member
          </Typography>
          <TableContainer>  
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>MEMBER</TableCell>
                  <TableCell>LAST CLAIM</TableCell>
                  <TableCell>TOTAL</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tasksClaimedPerMember
                  .sort((a, b) => new Date(b.lastClaimedAt) - new Date(a.lastClaimedAt))
                  .map((claim) => {
                    let memberName = '';
                    if (claim.member === 'souhail.krissaane@harvestgroup.com') {
                      memberName = 'Souhail Krissaane';
                    } else {
                      memberName = dacqMembersList.find((m) => m.email === claim.member).displayName;
                    }

                    return (
                      <TableRow key={claim.member}>
                        <TableCell>
                          <Box display="flex" alignItems="center" gap={2}>
                            <Avatar {...stringAvatar(memberName)} sx={{ bgcolor: stringToColor(memberName) }} />
                            <Typography fontWeight={600} display="inline">
                              {memberName}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Typography color={grey[600]}>{claim.lastClaimedAt}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography fontWeight={600}>{claim.count} tasks</Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Box>
    </Box>
  );
}