import React, { useRef, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Tooltip,
  DialogContentText,
  DialogActions,
  Slide,
  Typography,
} from '@mui/material';
import CopyAllOutlinedIcon from '@mui/icons-material/CopyAllOutlined';
import { UnControlled as CodeMirror } from 'react-codemirror2';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import { styles } from './LogentriesStyles';
//import 'codemirror/mode/javascript/javascript';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function LogEntriesDetails({ open, handleClose, logEntry }) {
  const requestHeadersRef = useRef(null);
  const requestParametersRef = useRef(null);
  const responseErrorRef = useRef(null);
  const responseBodyRef = useRef(null);
  const responseHeadersRef = useRef(null);

  const handleCopyClick = async (textToCopy) => {
    await navigator.clipboard.writeText(textToCopy);
  };

  useEffect(() => {
    if (requestHeadersRef.current) {
      const editor = requestHeadersRef.current.editor;
      const lines = editor.lineCount();
      const lineHeight = editor.defaultTextHeight();
      const totalHeight = (lines + 1) * lineHeight;

      editor.setSize(null, totalHeight);
    }
    if (requestParametersRef.current) {
      const editor = requestParametersRef.current.editor;
      const lines = editor.lineCount();
      const lineHeight = editor.defaultTextHeight();
      const totalHeight = (lines + 1) * lineHeight;

      editor.setSize(null, totalHeight);
    }
    if (requestHeadersRef.current) {
      const editor = requestHeadersRef.current.editor;
      const lines = editor.lineCount();
      const lineHeight = editor.defaultTextHeight();
      const totalHeight = (lines + 1) * lineHeight;

      editor.setSize(null, totalHeight);
    }
    if (responseErrorRef.current) {
      const editor = responseErrorRef.current.editor;
      const lines = editor.lineCount();
      const lineHeight = editor.defaultTextHeight();
      const totalHeight = (lines + 1) * lineHeight;

      editor.setSize(null, totalHeight);
    }
    if (responseBodyRef.current) {
      const editor = responseBodyRef.current.editor;
      const lines = editor.lineCount();
      const lineHeight = editor.defaultTextHeight();
      const totalHeight = (lines + 1) * lineHeight;

      editor.setSize(null, totalHeight);
    }
    if (responseHeadersRef.current) {
      const editor = responseHeadersRef.current.editor;
      const lines = editor.lineCount();
      const lineHeight = editor.defaultTextHeight();
      const totalHeight = (lines + 1) * lineHeight;

      editor.setSize(null, totalHeight);
    }
  }, [logEntry]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      fullWidth={'md'}
      maxWidth={'md'}
    >
      <DialogTitle sx={styles.background}>Log Entry Details</DialogTitle>
      <DialogContent sx={styles.background}>
        <DialogContentText id="alert-dialog-slide-description">
          <Typography sx={styles.text} variant="body1">
            <strong>Log entry ID:</strong> {logEntry?.id}
          </Typography>
          <hr />
          <Typography sx={styles.text} variant="body1">
            <strong>Message:</strong> {logEntry?.message}
          </Typography>
          <hr />
          {logEntry?.requestUrl && (
            <>
              <Typography sx={styles.text} variant="body1">
                <strong>Request URL:</strong> {logEntry?.requestUrl}
              </Typography>
              <hr />
            </>
          )}
          {logEntry?.requestHeaders && JSON.stringify(logEntry?.requestHeaders).length > 3 && (
            <>
              <Grid container>
                <Grid item xs={6} align="left">
                  <Typography sx={styles.text} variant="body1">
                    <strong>Request Headers:</strong>
                  </Typography>
                </Grid>
                <Grid item xs={6} align="right">
                  <Tooltip title={`copy`} placement="top">
                    <CopyAllOutlinedIcon
                      fontSize="large"
                      onClick={() => {
                        handleCopyClick(JSON.stringify(logEntry?.requestHeaders));
                      }}
                    />
                  </Tooltip>
                </Grid>
              </Grid>
              <CodeMirror
                ref={requestHeadersRef}
                value={JSON.stringify(logEntry?.requestHeaders, null, 2)}
                options={{
                  mode: 'json',
                  theme: 'material',
                  lineNumbers: true,
                }}
                onChange={(editor, data, value) => {}}
              />
              <hr />
            </>
          )}

          {logEntry?.requestParameters && JSON.stringify(logEntry?.requestParameters).length > 3 && (
            <>
              <Grid container>
                <Grid item xs={6} align="left">
                  <Typography sx={styles.text} variant="body1">
                    <strong>Request Parameters:</strong>
                  </Typography>
                </Grid>
                <Grid item xs={6} align="right">
                  <Tooltip title={`copy`} placement="top">
                    <CopyAllOutlinedIcon
                      fontSize="large"
                      onClick={() => {
                        handleCopyClick(JSON.stringify(logEntry?.requestParameters));
                      }}
                    />
                  </Tooltip>
                </Grid>
              </Grid>
              <CodeMirror
                ref={requestParametersRef}
                value={JSON.stringify(logEntry?.requestParameters, null, 2)}
                options={{
                  mode: 'json',
                  theme: 'material',
                  lineNumbers: true,
                }}
                onChange={(editor, data, value) => {}}
              />
              <hr />
            </>
          )}
          {logEntry?.responseError && JSON.stringify(logEntry?.responseError).length > 3 && (
            <>
              <Grid container>
                <Grid item xs={6} align="left">
                  <Typography sx={styles.text} variant="body1">
                    <strong>Response Error:</strong>
                  </Typography>
                </Grid>
                <Grid item xs={6} align="right">
                  <Tooltip title={`copy`} placement="top">
                    <CopyAllOutlinedIcon
                      fontSize="large"
                      onClick={() => {
                        handleCopyClick(JSON.stringify(logEntry?.responseError));
                      }}
                    />
                  </Tooltip>
                </Grid>
              </Grid>
              <CodeMirror
                ref={responseErrorRef}
                value={JSON.stringify(logEntry?.responseError, null, 2)}
                options={{
                  mode: 'json',
                  theme: 'material',
                  lineNumbers: true,
                }}
                onChange={(editor, data, value) => {}}
              />
              <hr />
            </>
          )}
          {logEntry?.responseBody && (
            <>
              <Grid container>
                <Grid item xs={6} align="left">
                  <Typography sx={styles.text} variant="body1">
                    <strong>Response Body:</strong>
                  </Typography>
                </Grid>
                <Grid item xs={6} align="right">
                  <Tooltip title={`copy`} placement="top">
                    <CopyAllOutlinedIcon
                      fontSize="large"
                      onClick={() => {
                        handleCopyClick(logEntry?.responseBody);
                      }}
                    />
                  </Tooltip>
                </Grid>
              </Grid>
              <CodeMirror
                ref={responseBodyRef}
                value={logEntry?.responseBody}
                options={{
                  mode: 'json',
                  theme: 'material',
                  lineNumbers: true,
                }}
                onChange={(editor, data, value) => {}}
              />
              <hr />
            </>
          )}
          {logEntry?.responseHeaders && JSON.stringify(logEntry?.responseHeaders).length > 3 && (
            <>
              <Grid container>
                <Grid item xs={6} align="left">
                  <Typography sx={styles.text} variant="body1">
                    <strong>Response Headers:</strong>
                  </Typography>
                </Grid>
                <Grid item xs={6} align="right">
                  <Tooltip title={`copy`} placement="top">
                    <CopyAllOutlinedIcon
                      fontSize="large"
                      onClick={() => {
                        handleCopyClick(JSON.stringify(logEntry?.responseHeaders));
                      }}
                    />
                  </Tooltip>
                </Grid>
              </Grid>
              <CodeMirror
                ref={responseHeadersRef}
                value={JSON.stringify(logEntry?.responseHeaders, null, 2)}
                options={{
                  mode: 'json',
                  theme: 'material',
                  lineNumbers: true,
                }}
                onChange={(editor, data, value) => {}}
              />
            </>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={styles.background}>
        <Button onClick={handleClose} variant="outlined" color="primary">
          CLOSE
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default LogEntriesDetails;
