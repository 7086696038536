export const logEntriesColumns = [
  {
    Header: 'log entry id',
    accessor: 'id',
    id: 'id',
    width: '6vw',
  },
  {
    Header: 'Created',
    accessor: 'created',
    id: 'created',
    width: '6vw',
  },
  {
    Header: 'Message',
    accessor: 'message',
    id: 'message',
    width: '9vw',
  },
  {
    Header: 'Response Code',
    accessor: 'responseCode',
    id: 'responseCode',
    width: '6vw',
  },
  {
    Header: 'More',
    accessor: 'actions',
    id: 'actions',
    sortable: false,
    filterable: false,
    width: '3vw',
  },
];
