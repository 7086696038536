export const styles = {
  CalendarButton: {
    bgcolor: 'button.primary',
    width: '30%',
    height: '100%',
  },
  DownloadButton: {
    bgcolor: 'button.primary',
    marginLeft: '5px',
    height: '100%',
  },
  rowText: {
    '&:not(:hover):not(:focus)': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      fontSize: 13,
    },
  },
  DownloadIcon: {
    marginLeft: '8px',
  },
};
