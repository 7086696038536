import React from 'react';
import { Chip, Tooltip, alpha, useTheme } from '@mui/material';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

export const CapacityChip = ({ value, minimumValue }) => {
  const theme = useTheme();
  const color = value < minimumValue ? theme.palette.taskGroupColors.red : theme.palette.taskGroupColors.success;

  return (
    <Tooltip
      style={{
        fontSize: '1rem',
        textTransform: 'none',

        fontWeight: '400',
      }}
      title={
        <h6
          style={{
            fontSize: '1rem',
            textTransform: 'none',
            fontWeight: '400',
          }}
        >
          {value < minimumValue ? `A minimum capacity of ${minimumValue} is required!` : 'Minimum capacity is satisfied!'}
        </h6>
      }
      placement="bottom"
    >
      <Chip
        sx={{
          color: color,
          backgroundColor: (_) => alpha(color, 0.05),
          borderRadius: '0.5rem',
          fontWeight: '600',
        }}
        icon={
          value < minimumValue ? <ErrorOutlineOutlinedIcon sx={{ fill: color }} /> : <TaskAltOutlinedIcon sx={{ fill: color }} />
        }
        label={`${value} / ${minimumValue}`}
      />
    </Tooltip>
  );
};
