import { BlobServiceClient } from '@azure/storage-blob';
import { gzipSync } from 'fflate';

export const containsProperty = (object, propertyName) => {
  return (
    object !== null &&
    object !== undefined &&
    object.hasOwnProperty(propertyName) &&
    object[propertyName] !== null &&
    object[propertyName] !== undefined
  );
};

export const isValidJson = (string) => {
  try {
    JSON.parse(string);
  } catch (e) {
    return false;
  }

  return true;
};

export const allRequiredFieldsHaveValues = (selectedInterface, interfaceInputFieldValue) => {
  if (!selectedInterface) {
    return false;
  }

  return (
    !selectedInterface.properties ||
    selectedInterface.properties.every(
      (property) =>
        (!property.required || interfaceInputFieldValue(property.name)) &&
        (property.requiredIf.key === '' ||
          interfaceInputFieldValue(property.requiredIf.key) != property.requiredIf.value ||
          interfaceInputFieldValue(property.name))
    )
  );
};

export const getDate = (string) => {
  return (([month, day, year]) => ({ day, month, year }))(string.split('-'));
};

const arrayToCSV = (objArray) => {
  const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;

  const str =
    array.length != 0
      ? `${Object.keys(array[0])
          .map((value) => `"${value}"`)
          .join(',')}\r\n'`
      : '';

  return array.reduce((str, next) => {
    str += `${Object.values(next)
      .map((value) => `"${value}"`)
      .join(',')}\r\n`;
    return str;
  }, str);
};

export const downloadArrayDataCSV = (isRunningJobs, array, downloadFileName) => {
  const csv = arrayToCSV(array);
  let newCsv = csv.replace("'", '');

  if (!isRunningJobs) {
    newCsv = newCsv
      .split('\n')
      .map(function (line) {
        const columns = line.split(',');
        columns.splice(2, 2);
        columns.splice(5, 1);
        columns.splice(8, 1);
        return columns;
      })
      .join('\n');
  }

  const data = new Blob([newCsv], { type: 'text/csv' });
  const csvURL = window.URL.createObjectURL(data);
  const tempLink = document.createElement('a');
  tempLink.href = csvURL;
  tempLink.setAttribute('download', downloadFileName);
  tempLink.click();
};
