export const styles = {
  CreateButton: {
    bgcolor: 'button.primary',
  },
  ActionButtons: {
    m: 0.5,
    '&:hover': {
      backgroundColor: 'actionButtons.primary',
      borderRadius: 2,
      color: 'white',
      p: 0.5,
    },
  },
  rowText: {
    '&:not(:hover):not(:focus)': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      fontSize: 13,
    },
  },
  Inputs: {
    m: 1,
    width: '99%',
    '& legend': { display: 'none' },
  },
  WarningIcon: {
    color: '#D60B00',
    fontSize: 'xx-large',
    height: '-webkit-fill-available',
  },
  background: {
    bgcolor: 'notificationMenu.primary',
  },
};
