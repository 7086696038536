import React, { useState, useEffect } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import {
  Chip,
  Box,
  Grid,
  Typography,
  Button,
  Autocomplete,
  TextField,
  FormControlLabel,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import {
  getManualJobCredentials,
  getManualJobsByCredentialId,
  EnqueueMultipleManualUploadJobs,
  enqueueManualUploadByJob,
  stopActiveAndFutureDataRunsByJobId,
} from '../../../Utilities/ForemanApiClient';
import { BasicTable } from '../../Components/Table/Table';
import { uploadFilesColumns } from '../../Components/TableColumns/uploadFilesColumns';
import { styles } from './UploadFilesStyles';
import UploadFilesActionButtons from '../../Components/TableActionButtons/UploadFilesActionButtons';
import { filterTableData } from '../../Utils/filterTableData';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import { showSnackbar } from '../../Utils/showSnackBar';
import { RefreshOutlined } from '@mui/icons-material';
import { useTimer } from '../../Context/UploadFilesTimerContext';
import { allRetailerServiceTypes } from '../../Utils/AllRetailerServiceTypes';
import cronstrue from 'cronstrue';
import ActiveAndFutureDataRunsModal from '../running-jobs/activeAndFutureDataRunsModal';

export default function UploadFiles() {
  const [credentials, setCredentials] = useState([]);
  const [filteredCredentials, setFilteredCredentials] = useState([]);
  const [areCredentialsLoading, setAreCredentialsLoading] = useState(false);
  const [selectedCredentials, setSelectedCredentials] = useState([]);
  const [searchValues, setSearchValues] = useState({});
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [selectedJobs, setSelectedJobs] = useState([]);
  const [retailerServiceTypes, setRetailerServiceTypes] = useState([]);
  const [selectedRetailerServiceTypes, setSelectedRetailerServiceTypes] = useState([]);
  const [showAllManualJobs, setShowAllManualJobs] = useState(false);
  const [showStopDataRunsModal, setShowStopDataRunsModal] = useState(false);
  const [selectedJob, setSelectedJob] = useState({});
  const [areCredentialsUpdating, setAreCredentialsUpdating] = useState(true);

  const { counter } = useTimer();
  const allowedClassName = ['ManualUploadHarvester', 'ManualUploadsHarvester'];

  const handleAddCredentials = async (credentials) => {
    setSelectedCredentials(credentials);
  };

  const handleRunJob = async (job) => {
    try {
      await enqueueManualUploadByJob(job.id);
      setFilteredJobs(
        filteredJobs.map((filteredJob) => (filteredJob.id === job.id ? { ...filteredJob, isRunning: true } : filteredJob))
      );
      setJobs(jobs.map((filteredJob) => (filteredJob.id === job.id ? { ...filteredJob, isRunning: true } : filteredJob)));
      showSnackbar('success', 'Job successfully enqueued');
    } catch (error) {
      showSnackbar(
        'error',
        `Job enqueue unsuccessful. Please try again later; if the issue persists, contact Dev : foremandev@harvestgroup.com.`
      );
    }
  };

  const handleStopActiveAndFutureDataRuns = async (job) => {
    setSelectedJob(job);
    setShowStopDataRunsModal(true);
  };

  const handleBulkStopActiveAndFutureDataRuns = async () => {
    const response = await stopActiveAndFutureDataRunsByJobId(selectedJob.id);

    if (!response) {
      showSnackbar(
        'error',
        `Could not stop all active/future Data Runs for Job Id ${selectedJob?.id}. Ensure the Job exists or contact Dev : foremandev@harvestgroup.com if the issue persists.`
      );
    } else {
      showSnackbar(
        'success',
        `Job Run: ${selectedJob?.jobRunId} has been canceled successfully, all active/future Data Runs are stopped.`
      );
      const updatedJobList = filteredJobs.filter((item) => item.id !== selectedJob.id);
      setFilteredJobs(updatedJobList);
      setJobs(updatedJobList);
      setSelectedJob({});
      setShowStopDataRunsModal(false);
    }
  };

  const handleRunSelectedJobs = async () => {
    try {
      await EnqueueMultipleManualUploadJobs(selectedJobs);

      setFilteredJobs(
        filteredJobs.map((job) => {
          if (selectedJobs.indexOf(job.id) !== -1) {
            job.isRunning = true;
          }
          return job;
        })
      );
      setJobs(
        jobs.map((job) => {
          if (selectedJobs.indexOf(job.id) !== -1) {
            job.isRunning = true;
          }
          return job;
        })
      );
      showSnackbar('success', 'Jobs successfully enqueued');

      setSelectedJobs([]);
    } catch (error) {
      showSnackbar(
        'error',
        `Jobs enqueue unsuccessful. Please try again later; if the issue persists, contact Dev : foremandev@harvestgroup.com.`
      );
    }
  };

  useEffect(() => {
    setSearchValues({});
    setCredentials([]);
    setFilteredCredentials([]);
    setSelectedCredentials([]);
    setSelectedJobs([]);
    setJobs([]);
    setFilteredJobs([]);
    setSelectedRetailerServiceTypes([]);
    setRetailerServiceTypes([]);
  }, [showAllManualJobs]);

  useEffect(async () => {
    setAreCredentialsLoading(true);
    const allCredentials = await getManualJobCredentials(showAllManualJobs);
    setCredentials(allCredentials);

    let existantRetailerServiceTypes = await allCredentials.map((credential) => credential.retailerServiceType);

    setRetailerServiceTypes(
      allRetailerServiceTypes.filter((retailerServiceType) => existantRetailerServiceTypes.includes(retailerServiceType.value))
    );

    setAreCredentialsLoading(false);
  }, [showAllManualJobs]);

  useEffect(async () => {
    if (selectedRetailerServiceTypes.length > 0) {
      let retailerServiceTypeIds = await selectedRetailerServiceTypes.map((retailerServiceType) => retailerServiceType.value);
      const credentialsByRetailer = await credentials.filter((credential) =>
        retailerServiceTypeIds.includes(credential.retailerServiceType)
      );
      setFilteredCredentials(credentialsByRetailer);
    } else {
      setFilteredCredentials([]);
    }
  }, [selectedRetailerServiceTypes]);

  const getJobsByCredentialIds = async () => {
    let allJobs = [];

    for (const credential of selectedCredentials) {
      const result = await getManualJobsByCredentialId(credential?.id);
      await result.forEach((item) => {
        let index = allJobs.findIndex((job) => job.id === item.id);
        if (index === -1) {
          allJobs.push(item);
        } else {
          allJobs.splice(index, 1);
        }
      });
    }

    allJobs = allJobs.filter(
      (job) =>
        allowedClassName.includes(job.jobTemplate.className) &&
        (showAllManualJobs || (job.isRunning && job.status != 'completed'))
    );
    setJobs(allJobs);
    setFilteredJobs(allJobs);
  };

  const handleSelectRetailer = (retailerServiceType) => {
    if (selectedRetailerServiceTypes.includes(retailerServiceType)) {
      setSelectedRetailerServiceTypes(selectedRetailerServiceTypes.filter((item) => item !== retailerServiceType));
    } else {
      setSelectedRetailerServiceTypes([...selectedRetailerServiceTypes, retailerServiceType]);
    }
  };

  const hideMultipleRunButton = () => {
    let result = false;
    selectedJobs.forEach((jobId) => {
      let job = jobs.find((job) => job.id === jobId);
      if (job.status != 'failed') {
        result = true;
      }
    });
    return result;
  };

  useEffect(() => {
    getJobsByCredentialIds();

    const interval = setInterval(getJobsByCredentialIds, 60000);

    return () => clearInterval(interval);
  }, [counter]);

  useEffect(async () => {
    if (selectedCredentials.length > 0) {
      setAreCredentialsUpdating(true);
      await getJobsByCredentialIds();
      setAreCredentialsUpdating(false);
    }
  }, [selectedCredentials]);

  useEffect(() => {
    const tableAsArray = filterTableData(jobs, searchValues);
    setFilteredJobs(tableAsArray);
  }, [searchValues]);

  const actionButtons = (job) => {
    return (
      <UploadFilesActionButtons
        job={job}
        handleRunJob={handleRunJob}
        handleStopActiveAndFutureDataRuns={handleStopActiveAndFutureDataRuns}
      />
    );
  };

  return (
    <>
      <Grid container spacing={2} sx={{ marginTop: '0.5rem' }}>
        {areCredentialsLoading ? (
          <Grid item xs={12}>
            <Box sx={{ width: '100%', marginTop: '5%' }}>
              <LinearProgress />
            </Box>
          </Grid>
        ) : (
          <>
            <Grid container>
              {retailerServiceTypes && (
                <Grid item xs={10.5} align="left">
                  {retailerServiceTypes.map((retailerServiceType) => (
                    <Chip
                      key={retailerServiceType.value}
                      label={retailerServiceType.label || ''}
                      onClick={() => handleSelectRetailer(retailerServiceType)}
                      variant={!selectedRetailerServiceTypes.includes(retailerServiceType) ? 'outlined' : 'default'}
                      sx={styles.RetailerChip}
                    />
                  ))}
                </Grid>
              )}
              <Grid item xs={1.5} align="right">
                <FormControlLabel
                  value="bottom"
                  control={<Switch color="primary" />}
                  checked={showAllManualJobs}
                  onChange={() => setShowAllManualJobs(!showAllManualJobs)}
                  label="show all"
                  labelPlacement="left"
                />
              </Grid>
            </Grid>
            {retailerServiceTypes && filteredCredentials && (
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  id="searchable-select"
                  options={filteredCredentials.sort((a, b) => a.childClient.name.localeCompare(b.childClient.name))}
                  value={selectedCredentials || []}
                  disableCloseOnSelect={true}
                  onChange={(event, newValue) => {
                    handleAddCredentials(newValue);
                  }}
                  isOptionEqualToValue={(option, value) => option === value}
                  getOptionLabel={(option) => `${option.childClient.name} - ${option.username}`}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search and select credentials"
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: <>{params.InputProps.endAdornment}</>,
                      }}
                    />
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip key={index} label={`${option.childClient.name} - ${option.username}`} {...getTagProps({ index })} />
                    ))
                  }
                />
              </Grid>
            )}
          </>
        )}
        {selectedCredentials.length > 0 ? (
          <>
            <Grid container rowSpacing={1} columnSpacing={1} sx={{ p: 1 }}>
              <Grid item xs={8.5}>
                <Typography variant="h6">Jobs</Typography>
                {filteredJobs.length != 0 && <Typography>Showing {filteredJobs.length} Records</Typography>}
              </Grid>

              <Grid item xs={2}>
                {selectedJobs.length === 0 ||
                  (!hideMultipleRunButton() && (
                    <Button
                      variant="contained"
                      size="md"
                      sx={styles.RunButton}
                      startIcon={<PlayCircleFilledWhiteOutlinedIcon />}
                      onClick={() => {
                        handleRunSelectedJobs();
                      }}
                    >
                      Run Selected Jobs
                    </Button>
                  ))}
              </Grid>
              <Grid item xs={1.5} align="right">
                <Button
                  variant="contained"
                  size="md"
                  sx={styles.RunButton}
                  startIcon={<RefreshOutlined />}
                  onClick={() => {
                    getJobsByCredentialIds();
                  }}
                >
                  Refresh
                </Button>
              </Grid>
            </Grid>

            <BasicTable
              data={filteredJobs}
              columns={uploadFilesColumns}
              actionButtons={actionButtons}
              setSearchValues={setSearchValues}
              searchValues={searchValues}
              noPagination={true}
              isLoading={areCredentialsUpdating}
              hasSelectionCheckboxes={showAllManualJobs}
              selected={selectedJobs}
              setSelected={setSelectedJobs}
              isFullHeight={false}
              fromManualPage={true}
            />
          </>
        ) : (
          <>
            <Grid container spacing={1} sx={styles.SelectContainer}>
              <Grid item xs={12} align="center">
                <AdsClickIcon sx={styles.SelectCredential} />
              </Grid>
              <Grid item xs={12} align="center">
                <Typography sx={styles.SelectText}>Select a Credential</Typography>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>

      <Dialog
        open={showStopDataRunsModal}
        onClose={() => setShowStopDataRunsModal(false)}
        aria-labelledby="alert-delete-title"
        aria-describedby="alert-delete-description"
      >
        <DialogTitle id="alert-delete">{'Stop Job Run?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This will stop all active and future Data Runs for the Job Run Id {selectedJob?.jobRunId}. Do you wish to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowStopDataRunsModal(false);
              setSelectedJob({});
            }}
          >
            Cancel
          </Button>

          <Button
            onClick={() => {
              handleBulkStopActiveAndFutureDataRuns();
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
