import React from 'react';
import { Avatar, AvatarGroup, Tooltip } from '@mui/material';
import { stringAvatar, stringToColor } from '../../Pages/dacq/taskGroupUtils';

const CustomAvatarGroup = ({ max, list, alignment = "end" }) => {
  
  return (
    <AvatarGroup max={max} sx={{ '&.MuiAvatarGroup-root': { justifyContent: alignment } }}>
      {list.map((displayName, index) => {
        return (
          <Tooltip key={index} title={displayName}>
            <Avatar {...stringAvatar(displayName)} sx={{ bgcolor: stringToColor(displayName) }} />
          </Tooltip>
        );
      })}
    </AvatarGroup>
  );
};

export default CustomAvatarGroup;
