import React, { useState, useEffect } from 'react';
import { Grid, Typography, TextField, FormControlLabel, Switch, Alert, MenuItem, Select, FormControl, FormHelperText } from '@mui/material';
import { updateChildClient, createChildClient, getAmazonRegionOptions } from '../../../Utilities/ForemanApiClient';
import { showSnackbar } from '../../Utils/showSnackBar';
import CustomModal from '../../Components/Modals/CustomModal';
import Checkbox from '@mui/material/Checkbox';
import { containsProperty } from '../../Utils/Utilities';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { styles } from '../job-groups/jobGroupStyles';

export default function ChildClientForm({
  childClient,
  parentClient,
  mode,
  retailers,
  open,
  setShowChildClientFormModal,
  handleClose,
  setChildClients,
  childClients,
  setSelectedChildClient,
}) {
  const [modifiedChildClient, setModifiedChildClient] = useState({});
  const [disableButtons, setDisableButtons] = useState(mode === 'create');
  const [selectedRetailer, setSelectedRetailer] = useState({});
  const [selectedAmazonRegion, setSelectedAmazonRegion] = useState({});
  const [originalChildClient, setOriginalChildClient] = useState({});
  const [amazonRegionOptions, setAmazonRegionOptions] = useState([]);
  const [changedName, setChangedName] = useState(false);

  useEffect(() => {
    const foundRetailer = retailers.find((retailer) => retailer.id == originalChildClient?.retailerId);
    foundRetailer && setSelectedRetailer({ value: foundRetailer, label: foundRetailer.name });

    setSelectedAmazonRegion(
      amazonRegionOptions.filter((region) => {
        return region.value === originalChildClient?.amazonRegion;
      })?.[0]?.value
    );
  }, [originalChildClient]);

  useEffect(async () => {
    const options = await getAmazonRegionOptions();
    setAmazonRegionOptions(options);
    setOriginalChildClient(childClient);
    setModifiedChildClient(childClient);
  }, []);

  const submitChildClientForm = async () => {
    setDisableButtons(true);
    let response = false;

    modifiedChildClient.name = modifiedChildClient.name.trim();

    if (mode == 'edit') {
      response = await updateChildClient(modifiedChildClient);
    } else {
      response = await createChildClient(modifiedChildClient);
    }

    if (!response) {
      showSnackbar(
        'error',
        `Child Client ${childClient.id} ${mode} unsuccessful. Please try again later; if the issue persists, contact foremandev@harvestgroup.com.`
      );
    } else {
      showSnackbar('success', `${mode} successful!`);
      if (mode == 'edit') {
        let newChildClientsArray = await childClients.map((childClient) => {
          if (childClient.id === modifiedChildClient.id) {
            return modifiedChildClient;
          } else {
            return childClient;
          }
        });
        setChildClients(newChildClientsArray);
        setSelectedChildClient({
          value: modifiedChildClient,
          label: `${modifiedChildClient.name} - ${modifiedChildClient.id}`,
        });

        setChildClients((childClients) => {
          childClients.filter((childClient) => childClient.id === modifiedChildClient.id)[0] = modifiedChildClient;
          return [...childClients];
        });
      } else {
        setChildClients((childClients) => [...childClients, response]);
      }
    }

    setShowChildClientFormModal(false);
    setDisableButtons(false);
  };

  const amazonRegionSelectOnChange = (option) => {
    setSelectedAmazonRegion(option);
    setModifiedChildClient({ ...modifiedChildClient, amazonRegion: option });
  };

  const retailerSelectOnChange = (value) => {
    setSelectedRetailer({ value: value, label: value.name });

    if (value.label === 'Amazon') {
      setSelectedAmazonRegion(amazonRegionOptions.filter(isValidAmazonRegion)[0]);
    } else {
      setSelectedAmazonRegion(amazonRegionOptions.filter(isDefaultAmazonRegion)[0]);
    }

    const changedChildClient = { ...modifiedChildClient, retailerId: value.id, name: parentClient.name + ' ' + value.name };
    setModifiedChildClient(changedChildClient);
    setDisableButtons(false);
  };

  const isValidAmazonRegion = (option) => {
    return option.value !== 0;
  };

  const isDefaultAmazonRegion = (option) => {
    return option.value === 0;
  };

  return (
    <CustomModal
      open={open}
      handleClose={handleClose}
      title={mode + ' child client'}
      isEditMode={mode == 'edit'}
      isCreateMode={mode != 'edit'}
      confirmEnabled={true}
      disabled={disableButtons}
      handleConfirm={submitChildClientForm}
    >
      <Grid container justify="center" spacing={2}>
        <Grid item md={12}>
          <Alert severity="warning">
            <strong>Warning</strong>: creating a child client affects other systems - such as creating a Microsoft Teams Team and
            user group. Identifying and removing clients can be time consuming and prone to error.
            <br />
            <strong>If creating a test client, please use our test environment.</strong>
          </Alert>
        </Grid>
        <form className="form-horizontal" method="get" style={{ width: '100%', marginLeft: '1rem' }}>
          {mode == 'edit' && (
            <FormControl fullWidth>
              <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
                <Grid item sm={1.5}>
                  <Typography>{modifiedChildClient.isActive ? 'Deactivate' : 'Activate'}</Typography>
                </Grid>
                <Grid item sm={10.5}>
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        checked={modifiedChildClient?.isActive}
                        onChange={() =>
                          setModifiedChildClient({
                            ...modifiedChildClient,
                            isActive: !modifiedChildClient?.isActive,
                          })
                        }
                        name="isActive"
                      />
                    }
                  />
                </Grid>
              </Grid>
            </FormControl>
          )}
          {mode == 'edit' && (
            <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
              <Grid item sm={1.5}>
                <Typography>Id</Typography>
              </Grid>
              <Grid item sm={10.5}>
                <FormControl fullWidth>
                  <Typography>{childClient.id}</Typography>
                </FormControl>
              </Grid>
            </Grid>
          )}
          <FormControl fullWidth>
            <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
              <Grid item sm={1.5}>
                <Typography>Parent Client Id</Typography>
              </Grid>
              <Grid item sm={10.5}>
                <FormControl fullWidth>
                  <Typography>{childClient && childClient.parentClientId}</Typography>
                </FormControl>
              </Grid>
            </Grid>
          </FormControl>
          {retailers && (
            <FormControl fullWidth>
              <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
                <Grid item sm={1.5}>
                  <Typography>Retailer</Typography>
                </Grid>
                <Grid item sm={10.5}>
                  <FormControl fullWidth>
                    <Select
                      className="react-select primary"
                      value={selectedRetailer.value || ''}
                      onChange={(e) => retailerSelectOnChange(e.target.value)}
                      variant="outlined"
                      disabled={mode == 'edit' ? !modifiedChildClient?.isActive : false}
                      error={!selectedRetailer?.label}
                    >
                      {retailers.map((retailer) => (
                        <MenuItem key={retailer.id} value={retailer}>
                          {retailer.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText sx={{ color: '#d3302f' }}>
                      {!selectedRetailer?.label ? 'This field is required.' : ''}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            </FormControl>
          )}
          <FormControl fullWidth>
            <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
              <Grid item sm={1.5}>
                <Typography>Name</Typography>
              </Grid>
              <Grid item sm={10.5}>
                <FormControl fullWidth>
                  <TextField
                    type="text"
                    autoComplete="off"
                    value={
                      !modifiedChildClient?.name 
                        ? (selectedRetailer?.label 
                            ? parentClient.name + ' ' + selectedRetailer.label 
                            : ''
                          ) 
                        : modifiedChildClient?.name
                    }
                    helperText={!modifiedChildClient?.name ? 'This field is set automatically.' : ''}
                    variant="outlined"
                    disabled
                  />
                </FormControl>
              </Grid>
            </Grid>
          </FormControl>
          <FormControl fullWidth>
            <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
              <Grid item sm={1.5}>
                <Typography>PBCS Child Client Id</Typography>
              </Grid>
              <Grid item sm={10.5}>
                <FormControl fullWidth>
                  <TextField
                    type="text"
                    autoComplete="off"
                    value={(modifiedChildClient && modifiedChildClient?.pbcsChildClientId) || ''}
                    onChange={(e) => {
                      setModifiedChildClient({ ...modifiedChildClient, pbcsChildClientId: e.target.value });
                    }}
                    variant="outlined"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </FormControl>
          <FormControl fullWidth disabled>
            <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
              <Grid item sm={1.5}>
                <Typography>Security Group Name</Typography>
              </Grid>
              <Grid item sm={10.5}>
                <FormControl fullWidth>
                  <TextField
                    value={(modifiedChildClient && modifiedChildClient?.securityGroupName) || ''}
                    variant="outlined"
                    disabled
                  />
                </FormControl>
              </Grid>
            </Grid>
          </FormControl>
          {containsProperty(selectedRetailer, 'label') && selectedRetailer.label === 'Amazon' && (
            <FormControl fullWidth>
              <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
                <Grid item sm={1.5}>
                  <Typography>Amazon Region</Typography>
                </Grid>
                <Grid item sm={10.5}>
                  <FormControl fullWidth>
                    <Select
                      className="react-select primary"
                      value={selectedAmazonRegion || ''}
                      onChange={(e) => amazonRegionSelectOnChange(e.target.value)}
                      variant="outlined"
                    >
                      {amazonRegionOptions.map((amazonRegion) => (
                        <MenuItem key={amazonRegion.value} value={amazonRegion.value}>
                          {amazonRegion.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </FormControl>
          )}
          {containsProperty(selectedRetailer, 'label') && selectedRetailer.label === 'Kroger' && (
            <FormControl fullWidth>
              <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
                <Grid item sm={1.5}>
                  <Typography>Vendor Id</Typography>
                </Grid>
                <Grid item sm={10.5}>
                  <FormControl fullWidth>
                    <TextField
                      type="text"
                      autoComplete="off"
                      value={
                        (modifiedChildClient &&
                          modifiedChildClient?.parameterValues &&
                          modifiedChildClient?.parameterValues.VendorId) ||
                        ''
                      }
                      onChange={(e) => {
                        setModifiedChildClient({
                          ...modifiedChildClient,
                          parameterValues: { ...modifiedChildClient?.parameterValues, VendorId: e.target.value },
                        });
                      }}
                      variant="outlined"
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </FormControl>
          )}
          {modifiedChildClient?.name != childClient.name && changedName && (
            <Grid>
              <FormControlLabel
                sx={{ mt: 3 }}
                required
                control={
                  <Checkbox
                    onChange={(e) => {
                      setDisableButtons(!disableButtons);
                    }}
                  />
                }
                label={
                  <Grid item xs={13} align="left">
                    <b>{'Confirm  name change decision?'}</b>
                  </Grid>
                }
              />
            </Grid>
          )}
        </form>
      </Grid>
    </CustomModal>
  );
}