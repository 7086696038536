import { graphConfig } from './authConfig';

/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken
 */
export async function callMsGraph(accessToken) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append('Authorization', bearer);

  const options = {
    method: 'GET',
    headers: headers,
  };

  return fetch(graphConfig.graphMeEndpoint, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
}
export async function callMsGraphSecurityGroups(accessToken) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append('Authorization', bearer);

  const options = {
    method: 'GET',
    headers: headers,
  };

  return fetch(graphConfig.graphMeMemberOfEndpoint, options)
    .then((response) => {
      return response.json();
    })
    .catch((error) => console.log(error));
}

export async function callMsGraphProfilePhoto(accessToken) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append('Authorization', bearer);

  const options = {
    method: 'GET',
    headers: headers,
  };

  return await fetch(graphConfig.graphProfilePhotoEndpoint, options)
    .then((response) => response.blob().then((blob) => URL.createObjectURL(blob)))
    .catch((error) => console.log(error));
}
