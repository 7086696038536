export const getDacqTaskStatusColor = (currentStatus, theme) => {
  currentStatus = parseInt(currentStatus);

  switch (currentStatus) {
    case 0:
      return theme.palette.taskGroupColors.darkGrey;
    case 1:
      return theme.palette.taskGroupColors.success;
    case 2:
      return theme.palette.taskGroupColors.red;
    default:
      return theme.palette.taskGroupColors.darkGrey;
  }
};

export const getDacqTaskStatusName = (status) => {
  status = parseInt(status);
  switch (status) {
    case 0:
      return 'Ready';
    case 1:
      return 'Completed';
    case 2:
      return 'Blocked';
    default:
      return 'Ready';
  }
};
export const doneTasksTableHeaders = ['Name', 'Task Group', 'Members', 'Start Date', 'End Date'];

 export const getDacqTaskStatus = (subTasks) => {
  const hasBlocked = subTasks.some((subTask) => subTask.currentStatus === 2);
  if (hasBlocked) {
    return 2;
  }
  const allCompleted = subTasks.every((subTask) => subTask.currentStatus === 1);
  if (allCompleted) {
    return 1;
  }
  return 0;
};