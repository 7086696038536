export const styles = {
  FullHeightTableContainer: {
    maxHeight: '70vh',
    height: 'fit-content',
    bgcolor: 'table.primary',
    '&::-webkit-scrollbar': { width: '0px', backgroundColor: '#131e29' },
    '&::-webkit-scrollbar-thumb': { backgroundColor: '#ffffff' },
  },
  TableContainer: {
    bgcolor: 'table.primary',
    '&::-webkit-scrollbar': { width: '0px', backgroundColor: '#131e29' },
    '&::-webkit-scrollbar-thumb': { backgroundColor: '#ffffff' },
  },
  ActionButtons: {
    m: 0.5,
    color: 'actionButtons.primary',
    '&:hover': {
      backgroundColor: 'actionButtons.primary',
      borderRadius: 2,
      color: 'white',
      p: 0.5,
    },
  },
  rowText: {
    overflow: 'hidden',
    whiteSpace: 'normal',
    wordBreak: 'break-all',
    textOverflow: 'ellipsis',
    fontSize: 13,
    '&:not(:hover):not(:focus)': {
      whiteSpace: 'nowrap',
      wordBreak: 'normal',
    },
  },

  classNameRowText: {
    '&:not(:hover):not(:focus)': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      fontSize: 13,
    },
    '&:hover': {
      animation: 'marquee 10s linear infinite',
      animationDirection: 'alternate',
    },
    '@keyframes marquee': {
      '0%': { transform: 'translateX(0)' },
      '100%': { transform: 'translateX(-100%)' },
    },
  },
  tablePagination: {
    bgcolor: 'table.primary',
    '.MuiTablePagination-displayedRows': {
      marginTop: '1em',
      marginBottom: '1em',
    },
    '.MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel': {
      marginTop: '1em',
      marginBottom: '1em',
    },
  },
  CreateButton: {
    bgcolor: 'button.primary',
  },
  ClickableSpan: {
    cursor: 'pointer',
    color: 'text.primary',
    textDecoration: 'none',
    fontSize: 'small',
    '&:hover': {
      cursor: 'pointer',
      color: 'lightgrey',
      textDecoration: 'underline',
    },
  },
};
