import React from 'react';
import Box from '@mui/material/Box';
import { styled, keyframes } from '@mui/system';
import { styles } from './Loader.styles';
import CircularProgress from '@mui/material/CircularProgress';

const typingKeyframes = keyframes`
  from {
    width: 0;
  }
  to {
    width: 13ch;
  }
`;

const blinkKeyframes = keyframes`
  50% {
    border-color: transparent;
  }
`;

const Wrapper = styled('div')`
  width: 13ch;
  white-space: nowrap;
  overflow: hidden;
  font-size: 1em;
  color: #1f3051;
  animation: ${typingKeyframes} 3s steps(13) infinite, ${blinkKeyframes} 0.4s step-end infinite alternate;
`;

export default function Loader() {
  return (
    <Box sx={styles.ContainingBox}>
      <CircularProgress disableShrink />
    </Box>
  );
}
