import React from 'react';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { IconStyles } from '../Modals/CustomModal.styles';
import {RetailerIcon} from './RetailerIcon';
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';

const ModalTitleIcon = ({ isCreateMode, isEditMode, isCopyMode, retailer, isCalendarMode }) => {
  return (
    <>
      {isCalendarMode ? (
        <DateRangeIcon />
      ) : isCreateMode ? (
        <AddBoxRoundedIcon sx={IconStyles.icon} />
      ) : isEditMode ? (
        <ModeEditOutlineOutlinedIcon sx={IconStyles.icon} />
      ) : isCopyMode ? (
        <FileCopyIcon sx={IconStyles.icon} />
      ) : (
        <RetailerIcon retailerType={retailer} />
      )}
    </>
  );
};

export default ModalTitleIcon;
