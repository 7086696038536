import React from 'react';
import { Button } from '@mui/material';
import { UncontrolledTooltip } from 'reactstrap';

export default function CsvExportButton({ onClick }) {
  const showDelayValue = 1000;
  const hideDelayValue = 0;

  return (
    <div>
      <Button onClick={onClick} color="info" size="lg" className="btn-icon btn-link like pull-right" id="export">
        <i className="fa fa-cloud-download" />
        <UncontrolledTooltip placement="auto" target="export" delay={{ show: showDelayValue, hide: hideDelayValue }}>
          Export filtered data to CSV
        </UncontrolledTooltip>
      </Button>
    </div>
  );
}

export const convertObjectArrayToCSV = (objArray) => {
  const array = objArray;
  const str =
    array.length !== 0
      ? `${Object.keys(array[0])
          .map((value) => `"${value}"`)
          .join(',')}\r\n`
      : '';

  return (
    str +
    array
      .map((obj) =>
        Object.values(obj)
          .map((value) => `"${value}"`)
          .join(',')
      )
      .join('\r\n')
  );
};

export const getDate = (string) => {
  return (([month, day, year]) => ({ day, month, year }))(string.split('-'));
};

export const downloadArrayDataCSV = (isRunningJobs, array, downloadFileName) => {
  let csv = convertObjectArrayToCSV(array);
  let newCsv = csv.replace("'", '');

  if (!isRunningJobs) {
    newCsv = newCsv
      .split('\n')
      .map((line) => {
        const [column1, column2, , , column5, , , , , ...restColumns] = line.split(',');
        return [column1, column2, column5, ...restColumns].join(',');
      })
      .join('\n');
  }

  const data = new Blob([newCsv], { type: 'text/csv' });
  const csvURL = window.URL.createObjectURL(data);
  const tempLink = document.createElement('a');
  tempLink.href = csvURL;
  tempLink.setAttribute('download', downloadFileName);
  tempLink.click();
};