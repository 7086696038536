import React, { useEffect, useState } from 'react';
import { jobRunsColumns } from '../../Components/TableColumns/jobRunsColumns';
import { Button, Chip, Divider, Grid, Typography, Box } from '@mui/material';
import { BasicTable } from '../../Components/Table/Table';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { getJobRuns } from '../../../Utilities/ForemanApiClient';
import { styles } from './jobRunsStyles';
import { getDate, downloadArrayDataCSV } from '../../Utils/JobRunUtilities';
import moment from 'moment';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DateRangeSelector from '../../Components/DateRangeSelector/DateRangePicker';
import CustomModal from '../../Components/Modals/CustomModal';
import CircularProgress from '@mui/material/CircularProgress';
import { useParams } from 'react-router-dom';

export default function JobRuns() {
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  const [startDate, setStartDate] = useState(moment(new Date()).format('MM-DD-YYYY'));
  const [endDate, setEndDate] = useState(moment(new Date()).format('MM-DD-YYYY'));
  const [showCalendar, setShowCalendar] = useState(false);
  const [sortDirection, setSortDirection] = useState('ascending');
  const [sort, setSort] = useState('');
  const [filters, setFilters] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [searchValues, setSearchValues] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [filteredJobRuns, setFilteredJobRuns] = useState(null);
  const [timer, setTimer] = useState(null);
  const [exportLoading, setExportLoading] = useState(false);

  const params = useParams();
  const jobRunId = params.jobRunId;

  const handleCloseCalendar = () => {
    setShowCalendar(false);
  };

  const handleSelectRange = async () => {
    const filters = searchValues
      ? Object.entries(searchValues)
          .map(([key, value]) => {
            if (value?.length > 0) return `&${key}=${value}`;
          })
          .join('')
      : '';

    setIsLoading(true);

    let response = await getFilteredRuns(rowsPerPage, page * rowsPerPage, filters, '', 'Ascending');

    if (response) {
      setCount(response.totalFilteredRecords ? response.totalFilteredRecords : 0);
      setFilteredJobRuns(response.records);
    }

    setIsLoading(false);
    setShowCalendar(false);
  };

  const getFilteredRuns = async (limit, offset, paginationFilters, paginationSort, paginationSortDirection, excludeFilter) => {
    setSort(paginationSort);
    setSortDirection(paginationSortDirection);
    setFilters(paginationFilters);

    return await getJobRuns(
      startDate,
      endDate,
      limit,
      offset,
      paginationFilters,
      paginationSort,
      paginationSortDirection,
      excludeFilter ? excludeFilter : false
    );
  };

  const getRunsForDownload = async () => {
    setExportLoading(true);
    const detailedStartDate = getDate(startDate);
    const detailedEndDate = getDate(endDate);

    await getJobRuns(startDate, endDate, 0, 0, filters, sort, sortDirection, false).then((response) => {
      downloadArrayDataCSV(
        false,
        response.records,
        `JobRuns_${detailedStartDate.year}${detailedStartDate.month}${detailedStartDate.day}_${detailedEndDate.year}${detailedEndDate.month}${detailedEndDate.day}.csv`
      );
    });
    setExportLoading(false);
  };

  useEffect(async () => {
    let filters = searchValues
      ? Object.entries(searchValues)
          .map(([key, value]) => {
            if (value?.length > 0) return `&${key}=${value}`;
          })
          .join('')
      : '';
    if (jobRunId != null) {
      filters = jobRunId && filters.concat(`&id=${jobRunId}`);
    }

    clearTimeout(timer);

    const newTimer = setTimeout(async () => {
      setIsLoading(true);
      let response = await getFilteredRuns(rowsPerPage, page * rowsPerPage, filters, '', 'Ascending');
      if (response) {
        setCount(response.totalFilteredRecords ? response.totalFilteredRecords : 0);
        setFilteredJobRuns(response.records);
      }
      setIsLoading(false);
    }, 500);

    setTimer(newTimer);
  }, [searchValues, page, rowsPerPage]);

  return (
    <>
      <Grid container rowSpacing={0}>
        <Grid item xs={6} align="left">
          <Typography>
            Showing {count} filtered records <Chip label={startDate}></Chip> to <Chip label={endDate}></Chip>
          </Typography>
        </Grid>
        <Grid item xs={6} align="right">
          <Button
            variant="contained"
            sx={styles.CalendarButton}
            startIcon={<EditCalendarIcon />}
            onClick={() => setShowCalendar(true)}
          >
            Select Dates
          </Button>
          <Button
            variant="contained"
            outline
            disabled={exportLoading}
            sx={styles.DownloadButton}
            startIcon={!exportLoading ? <CloudDownloadIcon sx={styles.DownloadIcon} /> : null}
            onClick={() => {
              !exportLoading && getRunsForDownload();
            }}
          >
            {exportLoading && (
              <div style={{ width: 'inherit' }}>
                <Box>
                  <CircularProgress size={20} />
                </Box>
              </div>
            )}
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ mt: 1, mb: 1 }} />
      </Grid>
      <Grid item xs={12}>
        <BasicTable
          data={filteredJobRuns}
          columns={jobRunsColumns}
          actionButtons={null}
          setSearchValues={setSearchValues}
          searchValues={searchValues}
          isLoading={isLoading}
          isFullHeight={true}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          page={page}
          setPage={setPage}
          count={count}
          hasSelectionCheckboxes={false}
        />
      </Grid>
      <CustomModal
        open={showCalendar}
        handleClose={handleCloseCalendar}
        handleConfirm={handleSelectRange}
        title={'Select Date Range'}
        isCalendarMode={true}
        customSize={'sm'}
        confirmEnabled={true}
      >
        <DateRangeSelector
          setDateRange={setDateRange}
          dateRange={dateRange}
          setEndDate={setEndDate}
          setStartDate={setStartDate}
        />
      </CustomModal>
    </>
  );
}
