import React from 'react';
import { Tooltip } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import RemoveIcon from '@mui/icons-material/Remove';
import { styles } from '../../Pages/job-templates/jobTemplates.styles';

function JobGroupTemplatesActionButtons({ handleRunOnClick, handleRemoveClick, jobTemplate }) {
  return (
    <>
      <Tooltip title="Run jobs for this template " placement="left">
        <PlayArrowIcon sx={styles.ActionButtonsRun} onClick={() => handleRunOnClick(jobTemplate)} />
      </Tooltip>
      <Tooltip title="Unassign template " placement="left">
        <RemoveIcon sx={styles.ActionButtonsCancel} onClick={() => handleRemoveClick(jobTemplate)} />
      </Tooltip>
    </>
  );
}

export default JobGroupTemplatesActionButtons;
