export const styles = (designTokens) => ({
  paper: {
    background: designTokens.palette.background.paper,
    margin: 'auto',
    width: '100%',
    height: '80%',
    '@media (maxWidth: 600px)': {
      width: '100%',
      height: '100%',
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
  },
  icon: {
    marginRight: '8px',
    fontSize: '32px',
    color: designTokens.palette.button.main,
  },
  loader: { width: '20px', height: '20px', color: 'gray' },
});
export const IconStyles = {
  icon: {
    marginRight: '8px',
    fontSize: '32px',
  },
};