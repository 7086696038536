import * as React from 'react';
import CustomModal from '../../Components/Modals/CustomModal';
import AdditionalRunningJobPropertiesForm from '../../Components/RunningJobInformationForm/AdditionalRunningJobPropertiesForm';

export default function RunningJobInfoModal({runningJob, open , handleClose}) {
  return (
    <CustomModal
      open={open}
      handleClose={handleClose}
      title={'Running Job Information'}
      customSize={'xl'}
    >
      <AdditionalRunningJobPropertiesForm runningJob={runningJob} />
    </CustomModal>
  );
}