import React from 'react';
import TableCell from '@mui/material/TableCell';
import { Tooltip, Typography } from '@mui/material';
import { styles } from './Table.styles';
import {RetailerIcon} from '../Icons/RetailerIcon';
import Chip from '../Chip/Chip';
import { getTimespanAsString, getCalculatedElapsedTime } from '../../Utils/filterTableData';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Link } from 'react-router-dom';

export function ConditionalColumn({
  columnId,
  columnAlign,
  value,
  dateDifference,
  row,
  columnHeader,
  openExtraInfoOnClick,
  hasMoreInfo,
  setSelectedProperty,
  handleMoreInfoOnClick,
  extraInfoParams,
  hasDuplicate,
  fromManualPage,
}) {
  const logEntriesLink = (id) => {
    if (extraInfoParams) {
      return `/V2/log-entries/${id}/${extraInfoParams?.jobId}/${extraInfoParams?.jobName}`;
    } else {
      return `/V2/log-entries/${id}`;
    }
  };

  return columnId === 'CronExpression' ? (
    <TableCell key={columnId} align={columnAlign} style={{ fontSize: 13 }}>
      {row.parameterDetail?.CronExpression}
    </TableCell>
  ) : columnId === 'Notes' ? (
    <TableCell key={columnId} align={columnAlign} sx={styles.rowText}>
      {row['parameterValues']?.Notes}
    </TableCell>
  ) : columnId === 'isPasswordFresh' ? (
    <TableCell key={columnId} align={columnAlign} style={{ fontSize: 13 }}>
      <Chip label={row.isPasswordFresh} />
    </TableCell>
  ) : columnId === 'ChildClientName' ? (
    <TableCell key={columnId} align={columnAlign} style={{ fontSize: 13 }}>
      {row.credential?.childClient?.name ? row.credential?.childClient?.name : ''}
    </TableCell>
  ) : columnId === 'Username' ? (
    <TableCell key={columnId} align={columnAlign} style={{ fontSize: 13 }} sx={styles.rowText}>
      {row.credential?.username ? row.credential?.username : ''}
    </TableCell>
  ) : columnId === 'Name' ? (
    <TableCell key={columnId} align={columnAlign} style={{ fontSize: 13 }}>
      {row.name}
    </TableCell>
  ) : columnId === 'JobId' ? (
    <TableCell key={columnId} align={columnAlign} style={{ fontSize: 13 }}>
      {row.id}
    </TableCell>
  ) : columnId === 'DataRunUsername' ? (
    <TableCell key={columnId} align={columnAlign} style={{ fontSize: 13 }} sx={styles.rowText}>
      {row.username}
    </TableCell>
  ) : columnId === 'elapsedTime' ? (
    <TableCell key={columnId} align={columnAlign} style={{ fontSize: 13 }}>
      {getTimespanAsString(value)}
    </TableCell>
  ) : columnId === 'startTime' ? (
    <TableCell key={columnId} align={columnAlign} style={{ fontSize: 13 }}>
      {getTimespanAsString(value)}
    </TableCell>
  ) : columnId === 'endTime' ? (
    <TableCell key={columnId} align={columnAlign} style={{ fontSize: 13, textAlign: 'center' }}>
      {value?.includes('9999-12-31') || value?.includes('0001-01-01') ? 'N/A' : getTimespanAsString(value)}
    </TableCell>
  ) : (
    <TableCell key={columnId} align={columnAlign} style={{ maxWidth: 1, overflow: 'hidden' }}>
      {columnId === 'status' ? (
        <Chip jobStatus={value} />
      ) : columnId === 'retailerName' || columnId === 'retailerType' ? (
        <>
          <RetailerIcon retailerName={value} retailerType={value} />
        </>
      ) : columnHeader === 'Data Run Id' ? (
        <>
          <Link to={logEntriesLink(row?.id)}>
            <Typography sx={styles.ClickableSpan}>{value}</Typography>
          </Link>
        </>
      ) : columnHeader === 'Job Run Id' ? (
        <>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {!extraInfoParams ? (
              <>
                {fromManualPage ? (
                  <Link to={`/V2/jobruns/${row?.jobRunId}`}>
                    <Typography sx={styles.ClickableSpan}>{value}</Typography>
                  </Link>
                ) : (
                  <Link to={`/V2/dataruns/${row?.id}/${encodeURIComponent(row?.startTime)}`}>
                    <Typography sx={styles.ClickableSpan}>{value}</Typography>
                  </Link>
                )}
              </>
            ) : (
              <Typography
                variant="body2"
                display="block"
                sx={columnId === 'className' ? styles.classNameRowText : styles.rowText}
              >
                {value}
              </Typography>
            )}

            {hasMoreInfo && columnId === 'id' && (
              <Tooltip title={`Click to list Data Runs!`} placement="right">
                <ExpandCircleDownOutlinedIcon
                  sx={styles.ActionButtons}
                  onClick={() => {
                    handleMoreInfoOnClick(row.id);
                    setSelectedProperty(row);
                  }}
                />
              </Tooltip>
            )}
          </div>
        </>
      ) : openExtraInfoOnClick && columnId === 'name' ? (
        <>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Link to={`/V2/job-extraInfo/${row?.id}/${encodeURIComponent(row?.name)}`} target="_blank">
              <Typography sx={styles.ClickableSpan}>{value}</Typography>
            </Link>
            {hasDuplicate && hasDuplicate(row) && (
              <Tooltip title={`Warning: More than one Job uses ${row?.jobTemplate?.name}`} placement="right">
                <InfoOutlinedIcon sx={{ color: 'primary.main' }} />
              </Tooltip>
            )}
          </div>
        </>
      ) : columnId === 'jobrunselapsedtime' ? (
        <>
          {row?.endTime?.includes('9999-12-31') || row?.endTime?.includes('0001-01-01')
            ? getCalculatedElapsedTime(dateDifference(row?.startTime, Date.now()))
            : getCalculatedElapsedTime(dateDifference(row?.startTime, row.endTime))}
        </>
      ) : columnId === 'nextRun' ? (
        <>
          <Typography variant="body2" display="block" sx={columnId === 'className' ? styles.classNameRowText : styles.rowText}>
            {row?.isEnabled ? row?.nextRun : 'Disabled'}
          </Typography>
        </>
      ) : columnId === 'jobGroupId' ? (
        <>
          <Typography variant="body2" display="block" sx={columnId === 'className' ? styles.classNameRowText : styles.rowText}>
            {row?.jobGroupId ? value : 'AD HOC'}
          </Typography>
        </>
      ) : (
        <>
          <Typography variant="body2" display="block" sx={columnId === 'className' ? styles.classNameRowText : styles.rowText}>
            {value}
          </Typography>
        </>
      )}
    </TableCell>
  );
}
