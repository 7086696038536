export const retailers = [
  {
    retailerName: 'Walmart',
    retailerType: 1,
  },
  {
    retailerName: 'Walmart China',
    retailerType: 7,
  },
  {
    retailerName: 'Walmart Canada',
    retailerType: 6,
  },
  {
    retailerName: 'Kroger',
    retailerType: 2,
  },
  {
    retailerName: 'Target',
    retailerType: 3,
  },
  {
    retailerName: 'Amazon',
    retailerType: 4,
  },
  {
    retailerName: "Sam's Club",
    retailerType: 5,
  },
  {
    retailerName: 'Costco',
    retailerType: 8,
  },
  {
    retailerName: 'Albertsons',
    retailerType: 10,
  },
  {
    retailerName: 'Instacart',
    retailerType: 12,
  },
  {
    retailerName: 'SYSTEM',
    retailerType: 99,
  },
  {
    retailerName: 'Other Ads Channels',
    retailerType: 9,
  },
];
