import React from 'react';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import EventBusyOutlinedIcon from '@mui/icons-material/EventBusyOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
import NoAccountsOutlinedIcon from '@mui/icons-material/NoAccountsOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import ReportGmailerrorredOutlinedIcon from '@mui/icons-material/ReportGmailerrorredOutlined';

const LoginStatusIcon = ({ status }) => {
  // Define the mapping of login status descriptions to icons
  const statusIcons = {
    Forbidden: <BlockOutlinedIcon fontSize="large" sx={{ mr: 1 }} />,
    'Expired Password': <EventBusyOutlinedIcon fontSize="large" sx={{ mr: 1 }} />,
    'Account Locked': <LockOutlinedIcon fontSize="large" sx={{ mr: 1 }} />,
    'Unknown Error': <ErrorOutlineOutlinedIcon fontSize="large" sx={{ mr: 1 }} />,
    'Blocked by Captcha': <VisibilityOffOutlinedIcon fontSize="large" sx={{ mr: 1 }} />,
    'Bot Invalid': <SmartToyOutlinedIcon fontSize="large" sx={{ mr: 1 }} />,
    'Username Error': <NoAccountsOutlinedIcon fontSize="large" sx={{ mr: 1 }} />,
  };

  // Check if the status has a corresponding icon, otherwise display a default icon
  const icon = statusIcons[status] || <ReportGmailerrorredOutlinedIcon fontSize="large" sx={{ mr: 1 }} />;

  return <span>{icon}</span>;
};

export default LoginStatusIcon;
