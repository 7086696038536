/* eslint-disable eqeqeq */
import { Box, CircularProgress, Grid, Step, StepButton, Stepper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  foremanApiIsHealthy,
  foremanDbIsHealthy,
  lanternApiIsHealthy,
  lanternDbIsHealthy
} from '../../../Utilities/ForemanApiClient';
import DrForemanSVG from '../../Assets/HealthCheck';
import ReportProblemSharpIcon from '@mui/icons-material/ReportProblemSharp';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export default function HealthCheck(props) {
  const [foremanApiStatus, setForemanApiStatus] = useState(undefined);
  const [foremanDbStatus, setForemanDbStatus] = useState(undefined);
  const [foremanHarvesterStatus, setForemanHarvesterStatus] = useState(undefined);
  const [lanternApiStatus, setLanternApiStatus] = useState(undefined);
  const [lanternDbStatus, setLanternDbStatus] = useState(undefined);
  const [lanternHarvesterStatus, setLanternHarvesterStatus] = useState(undefined);
  const steps = [
    { value: 'foremanApiStatus', label: 'Foreman API' },
    { value: 'foremanDbStatus', label: 'Foreman DB' },
    { value: 'foremanHarvesterStatus', label: 'Foreman Harvester' },
    { value: 'lanternApiStatus', label: 'Lantern API' },
    { value: 'lanternDbStatus', label: 'Lantern DB' },
    { value: 'lanternHarvesterStatus', label: 'Lantern Harvester' },
  ];

  const getServiceStatus = async () => {
    setForemanApiStatus(await foremanApiIsHealthy());
    setForemanDbStatus(await foremanDbIsHealthy());
    setForemanHarvesterStatus(await foremanApiIsHealthy());
    setLanternApiStatus(await lanternApiIsHealthy());
    setLanternDbStatus(await lanternDbIsHealthy());
    setLanternHarvesterStatus(await foremanApiIsHealthy());
  };

  const initializePage = () => {
    getServiceStatus();
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    initializePage();
  }, [foremanApiStatus, foremanDbStatus, foremanHarvesterStatus, lanternApiStatus, lanternDbStatus, lanternHarvesterStatus]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} align="center" sx={{ alignContent: 'center', mt: 4 }}>
        <Box sx={{ width: '100%' }}>
          <Stepper alternativeLabel>
            {steps.map((step, index) =>
              eval(step.value) == undefined ? (
                <Step color="red">
                  <StepButton icon={<CircularProgress size={20} />}>{step.label}</StepButton>
                </Step>
              ) : eval(step.value) == false ? (
                <Step key={step.label}>
                  <StepButton icon={<ReportProblemSharpIcon sx={{ color: 'stepper.secondary' }} />}>{step.label}</StepButton>
                </Step>
              ) : (
                <Step key={step.label} completed={eval(step.value)}>
                  <StepButton icon={<CheckCircleIcon sx={{ color: 'secondary.main' }} />}>{step.label}</StepButton>
                </Step>
              )
            )}
          </Stepper>
        </Box>
      </Grid>
      <Grid item xs={12} align="center" sx={{ alignContent: 'center' }}>
        <DrForemanSVG />
      </Grid>
    </Grid>
  );
}