export const styles = {
  CreateButton: {
    bgcolor: 'button.primary',
  },
  Inputs: {
    m: 1,
    width: '99%',
    '& legend': { display: 'none' },
  },
  ActionButtons: {
    m: 0.5,
    '&:hover': {
      backgroundColor: 'actionButtons.primary',
      borderRadius: 2,
      color: 'white',
      p: 0.5,
    },
  },
  CloseButton: {
    m: 0.5,
    '&:hover': {
      borderRadius: 2,
      p: 0.5,
      cursor: 'pointer',
    },
  },
  ModelTabs: {
    '& .Mui-selected:focus': {
      outline: 'none',
    },
  },
  TabPanels: {
    display: 'flex',
    flexDirection: 'column',
    paddingRight: '0px',
    paddingLeft: '0px',
  },
  Dividers: { marginTop: 1, marginBottom: 1 },
  RowStyle: { display: 'flex', flexDirection: 'row', alignItems: 'center' },
  DownloadIcon: {
    marginLeft: '8px',
  },
};
