import React from 'react'
import InfoFireNav from "./InfoFireNav";
import { additionalInformation, generalInformation, timeInformation ,parameterValuesCreation} from '../../Assets/RunningJobsInformationFields';
import ParametersIcon from '@mui/icons-material/SpeakerNotes';
import AdditionlIcon from '@mui/icons-material/Layers';
import TimeIcon from '@mui/icons-material/Schedule';
import InfoIcon from '@mui/icons-material/Info';

function AdditionalRunningJobPropertiesForm({runningJob}) {
  const generalInformationData = generalInformation(runningJob);
  const timeInformationData = timeInformation(runningJob);
  const additionalInformationData = additionalInformation(runningJob);
  const parameterValuesData = parameterValuesCreation(runningJob);

  return (
    <div>
        <InfoFireNav title={"General Information"} data={generalInformationData} Icon={InfoIcon} />
        <InfoFireNav title={"Time Information"} data={timeInformationData} Icon={TimeIcon} />
        <InfoFireNav title={"Additional Information"} data={additionalInformationData} Icon={AdditionlIcon} />
        <InfoFireNav title={"Parameter Values"} data={parameterValuesData} Icon={ParametersIcon} />
  </div>
  )
}

export default AdditionalRunningJobPropertiesForm