export const credentialsColumns = [
  {
    Header: 'Id',
    accessor: 'id',
    id: 'id',
    width: '4vw',
  },
  {
    Header: 'Username',
    accessor: 'Username',
    id: 'username',
    width: '12vw',
  },
  {
    Header: 'Job Group',
    accessor: 'credential.jobGroupName',
    id: 'jobGroupName',
    width: '15vw',
  },
  {
    Header: 'Notes',
    accessor: 'Notes',
    id: 'Notes',
    width: '18vw',
  },
  {
    Header: 'isPasswordFresh',
    accessor: 'isPasswordFresh',
    id: 'isPasswordFresh',
    width: '7vw',
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    id: 'actions',
    width: '13vw',
  },
];
