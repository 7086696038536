import React, { useState, useEffect } from 'react';
import {
  Link,
  AppBar,
  Box,
  Typography,
  TextField,
  CssBaseline,
  Button,
  Grid,
  Container,
  InputAdornment,
  IconButton,
  Alert,
  Stack,
  AlertTitle,
  CircularProgress,
} from '@mui/material';
import { Facebook, Instagram, LinkedIn } from '@mui/icons-material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { styles } from './TokenRefreshStyles';
import { useParams } from 'react-router-dom';
import { refreshTokenAndPassword, getRefreshToken, getCredentialUsername } from '../../../Utilities/ForemanApiClient';
import Loader from '../../Components/Loader/Loader';

export default function TokenRefresh() {
  const params = useParams();
  const token = params.token;
  const [showPassword, setShowPassword] = useState(true);
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [freshToken, setFreshToken] = useState('');
  const [tokenExists, setTokenExists] = useState(false);
  const [updated, setIsUpdated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [credentialUsername, setCredentialUsername] = useState('');

  const isDisabled = !(password && freshToken);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsActionLoading(true);

    try {
      const response = await refreshTokenAndPassword(freshToken, password, token);

      if (response.ok) {
        setIsUpdated(true);
        setError(false);
      } else {
        setError(true);
        setErrorMessage(response.message);
      }
    } catch (error) {
      setError(true);
      setErrorMessage(error.message);
    }

    setIsActionLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await getRefreshToken(token);
      if (response) setTokenExists(true);
      else setTokenExists(false);
      setLoading(false);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchCredentialUsername = async () => {
      const response = await getCredentialUsername(token);
      setCredentialUsername(response);
    };
    fetchCredentialUsername();
  }, []);

  if (loading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <AppBar position="static" sx={styles.NavBarStyle}>
        <img
          alt="system"
          src={'/whitelogo.svg'}
          style={{ width: '100px', height: '50px', marginLeft: '20px', marginTop: '12px' }}
        />
      </AppBar>
      {tokenExists ? (
        <div className="content compact-centered">
          <Container component="main" maxWidth="md" sx={styles.UpdatedContainer}>
            {updated ? (
              <>
                <Stack sx={{ width: '100%' }} spacing={2}>
                  <Alert severity="success" sx={{ fontSize: '16px' }}>
                    <AlertTitle>Success</AlertTitle>
                    <strong>Retail Link Credentials updated successfully!</strong>
                    <br />
                    <br />
                    Feel free to close this window
                  </Alert>
                </Stack>
              </>
            ) : (
              <>
                <Container component="main" maxWidth="md" sx={styles.Container}>
                  <CssBaseline />
                  <Box sx={styles.BoxStyles}>
                    <Grid container sx={styles.MainGrid}>
                      <Grid item xs={10}>
                        <Typography component="h1" variant="h5">
                          Refresh Credentials
                        </Typography>
                      </Grid>
                      <Grid item xs={2} align="right">
                        <img alt="system" src={'/systemJobLightMode.svg'} style={{ width: '60px', height: '80px' }} />
                      </Grid>
                      <Alert icon={false} severity="info" sx={styles.AlertStyle}>
                        <strong>Friendly reminder:</strong> The password and bot token must be updated at the same time.
                      </Alert>
                    </Grid>
                    <Box component="form" onSubmit={(event) => handleSubmit(event)} noValidate sx={{ mt: 1 }}>
                      <Typography component="h1" variant="h6" sx={{ textAlign: 'center', fontSize: '20px' }}>
                        {credentialUsername}
                      </Typography>

                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Retail Link Password"
                        type={!showPassword ? 'text' : 'password'}
                        id="password"
                        autoComplete="current-password"
                        sx={styles.PasswordInput}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <TextField
                        id="outlined-multiline-static"
                        multiline
                        rows={6}
                        label="Bot Token"
                        name="Token"
                        fullWidth
                        required
                        value={freshToken}
                        onChange={(e) => setFreshToken(e.target.value)}
                      />
                      <Button type="submit" fullWidth variant="contained" sx={styles.UpdateButton} disabled={isDisabled}>
                        {!isActionLoading ? 'UPDATE' : <CircularProgress size={24} color="inherit" />}
                      </Button>

                      {error && (
                        <Alert severity="error" sx={styles.AlertStyle}>
                          {errorMessage}
                        </Alert>
                      )}
                      <div>
                        <Link
                          href="https://hgretailreporterprod.blob.core.windows.net/rl-instructions/PasswordResetInstructions-RLBotAccount.pdf"
                          color="inherit"
                          target="_blank"
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            textAlign: 'center',
                          }}
                          sx={{
                            '&:hover': {
                              color: '#1D4C7B',
                            },
                          }}
                        >
                          Reset Bot Password Instructions
                        </Link>
                      </div>
                      <Link
                        href="https://hgretailreporterprod.blob.core.windows.net/rl-instructions/BotTokenResetInstructions-RLBotAccount.pdf"
                        color="inherit"
                        target="_blank"
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          flexDirection: 'column',
                          textAlign: 'center',
                        }}
                        sx={{
                          '&:hover': {
                            color: '#1D4C7B',
                          },
                        }}
                      >
                        Reset Bot Token Instructions
                      </Link>
                    </Box>
                  </Box>
                </Container>
              </>
            )}
          </Container>
        </div>
      ) : (
        <>
          <h1 style={{ alignSelf: 'center', marginTop: '10%' }}> This link has already been used.</h1>
        </>
      )}
      <Container maxWidth="lg" sx={styles.FooterStyle}>
        <Grid container>
          <Grid item xs={12} sm={4}></Grid>
          <Grid item xs={12} align="center" sm={4}>
            <Typography variant="body1" color="text.primary">
              Our Social Media
            </Typography>
            <Link href="https://www.facebook.com/harvestgroupnwa/" color="inherit">
              <Facebook />
            </Link>
            <Link href="https://www.instagram.com/the_harvest_group/" color="inherit" sx={{ pl: 1, pr: 1 }}>
              <Instagram />
            </Link>
            <Link href="https://www.linkedin.com/company/the-harvest-group/" color="inherit">
              <LinkedIn />
            </Link>
          </Grid>
        </Grid>
        <Box mt={2}>
          <Typography variant="body2" color="text.secondary" align="center">
            <Link color="inherit" href="https://harvestgroup.com/">
              {'© Harvest Group 2023'}
            </Link>
          </Typography>
        </Box>
      </Container>
    </div>
  );
}
