export const styles = {
  list: {
    minHeight: 48,
    justifyContent: 'center',
    px: 2.5,
    marginBottom: 1,
  },
  icon: {
    minWidth: 0,
    justifyContent: 'center',
  },
  link: {
    textDecoration: 'none',
    color: 'black',
  },
  listItemText: {
    color: 'text.primary',
  },
};
