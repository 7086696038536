import React, { useState, useEffect } from 'react';
import { Grid, Tab, Button } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import TabList from '@mui/lab/TabList';
import { styles } from './runningJobs.styles';
import { BasicTable } from '../../Components/Table/Table';
import { activeFutureDataRunsColumns } from '../../Components/TableColumns/runningJobsColumns';
import DefaultDeleteActionButton from '../../Components/TableActionButtons/DefaultDeleteActionButton';
import {
  getFutureDataRuns,
  getActiveDataRuns,
  stopDataRun,
  stopActiveAndFutureDataRunsByJobId,
} from '../../../Utilities/ForemanApiClient';
import { filterTableData } from '../../Utils/filterTableData';
import { showSnackbar } from '../../Utils/showSnackBar';

export default function ActiveAndFutureDataRunsModal({ runningJob, handleClose }) {
  const [value, setValue] = useState('0');
  const [isLoading, setIsLoading] = useState('0');
  const [searchValues, setSearchValues] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [sortDirection, setSortDirection] = useState('ascending');
  const [limit, setLimit] = useState(50);
  const [sort, setSort] = useState('');
  const [filters, setFilters] = useState('');
  const [dataRuns, setDataRuns] = useState([]);
  const [filteredDataRuns, setFilteredDataRuns] = useState(null);
  const [selectedDataRun, setSelectedDataRun] = useState(null);
  const [showStopDataRunModal, setShowStopDataRunModal] = useState(false);

  const actionButtons = (dataRun) => {
    return (
      <DefaultDeleteActionButton elementToDelete={dataRun} title={'Stop Data Run'} deleteFunction={displayConfirmStopModal} />
    );
  };

  const displayConfirmStopModal = (dataRun) => {
    setSelectedDataRun(dataRun);
    setShowStopDataRunModal(true);
  };

  const handleStopDataRun = async (dataRun) => {
    setIsLoading(true);

    await stopDataRun(dataRun.id).then((response) => {
      if (!response) {
        showSnackbar(
          'error',
          `Stopping Data Run ${dataRun.id} unsuccessful. Please try again later; if the issue persists, contact Dev : foremandev@harvestgroup.com.`
        );
      } else {
        setShowStopDataRunModal(false);
        setDataRuns((prevDataRuns) => prevDataRuns.filter((item) => item.id !== dataRun.id));
        setFilteredDataRuns((prevDataRuns) => prevDataRuns.filter((item) => item.id !== dataRun.id));
        setCount(count - 1);
      }
    });

    setIsLoading(false);
  };

  const handleBulkStopActiveAndFutureDataRuns = async () => {
    const response = await stopActiveAndFutureDataRunsByJobId(runningJob.id);

    if (!response) {
      showSnackbar(
        'error',
        `Could not stop all active/future Data Runs for Job Id ${runningJob?.id}. Ensure the Job exists or contact Dev : foremandev@harvestgroup.com if the issue persists.`
      );
    } else {
      setDataRuns([]);
      setFilteredDataRuns([]);
      setShowStopDataRunModal(false);
    }
  };

  const handleValueChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (value == 1) {
      getFutureDataRunsByJobId(rowsPerPage, rowsPerPage * page, filters, sort, sortDirection, false);
    } else {
      getActiveDataRunsByJobId(rowsPerPage, rowsPerPage * page, filters, sort, sortDirection, false);
    }
  },[value,rowsPerPage,page])

  useEffect(() => {
    const tableAsArray = filterTableData(dataRuns, searchValues);
    setFilteredDataRuns(tableAsArray);
  }, [searchValues]);

  useEffect(() => {
    if (value == 1) {
      getFutureDataRunsByJobId(rowsPerPage, rowsPerPage * page, filters, sort, sortDirection, false);
    } else {
      getActiveDataRunsByJobId(rowsPerPage, rowsPerPage * page, filters, sort, sortDirection, false);
    }
  }, [value,rowsPerPage,page]);

  const getActiveDataRunsByJobId = async (limit, offset, filters, sort, sortDirection, excludeFilter) => {
    setIsLoading(true);
    setFilters(filters);
    setSort(sort);
    setLimit(limit);
    setSortDirection(sortDirection);

    const response = await getActiveDataRuns(
      limit,
      offset,
      `${filters}&jobId=${runningJob?.id}`,
      sort,
      sortDirection,
      excludeFilter
    );
    let dataRunsList = response?.records;
    setCount(dataRunsList?.length);
    setFilteredDataRuns(dataRunsList);
    setDataRuns(dataRunsList);
    setIsLoading(false);
  };

  const getFutureDataRunsByJobId = async (limit, offset, filters, sort, sortDirection, excludeFilter) => {
    setIsLoading(true);
    setFilters(filters);
    setSort(sort);
    setLimit(limit);
    setSortDirection(sortDirection);

    const response = await getFutureDataRuns(
      limit,
      offset,
      `${filters}&jobId=${runningJob?.id}`,
      sort,
      sortDirection,
      excludeFilter
    );
    let dataRunsList = response?.records;
    let totalFilteredRecords = response?.totalFilteredRecords;

    setCount(totalFilteredRecords);
    setDataRuns(dataRunsList);
    setFilteredDataRuns(dataRunsList);
    setIsLoading(false);
  };

  return (
    <>
      <TabContext value={value}>
        <Grid container justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
          <Grid item xs={6} align="left">
            <span style={{ cursor: 'pointer' }} onClick={() => handleClose()}>
              <NavigateBeforeIcon />
            </span>
            {runningJob?.name}
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
          <Grid item xs={6} align="left">
            <TabList onChange={handleValueChange} aria-label="jobTemplates_tabs" sx={styles.ModelTabs}>
              <Tab label="Active Data Runs" value="0" />
              <Tab label="Future Data Runs" value="1" />
            </TabList>
          </Grid>
          <Grid item xs={6} align="right">
            <Button
              variant="contained"
              sx={styles.CreateButton}
              onClick={() => {
                displayConfirmStopModal(null);
              }}
            >
              Stop Active and Future Data Runs
            </Button>
          </Grid>
        </Grid>
        <TabPanel value="0" sx={styles.TabPanels}>
          <BasicTable
            data={filteredDataRuns}
            columns={activeFutureDataRunsColumns}
            actionButtons={actionButtons}
            setSearchValues={setSearchValues}
            searchValues={searchValues}
            isLoading={isLoading}
            isFullHeight={true}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            page={page}
            setPage={setPage}
            count={count}
          />
        </TabPanel>
        <TabPanel value="1" sx={styles.TabPanels}>
          <BasicTable
            data={filteredDataRuns}
            columns={activeFutureDataRunsColumns}
            actionButtons={actionButtons}
            setSearchValues={setSearchValues}
            searchValues={searchValues}
            isLoading={isLoading}
            isFullHeight={true}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            page={page}
            setPage={setPage}
            count={count}
          />
        </TabPanel>
      </TabContext>
      <Dialog
        open={showStopDataRunModal}
        onClose={() => setShowStopDataRunModal(false)}
        aria-labelledby="alert-delete-title"
        aria-describedby="alert-delete-description"
      >
        <DialogTitle id="alert-delete">{'Stop Data Run?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {selectedDataRun?.id
              ? ` This will stop Data Run ${selectedDataRun?.id}. If stopped, no data will be processed for this Data Run. Do you wish to continue?`
              : `This will stop all active and future Data Runs for the Job Id ${runningJob?.id}. Do you wish to continue?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowStopDataRunModal(false);
              setSelectedDataRun(null);
            }}
          >
            Cancel
          </Button>
          {selectedDataRun?.id ? (
            <Button
              onClick={() => {
                handleStopDataRun(selectedDataRun);
              }}
              autoFocus
            >
              Confirm
            </Button>
          ) : (
            <Button
              onClick={() => {
                handleBulkStopActiveAndFutureDataRuns(runningJob?.id);
              }}
              autoFocus
            >
              Confirm
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}
