import { Chip } from '@mui/material';
import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import DownloadingIcon from '@mui/icons-material/Downloading';
import { styles } from './StatusChip.styles';

export default function InfoChip({ label, jobStatus }) {
  return (
    <>
      {jobStatus ? (
        <Chip
          key={Date.now()}
          icon={
            jobStatus.toLowerCase() == 'completed' || jobStatus == 'submitted' || jobStatus == 'done' ? (
              <CheckIcon style={styles.ChipIcon} color="statusChips.successChip" />
            ) : jobStatus.toLowerCase() == 'failed' ? (
              <CloseIcon style={styles.ChipIcon} color="statusChips.dangerChip" />
            ) : jobStatus == 'enqueued' ? (
              <HourglassEmptyIcon style={styles.ChipIcon} color="primary.main" />
            ) : jobStatus == 'active' || jobStatus == 'downloaded' ? (
              <DownloadingIcon style={styles.ChipIcon} color="primary.main" />
            ) : null
          }
          label={jobStatus}
          variant="outlined"
          size="small"
          sx={
            jobStatus.toLowerCase() == 'completed' || jobStatus == 'submitted' || jobStatus == 'done'
              ? styles.SuccessChip
              : jobStatus?.toLowerCase() == 'failed'
              ? styles.DangerChip
              : styles.PrimaryChip
          }
        />
      ) : label === undefined ? (
        <Chip label={'not set'} color={'primary'} variant={'outlined'} size="small" sx={styles.PrimaryChip} />
      ) : (
        <Chip
          label={label ? 'Fresh' : 'Not Fresh'}
          color={label ? 'success' : 'error'}
          variant={'outlined'}
          sx={{ width: '90%' }}
        />
      )}
    </>
  );
}
