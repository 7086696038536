import React from 'react';
import { Grid, Typography, FormControl, TextField, Button, Switch } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

export const WalmartUSRetailLinkMFA = ({
  isClientNumberValid,
  modifiedCredential,
  setModifiedCredential,
  clientNumberOnChange,
  FormHelperText,
  isTwilioNumberValid,
  validateAndSaveTwilioNumber,
  purchaseOnClick,
  mode,
  isLoading,
}) => {
  return (
    <>
      <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
        <Grid item sm={2}>
          <Typography>Name for MFA Forwarding</Typography>
        </Grid>
        <Grid item sm={4}>
          <FormControl fullWidth>
            <TextField
              variant="outlined"
              type="text"
              autoComplete="off"
              value={
                (modifiedCredential &&
                  modifiedCredential.parameterValues &&
                  modifiedCredential.parameterValues.MFAForwardingName) ||
                ''
              }
              onChange={(event) => {
                setModifiedCredential({
                  ...modifiedCredential,
                  parameterValues: {
                    ...modifiedCredential.parameterValues,
                    MFAForwardingName: event.target.value,
                  },
                });
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
        <Grid item sm={2}>
          <Typography>Email for MFA Forwarding</Typography>
        </Grid>
        <Grid item sm={4}>
          <FormControl fullWidth>
            <TextField
              variant="outlined"
              type="text"
              autoComplete="off"
              value={
                (modifiedCredential &&
                  modifiedCredential.parameterValues &&
                  modifiedCredential.parameterValues.MFAForwardingEmail) ||
                ''
              }
              onChange={(event) => {
                setModifiedCredential({
                  ...modifiedCredential,
                  parameterValues: {
                    ...modifiedCredential.parameterValues,
                    MFAForwardingEmail: event.target.value,
                  },
                });
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
        <Grid item sm={2}>
          <Typography>Twilio Telephone Number</Typography>
        </Grid>
        <Grid item sm={4}>
          <FormControl fullWidth error={!isTwilioNumberValid}>
            <TextField
              variant="outlined"
              type="text"
              autoComplete="off"
              value={(modifiedCredential && modifiedCredential.twilioNumber) || ''}
              onChange={validateAndSaveTwilioNumber}
            />
            <FormHelperText error={!isTwilioNumberValid}>US number format is required (ex: +11334567891).</FormHelperText>
          </FormControl>
        </Grid>
        {mode === 'edit' && modifiedCredential && !modifiedCredential.twilioNumber && (
          <Grid item sm={2}>
            {isLoading ? (
              <CircularProgress className="form-button-spinner" color="primary" />
            ) : (
              <Button style={{ bgcolor: 'button.primary', marginLeft: 5 }} onClick={async () => await purchaseOnClick()}>
                PURCHASE
              </Button>
            )}
          </Grid>
        )}
        <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
          <Grid item sm={2}>
            <Typography>Should Forward MFA To Email</Typography>
          </Grid>
          <Grid item sm={10}>
            <FormControl fullWidth>
              <Switch
                checked={modifiedCredential.shouldForwardMFAToEmail}
                onChange={() =>
                  setModifiedCredential({
                    ...modifiedCredential,
                    shouldForwardMFAToEmail: !modifiedCredential.shouldForwardMFAToEmail,
                  })
                }
                color="primary"
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
