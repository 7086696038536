import React, { useEffect, useState } from 'react';
import { Grid, Typography, FormControl, TextField, Switch, MenuItem } from '@mui/material';
import Select from '@mui/material/Select';

export const WalmartUSRetailer = ({
  selectedRetailerServiceType,
  modifiedCredential,
  setModifiedCredential,
  setDisableButtons,
  walmartAdsLocationOptions,
  selectedWalmartAdsLocation,
  setSelectedWalmartAdsLocation,
}) => {
  useEffect(() => {
    if (modifiedCredential.retailerServiceType == 13) {
      setSelectedWalmartAdsLocation(
        walmartAdsLocationOptions.find((option) => option.value == modifiedCredential.parameterValues?.AdLocation)
      );
    }
  }, [modifiedCredential.retailerServiceType]);

  const [showError, setShowError] = useState(false);
  const [isValidEmailFormat, setIsValidEmailFormat] = useState(false);

  const walmartAdsLocationSelectOnChange = (option) => {
    let adLocation = walmartAdsLocationOptions.find((adLocation) => adLocation.value === option);
    setSelectedWalmartAdsLocation(adLocation);

    setModifiedCredential({
      ...modifiedCredential,
      parameterValues: { ...modifiedCredential.parameterValues, AdLocation: option },
    });
  };

  return (
    <>
      {selectedRetailerServiceType.value === 31 && (
        <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
          <Grid item sm={2}>
            <Typography>Walmart OTP key</Typography>
          </Grid>
          <Grid item sm={10}>
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                type="text"
                autoComplete="off"
                value={
                  (modifiedCredential && modifiedCredential.parameterValues && modifiedCredential.parameterValues.OtpSecretKey) ||
                  ''
                }
                onChange={(event) => {
                  setModifiedCredential({
                    ...modifiedCredential,
                    parameterValues: {
                      ...modifiedCredential.parameterValues,
                      OtpSecretKey: event.target.value,
                    },
                  });
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      )}
      {selectedRetailerServiceType.value === 16 && (
        <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
          <Grid item sm={2}>
            <Typography>Advertiser Id</Typography>
          </Grid>
          <Grid item sm={10}>
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                type="text"
                autoComplete="off"
                value={
                  (modifiedCredential && modifiedCredential.parameterValues && modifiedCredential.parameterValues.AdvertiserId) ||
                  ''
                }
                onChange={(event) => {
                  setModifiedCredential({
                    ...modifiedCredential,
                    parameterValues: {
                      ...modifiedCredential.parameterValues,
                      AdvertiserId: event.target.value,
                    },
                  });
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      )}
      {selectedRetailerServiceType.value === 13 && (
        <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
          <Grid item sm={2}>
            <Typography>Advertiser Id</Typography>
          </Grid>
          <Grid item sm={10}>
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                type="text"
                autoComplete="off"
                value={
                  (modifiedCredential && modifiedCredential.parameterValues && modifiedCredential.parameterValues.AdvertiserId) ||
                  ''
                }
                onChange={(event) => {
                  setModifiedCredential({
                    ...modifiedCredential,
                    parameterValues: {
                      ...modifiedCredential.parameterValues,
                      AdvertiserId: event.target.value,
                    },
                  });
                }}
              />
            </FormControl>
          </Grid>
          <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
            <Grid item sm={2}>
              <Typography>Ad Location</Typography>
            </Grid>
            <Grid item sm={10}>
              <FormControl fullWidth>
                <Select
                  value={selectedWalmartAdsLocation?.value || null}
                  onChange={(option) => walmartAdsLocationSelectOnChange(option.target.value)}
                >
                  {walmartAdsLocationOptions.map((adLocation) => (
                    <MenuItem key={adLocation.value} value={adLocation.value}>
                      {adLocation.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      )}
      {selectedRetailerServiceType.value == 7 && (
        <>
          <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
            <Grid item sm={2}>
              <Typography>Bot Token</Typography>
            </Grid>
            <Grid item sm={10}>
              <FormControl fullWidth>
                <TextField
                  variant="outlined"
                  type="text"
                  autoComplete="off"
                  value={(modifiedCredential && modifiedCredential.authToken) || ''}
                  onChange={(event) => {
                    setModifiedCredential({ ...modifiedCredential, authToken: event.target.value });
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
            <Grid item sm={2}>
              <Typography>External Email</Typography>
            </Grid>
            <Grid item sm={10}>
              <FormControl fullWidth>
                <TextField
                  variant="outlined"
                  type="text"
                  autoComplete="off"
                  value={
                    (modifiedCredential &&
                      modifiedCredential.parameterValues &&
                      modifiedCredential.parameterValues.ExternalEmail) ||
                    ''
                  }
                  onChange={(event) => {
                    setModifiedCredential({
                      ...modifiedCredential,
                      parameterValues: {
                        ...modifiedCredential.parameterValues,
                        ExternalEmail: event.target.value,
                      },
                    });
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
            <Grid item sm={2}>
              <Typography>Retail Link Admin Email</Typography>
            </Grid>
            <Grid item sm={10}>
              <FormControl fullWidth>
                <TextField
                  variant="outlined"
                  type="text"
                  autoComplete="off"
                  value={
                    (modifiedCredential &&
                      modifiedCredential.parameterValues &&
                      modifiedCredential.parameterValues.RetailLinkAdminEmail) ||
                    ''
                  }
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    const hasSemicolon = inputValue.includes(';');
                    const isValidEmail = /^$|^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputValue);

                    setModifiedCredential({
                      ...modifiedCredential,
                      parameterValues: {
                        ...modifiedCredential.parameterValues,
                        RetailLinkAdminEmail: inputValue,
                      },
                    });

                    const showError = hasSemicolon || !isValidEmail;

                    setShowError(showError);
                    setIsValidEmailFormat(isValidEmail);
                    setDisableButtons(showError);
                  }}
                />
                {showError && (
                  <p style={{ color: '#b71c1c' }}>
                    {!isValidEmailFormat
                      ? 'Invalid email format. Please enter a valid email address.'
                      : 'The RL Admin email field cannot contain multiple email addresses.'}
                  </p>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
            <Grid item sm={2}>
              <Typography>Enable Forwarding To Client</Typography>
            </Grid>
            <Grid item sm={10}>
              <FormControl fullWidth>
                <Switch
                  checked={modifiedCredential.isForwardToExternalEnabled}
                  onChange={() =>
                    setModifiedCredential({
                      ...modifiedCredential,
                      isForwardToExternalEnabled: !modifiedCredential.isForwardToExternalEnabled,
                    })
                  }
                  color="primary"
                />
              </FormControl>
            </Grid>
          </Grid>
        </>
      )}
      {selectedRetailerServiceType.value === 26 && (
        <>
          <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
            <Grid item sm={2}>
              <Typography>Client Id</Typography>
            </Grid>
            <Grid item sm={10}>
              <FormControl fullWidth>
                <TextField
                  variant="outlined"
                  type="text"
                  autoComplete="off"
                  value={
                    (modifiedCredential && modifiedCredential.parameterValues && modifiedCredential.parameterValues.ClientId) ||
                    ''
                  }
                  onChange={(event) => {
                    setModifiedCredential({
                      ...modifiedCredential,
                      parameterValues: {
                        ...modifiedCredential.parameterValues,
                        ClientId: event.target.value,
                      },
                    });
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
            <Grid item sm={2}>
              <Typography>Client Secret</Typography>
            </Grid>
            <Grid item sm={10}>
              <FormControl fullWidth>
                <TextField
                  variant="outlined"
                  type="text"
                  autoComplete="off"
                  value={
                    (modifiedCredential &&
                      modifiedCredential.parameterValues &&
                      modifiedCredential.parameterValues.ClientSecret) ||
                    ''
                  }
                  onChange={(event) => {
                    setModifiedCredential({
                      ...modifiedCredential,
                      parameterValues: {
                        ...modifiedCredential.parameterValues,
                        ClientSecret: event.target.value,
                      },
                    });
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
            <Grid item sm={2}>
              <Typography>Partner Id</Typography>
            </Grid>
            <Grid item sm={10}>
              <FormControl fullWidth>
                <TextField
                  variant="outlined"
                  type="text"
                  autoComplete="off"
                  value={
                    (modifiedCredential && modifiedCredential.parameterValues && modifiedCredential.parameterValues.PartnerId) ||
                    ''
                  }
                  onChange={(event) => {
                    setModifiedCredential({
                      ...modifiedCredential,
                      parameterValues: {
                        ...modifiedCredential.parameterValues,
                        PartnerId: event.target.value,
                      },
                    });
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};
