import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import { drawerWidth } from '../../Pages/main-page/page.styles';

const iconButton = {
  '&:focus': {
    outline: 'none',
  },
};

export const styles = {
  drawerIconButton: {
    ...iconButton,
  },
  notificationIconButton: {
    marginRight: 2,
    ...iconButton,
  },
  profileIconButton: {
    marginRight: 2,
    ...iconButton,
  },
  appBar: {
    bgcolor: 'appBar.primary',
  },
  appBarSecondary: {
    bgcolor: '#7FBCD2',
  },
  title: {
    fontFamily: 'Helvetica',
  },
  mainBox: {
    xs: 'flex',
    md: 'flex',
  },
  avatarImage: {
    width: 30,
    height: 30,
    borderRadius: 30,
    marginRight: 5,
  },
  button: {
    bgcolor: '#ffffff',
    color: '#000000',
    mt: 1,
    '&:hover': {
      bgcolor: '#ffffff',
      color: '#000000',
      textDecoration: 'none',
    },
  },
};

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));
