import React, { useEffect, useState } from 'react';
import CustomModal from '../../Components/Modals/CustomModal';
import { Grid, Typography, TextField, FormControlLabel, Switch, FormControl, Select, MenuItem, Checkbox, FormHelperText } from '@mui/material';
import { showSnackbar } from '../../Utils/showSnackBar';
import { updateParentClient, createParentClient, getAmazonRegionOptions, createChildClient } from '../../../Utilities/ForemanApiClient';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { styles } from '../job-groups/jobGroupStyles';
import { containsProperty } from '../../Utils/Utilities';

export default function ParentClientForm({
  parentClient,
  mode,
  open,
  setShowParentClientFormModal,
  setParentClients,
  handleClose,
  parentClients,
  setSelectedParentClient,
  retailers,
}) {
  const [modifiedParentClient, setModifiedParentClient] = useState(parentClient);
  const [disableButtons, setDisableButtons] = useState(false);
  const [changedName, setChangedName] = useState(false);
  const [selectedRetailers, setSelectedRetailers] = useState([]);
  const [selectedAmazon, setSelectedAmazon] = useState(false);
  const [selectedKroger, setselectedKroger] = useState(false);
  const [selectedAmazonRegion, setSelectedAmazonRegion] = useState('');
  const [VendorId, setVendorId] = useState('');
  const [amazonRegionOptions, setAmazonRegionOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const submitParentClientForm = async () => {
    setDisableButtons(true);
    setIsLoading(true);
    let parentClientOperation = false;

    if (mode === 'edit') {
      parentClientOperation = await updateParentClient(modifiedParentClient);
    } else {
      parentClientOperation = await createParentClient(modifiedParentClient);
    }

    if (!parentClientOperation) {
      showSnackbar(
        'error',
        `Parent Client ${parentClient.id} ${mode} unsuccessful. Please try again later; if the issue persists, contact dev: foremandev@harvestgroup.com`
      );
    } else {
      await Promise.allSettled(
        selectedRetailers.map((retailer) => createChildClientForRetailer(retailer, parentClientOperation))
      );      

      showSnackbar('success', `${mode} successful!`);

      if (mode === 'edit') {
        const newParentClientsArray = parentClients.map((client) => {
          return client.id === modifiedParentClient.id ? modifiedParentClient : client;
        });
        setParentClients(newParentClientsArray);
        setSelectedParentClient({
          value: modifiedParentClient,
          label: `${modifiedParentClient.name} - ${modifiedParentClient.id}`,
        });
      } else {
        setParentClients((clients) => [...clients, parentClientOperation]);
      }
    }
    setShowParentClientFormModal(false);
    setDisableButtons(false);
    setIsLoading(false);
  };

  async function createChildClientForRetailer(retailer, parentClient) {
    const childClient = {
      id: 0,
      parentClientId: modifiedParentClient.id != 0 ? modifiedParentClient.id : parentClient.id,
      isActive: true,
      name: `${modifiedParentClient.name} ${retailer.name}`,
      pbcsChildClientId: '',
      retailerId: retailer.id,
      securityGroupName: '',
      amazonRegion: retailer.id == 6 ? selectedAmazonRegion : 0,
      ...(VendorId !== '' && retailer.id == 5 && { parameterValues: { VendorId } }),
    };

    return createChildClient(childClient);
  }

  useEffect(async () => {
    const options = await getAmazonRegionOptions();
    setAmazonRegionOptions(options);
  }, []);

  useEffect(() => {
    const isAmazonSelected = selectedRetailers.some(
      (selectedRetailer) => containsProperty(selectedRetailer, 'name') && selectedRetailer.name === 'Amazon'
    );

    const isKrogerSelected = selectedRetailers.some(
      (selectedRetailer) => containsProperty(selectedRetailer, 'name') && selectedRetailer.name === 'Kroger'
    );

    setSelectedAmazon(isAmazonSelected);
    setselectedKroger(isKrogerSelected);
  }, [selectedRetailers]);

  return (
    <CustomModal
      open={open}
      handleClose={handleClose}
      title={mode + ' parent client'}
      isEditMode={mode === 'edit'}
      isCreateMode={mode !== 'edit'}
      confirmEnabled={true}
      disabled={disableButtons}
      handleConfirm={submitParentClientForm}
      isSubmitting={isLoading}
    >
      <Grid container justify="center" spacing={1.5}>
        {mode === 'edit' && (
          <FormControl fullWidth>
            <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
              <Grid item sm={1.5}>
                <Typography>{modifiedParentClient.isActive ? 'Deactivate' : 'Activate'}</Typography>
              </Grid>
              <Grid item sm={10.5}>
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      checked={modifiedParentClient.isActive}
                      onChange={() =>
                        setModifiedParentClient({
                          ...modifiedParentClient,
                          isActive: !modifiedParentClient.isActive,
                        })
                      }
                      name="isActive"
                    />
                  }
                />
              </Grid>
            </Grid>
          </FormControl>
        )}
        {mode === 'edit' && (
          <FormControl fullWidth>
            <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
              <Grid item sm={1.5}>
                <Typography>Id</Typography>
              </Grid>
              <Grid item sm={10.5}>
                <FormControl fullWidth>
                  <Typography>{parentClient.id}</Typography>
                </FormControl>
              </Grid>
            </Grid>
          </FormControl>
        )}
        <FormControl fullWidth>
          <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
            <Grid item sm={1.5}>
              <Typography>Name *</Typography>
            </Grid>
            <Grid item sm={10.5}>
              <FormControl fullWidth>
                <TextField
                  type="text"
                  autoComplete="off"
                  value={(modifiedParentClient && modifiedParentClient.name) || ''}
                  onChange={(e) => {
                    const newName = e.target.value;
                    const nameChanged = newName === parentClient.name || parentClient.name === '';

                    setChangedName(!nameChanged);
                    setDisableButtons(!nameChanged);
                    setModifiedParentClient({ ...modifiedParentClient, name: e.target.value });
                  }}
                  error={!modifiedParentClient?.name}
                  helperText={!modifiedParentClient?.name && 'This field is required.'}
                  variant="outlined"
                  disabled={mode === 'edit' ? !modifiedParentClient.isActive : false}
                />
              </FormControl>
              {modifiedParentClient?.name !== parentClient.name && changedName && (
                <Grid sx={{ mt: 1 }} item xs={13} align="left">
                  <WarningAmberIcon sx={styles.WarningIcon} />
                  <b>
                    {
                      "Changing the name of a Parent Client might be a breaking change. It's rare to change a Parent Client's name and can involve multiple teams."
                    }
                  </b>
                </Grid>
              )}
            </Grid>
          </Grid>
        </FormControl>
        {mode === 'edit' && (
          <FormControl fullWidth disabled>
            <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
              <Grid item sm={1.5}>
                <Typography>Security Group Name</Typography>
              </Grid>
              <Grid item sm={10.5}>
                <FormControl fullWidth>
                  <TextField
                    value={(modifiedParentClient && modifiedParentClient.securityGroupName) || ''}
                    variant="outlined"
                    disabled
                  />
                </FormControl>
              </Grid>
            </Grid>
          </FormControl>
        )}
        <FormControl fullWidth>
          <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
            <Grid item sm={1.5}>
              <Typography>Select Retailers for Child Client Creation</Typography>
            </Grid>
            <Grid item sm={10.5}>
              <FormControl fullWidth>
                <Select
                  className="react-select primary"
                  multiple
                  value={selectedRetailers}
                  onChange={(e) => setSelectedRetailers(e.target.value)}
                  variant="outlined"
                  disabled={mode === 'edit' ? !modifiedParentClient.isActive : false}
                >
                  {retailers.length > 0 ? (
                    retailers.map((retailer) => (
                      <MenuItem key={retailer.id} value={retailer}>
                        {retailer.name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No retailers available</MenuItem>
                  )}
                </Select>
                <FormHelperText>{selectedRetailers.length === 0 ? 'This field is required.' : ''}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        </FormControl>
        {selectedAmazon && (
          <FormControl fullWidth>
            <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
              <Grid item sm={1.5}>
                <Typography>Amazon Region</Typography>
              </Grid>
              <Grid item sm={10.5}>
                <FormControl fullWidth>
                  <Select
                    className="react-select primary"
                    value={selectedAmazonRegion || ''}
                    onChange={(e) => setSelectedAmazonRegion(e.target.value)}
                    variant="outlined"
                  >
                    {amazonRegionOptions.length > 0 ? (
                      amazonRegionOptions.map((region) => (
                        <MenuItem key={region.value} value={region.value}>
                          {region.label}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>No regions available</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </FormControl>
        )}
        {selectedKroger && (
          <FormControl fullWidth>
            <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
              <Grid item sm={1.5}>
                <Typography>Kroger's Vendor Id</Typography>
              </Grid>
              <Grid item sm={10.5}>
                <FormControl fullWidth>
                  <TextField
                    type="text"
                    autoComplete="off"
                    value={(VendorId && VendorId) || ''}
                    onChange={(e) => {
                      setVendorId(e.target.value);
                    }}
                    variant="outlined"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </FormControl>
        )}
        {modifiedParentClient?.name !== parentClient.name && changedName && (
          <Grid>
            <FormControlLabel
              sx={{ mt: 3 }}
              required
              control={
                <Checkbox
                  onChange={(e) => {
                    setDisableButtons(!disableButtons);
                  }}
                />
              }
              label={
                <Grid item xs={13} align="left">
                  <b>{'Confirm name change decision?'}</b>
                </Grid>
              }
            />
          </Grid>
        )}
      </Grid>
    </CustomModal>
  );
}