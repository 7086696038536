import React from 'react';
import { Autocomplete, FormControl, Grid, InputLabel, TextField, Typography, Select, MenuItem } from '@mui/material';
import { styles } from './jobGroupStyles';
import { retailerServiceTypeOptions } from '../credentials/retailerServiceTypeOptions';

export default function JobGroupsFormModal({
  mode,
  jobGroup,
  handleRetailerSelection,
  submitJobGroupForm,
  isCreateMode,
  isEditMode,
  ModifiedJobGroup,
  setModifiedJobGroup,
  selectedRetailer,
  retailers,
}) {
  const retailerSelectOnChange = (value) => {
    ModifiedJobGroup.retailerType = value?.retailerType;
    handleRetailerSelection(value);
  };

  const mapSelectedRetailerToRetailerServiceTypeOptions = () => {
    let foundRetailer = retailers.find((retailer) => retailer.retailerType === ModifiedJobGroup.retailerType);
    return retailerServiceTypeOptions[foundRetailer?.name] || retailerServiceTypeOptions['default'];
  };

  const selectedRetailerServiceTypeOnChange = (value) => {
    setModifiedJobGroup({ ...ModifiedJobGroup, retailerServiceType: value });
  };

  return (
    <>
      <Grid container spacing={1} alignItems="center">
        {isCreateMode && (
          <>
            <Grid item xs={1} align="left">
              <InputLabel htmlFor="format-input">Retailer :</InputLabel>
            </Grid>
            <Grid item xs={11} align="left">
              <Autocomplete
                sx={styles.Inputs}
                id="combo-box-demo"
                getOptionLabel={(option) => option.label || ''}
                value={selectedRetailer}
                options={retailers.map((retailer) => ({
                  value: retailer.id,
                  label: retailer.name,
                  retailerType: retailer.retailerType,
                }))}
                onChange={(event, value) => {
                  retailerSelectOnChange(value);
                }}
                isOptionEqualToValue={(option, value) => option.value === value}
                renderInput={(params) => <TextField {...params} />}
              />
            </Grid>
          </>
        )}
        {ModifiedJobGroup?.retailerType && (
          <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
            <Grid item sm={1} align="left">
              <Typography>Retailer Service Type:</Typography>
            </Grid>
            <Grid item sm={11} align="left">
              <FormControl fullWidth sx={{ paddingLeft: '5px' }}>
                <Select
                  sx={styles.Inputs}
                  className="react-select primary"
                  variant="outlined"
                  placeholder="Retailer Service Type (required)"
                  value={ModifiedJobGroup?.retailerServiceType || null}
                  displayEmpty
                  onChange={(event) => {
                    const selectedOption = mapSelectedRetailerToRetailerServiceTypeOptions().find(
                      (option) => option.value === event.target.value
                    );
                    selectedRetailerServiceTypeOnChange(event.target.value, selectedOption ? selectedOption.label : '');
                  }}
                >
                  {mapSelectedRetailerToRetailerServiceTypeOptions().map((option) => (
                    <MenuItem
                      key={option.value}
                      value={option.value}
                      selected={option.value === ModifiedJobGroup?.retailerServiceType}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        )}
        {isEditMode && (
          <>
            <Grid item xs={1} align="left">
              <InputLabel htmlFor="format-input">id :</InputLabel>
            </Grid>
            <Grid item xs={11} align="left">
              <TextField id="outlined-number" sx={styles.Inputs} autoComplete="off" value={ModifiedJobGroup.id || ''} disabled />
            </Grid>
          </>
        )}
        <Grid item xs={1} align="left">
          <InputLabel htmlFor="format-input">Name* :</InputLabel>
        </Grid>
        <Grid item xs={11} align="left">
          <TextField
            helperText={!ModifiedJobGroup?.name && 'This field is required.'}
            id="outlined-number"
            sx={styles.Inputs}
            autoComplete="off"
            value={ModifiedJobGroup.name || ''}
            onChange={(e) => {
              setModifiedJobGroup({ ...ModifiedJobGroup, name: e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={1} align="left">
          <InputLabel htmlFor="format-input">Description* :</InputLabel>
        </Grid>
        <Grid item xs={11} align="left">
          <TextField
            helperText={!ModifiedJobGroup?.description && 'This field is required.'}
            id="outlined-number"
            sx={styles.Inputs}
            autoComplete="off"
            value={ModifiedJobGroup.description || ''}
            onChange={(event) => {
              setModifiedJobGroup({ ...ModifiedJobGroup, description: event.target.value });
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}
