import React, { useState } from 'react';
import { ListItemButton, ListItemText, Box, OutlinedInput, IconButton, ListItemIcon, Typography } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/ContentCopy';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { styles } from '../../Pages/running-jobs/runningJobs.styles';
import { useTheme } from '@emotion/react';

function InfoFireNav({ title, data, Icon }) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [showFullText, setShowFullText] = useState(false);
  const contentString = data.reduce((result, item, index) => {
    const { icon, label } = item;
    const labelName = index === 0 ? label : ` - ${label}`;
    result += ` ${labelName}`;
    return result;
  }, '');

  const handleCopyClick = (value) => {
    navigator.clipboard.writeText(value);
  };

  function displayString(value, maxLength) {
    if (typeof value == 'string') {
      if (value.length <= maxLength) {
        return value;
      } else {
        return (
          <Box sx={styles.RowStyle}>
            <IconButton onClick={() => handleCopyClick(value)} aria-label="Copy" size="small">
              <FileCopyIcon />
            </IconButton>
            {!showFullText ? (
              <Typography
                sx={{
                  fontSize: 15,
                  fontWeight: 500,
                  lineHeight: '20px',
                }}
                onClick={() => setShowFullText(!showFullText)}
              >
                {value.substring(0, maxLength) + '...'}{' '}
              </Typography>
            ) : (
              <OutlinedInput sx={styles.Inputs} id="metadata-input" multiline rows={5} value={value.replace(/\s/g, '')} />
            )}
          </Box>
        );
      }
    }
    return value;
  }

  return (
    <Box
      sx={{
        bgcolor: theme.palette.mode === 'dark' ? '#344b61' : '#f4f7fa',
        borderRadius: 2,
        pb: open ? 2 : 0,
        margin: '8px',
      }}
    >
      <ListItemButton
        alignItems="flex-start"
        onClick={() => setOpen(!open)}
        sx={{
          px: 3,
          pt: 2.5,
          pb: open ? 0 : 2.5,
          '&:hover, &:focus': { '& svg': { opacity: open ? 1 : 0.5, pt: 0.5 } },
        }}
      >
        {!open && (
          <ListItemIcon sx={{ color: 'inherit' }}>
            <Icon />
          </ListItemIcon>
        )}
        <ListItemText
          primary={title}
          primaryTypographyProps={{
            fontSize: 15,
            fontWeight: 600,
            lineHeight: '20px',
            mb: '2px',
            pb: open ? 2.5 : 0.5,
          }}
          secondary={open ? null : contentString}
          secondaryTypographyProps={{
            noWrap: true,
            fontSize: 12,
            lineHeight: '16px',
          }}
          sx={{ my: 0 }}
        />
        <KeyboardArrowDown
          sx={{
            mr: -1,
            opacity: 0,
            transform: open ? 'rotate(-180deg)' : 'rotate(0)',
            transition: '0.2s',
          }}
        />
      </ListItemButton>
      {open &&
        data.map((item) => (
          <Box sx={styles.RowStyle}>
            <Typography sx={{ fontSize: 14, fontWeight: 600, pl: 3.2 }}>{item.label + ' : '}</Typography>
            <Typography sx={{ fontSize: 14, fontWeight: 500, pl: 3.2 }}>
              {typeof item.value === 'boolean' ? item.value.toString() : displayString(item.value, 100)}
            </Typography>
          </Box>
        ))}
    </Box>
  );
}

export default InfoFireNav;