import React, { useEffect, useState } from 'react';
import { credentialJobsColumns } from '../../Components/TableColumns/CredentialJobsColumns';
import { Grid, Button } from '@mui/material';
import { BasicTable } from '../../Components/Table/Table';
import CredentialJobsActionButtons from '../../Components/TableActionButtons/credentialJobsActionButtons';
import { getJobsByCredential, getSystemJobs, patchJob, deleteJob } from '../../../Utilities/ForemanApiClient';
import { filterTableData } from '../../Utils/filterTableData';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { showSnackbar } from '../../Utils/showSnackBar';
import JobForm from './JobForm';
import DeleteVerificationModal from '../../Components/DeleteComponent/DeleteModal';
import { styles } from './Credentials.styles';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { ta } from 'date-fns/locale';

const CredentialJobsManager = ({
  credential,
  setIsJobListingMode,
  childClient,
  formMode,
  setFormMode,
  showForm,
  setShowForm,
  activeJob,
  setActiveJob,
  submitJobForm,
  showSystemJobsModal,
  createJobOnClick,
  credentialJobsList,
  setCredentialJobsList,
  FilteredredentialJobsList,
  setFilteredCredentialJobsList,
}) => {
  const [searchValues, setSearchValues] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [runNowIsLoading, setRunNowIsLoading] = useState(0);
  const [showDeleteJobModal, setShowDeleteJobModal] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);

  useEffect(() => {
    setFilteredCredentialJobsList(credentialJobsList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage));
  }, [page, rowsPerPage]);

  useEffect(async () => {
    const jobs = credential?.id === undefined ? await getSystemJobs() : await getJobsByCredential(credential.id);

    setCredentialJobsList(jobs);
    setFilteredCredentialJobsList(jobs);
    setCount(jobs?.length);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    const tableAsArray = filterTableData(credentialJobsList, searchValues);
    setFilteredCredentialJobsList(tableAsArray);
  }, [searchValues]);

  const hasDuplicate = (job) => {
    const result = credentialJobsList.filter((jobItem) => job.jobTemplateId == jobItem.jobTemplateId);
    return result.length >= 2;
  };

  const jobActivationToggle = async (job) => {
    const response = await patchJob(job.id, '/isEnabled', !job.isEnabled);
    if (!response) {
      showSnackbar(
        'error',
        `Toggling job ${job.id} "IsEnabled" failed. Please try again later. If the issue persists, contact dev: foremandev@harvestgroup.com`
      );
    } else {
      showSnackbar('success', `${job.isEnabled ? 'disabled' : 'enabled'} Job ${job.id} successfully!`);
    }
    setCredentialJobsList((credentialJobsList) => {
      return credentialJobsList.map((item) => (item.id === job.id ? { ...item, isEnabled: !item.isEnabled } : item));
    });
    setFilteredCredentialJobsList((filteredCredentialsJobList) => {
      return filteredCredentialsJobList.map((item) => (item.id === job.id ? { ...item, isEnabled: !item.isEnabled } : item));
    });
  };

  const handleClose = () => {
    setShowForm(false);
    setActiveJob({});
  };

  const handleDeleteJob = async () => {
    const response = await deleteJob(activeJob.id);

    if (response) {
      showSnackbar('success', 'deleted successfully!');
    } else {
      showSnackbar(
        'error',
        'Deleteing Job list failed. Please try again later; if the issue persists, contact dev: foremandev@harvestgroup.com'
      );
    }
    setCredentialJobsList((credentialJobsList) => {
      return credentialJobsList.filter((item) => item.id !== activeJob.id);
    });
    setFilteredCredentialJobsList((filteredCredentialsJobList) => {
      return filteredCredentialsJobList.filter((item) => item.id !== activeJob.id);
    });
    setShowDeleteJobModal(false);
  };

  const deleteJobConfirmationModal = () => {
    return (
      <DeleteVerificationModal
        name={activeJob.name}
        referenceText={activeJob.name}
        modalType="Job"
        warningMessage="Deleting the Job will delete all Data Runs created by this Job."
        setShowModal={setShowDeleteJobModal}
        showModal={showDeleteJobModal}
        confirmedDeleteAction={handleDeleteJob}
      />
    );
  };

  const actionButtons = (job) => {
    return (
      <CredentialJobsActionButtons
        job={job}
        runNowIsLoading={runNowIsLoading}
        shouldRunAtOnClick={shouldRunAtOnClick}
        deleteJobOnClick={deleteJobOnClick}
        editJobOnClick={editJobOnClick}
        jobActivationToggle={jobActivationToggle}
      />
    );
  };

  const getUpdatedShouldRunAt = async (job) => {
    if (job.shouldRunAt < new Date(9999, 11, 31).toISOString()) {
      return '9999-12-31T23:59:59.9999';
    }

    return new Date(new Date().toUTCString()).toISOString();
  };

  const shouldRunAtOnClick = async (job) => {
    setRunNowIsLoading(job.id);
    let newShouldRunAt = await getUpdatedShouldRunAt(job);

    const response = await patchJob(job.id, '/shouldRunAt', newShouldRunAt);

    if (!response) {
      showSnackbar(
        'error',
        `Altering Job ${job.id} 'ShouldRunAt' failed. Please try again later; if the issue persists, contact Dev: foremandev@harvestgroup.com.`
      );
    } else {
      showSnackbar('success', `${job.shouldRunAt > new Date().toISOString() ? 'Running' : 'Running on cron'} Job ${job.id}!`);
    }

    const jobs = !job.credentialId ? await getSystemJobs() : await getJobsByCredential(job.credentialId);

    const tableAsArray = filterTableData(jobs, searchValues);
    setFilteredCredentialJobsList(tableAsArray);
    setCredentialJobsList(tableAsArray);

    setRunNowIsLoading(0);
  };

  const editJobOnClick = (job) => {
    setActiveJob(job);
    setFormMode('edit');
    setShowForm(true);
  };

  const deleteJobOnClick = (job) => {
    setShowDeleteJobModal(true);
    setActiveJob(job);
  };

  return (
    <>
      <Grid container sx={{ marginLeft: '5px' }}>
        <Grid item xs={10} align="left">
          {!showSystemJobsModal && (
            <span style={{ cursor: 'pointer' }} onClick={() => setIsJobListingMode(false)}>
              <NavigateBeforeIcon />
            </span>
          )}
          {`${!showSystemJobsModal ? `${credential?.username} - ` : 'System'} jobs`}
        </Grid>
        <Grid item xs={2} align="right">
          {showSystemJobsModal && (
            <>
              <Button
                variant="contained"
                size="md"
                sx={styles.CreateButton}
                startIcon={<AddOutlinedIcon />}
                onClick={() => createJobOnClick()}
              >
                Create Job
              </Button>
            </>
          )}
        </Grid>
      </Grid>
      <BasicTable
        data={FilteredredentialJobsList}
        columns={credentialJobsColumns}
        actionButtons={actionButtons}
        setSearchValues={setSearchValues}
        searchValues={searchValues}
        isLoading={isLoading}
        hasSelectionCheckboxes={false}
        openExtraInfoOnClick={true}
        isFullHeight={true}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        page={page}
        setPage={setPage}
        count={count}
        hasDuplicate={hasDuplicate}
      />
      <JobForm
        job={activeJob}
        mode={formMode}
        childClient={childClient}
        handleClose={handleClose}
        submitForm={submitJobForm}
        credential={credential}
        open={showForm}
      />
      {showDeleteJobModal && deleteJobConfirmationModal()}
    </>
  );
};

export default CredentialJobsManager;
