export const uploadFilesColumns = [
  {
    Header: 'Job Id',
    accessor: 'id',
    id: 'id',
    width: '6vw',
  },
  {
    Header: 'Job Run Id',
    accessor: 'jobRunId',
    id: 'jobRunId',
    width: '6vw',
  },
  {
    Header: 'Name',
    accessor: 'name',
    id: 'name',
    width: '20vw',
    sortable: true,
    filterable: true,
  },
  {
    Header: 'Client Name',
    accessor: 'childClientName',
    id: 'childClientName',
    width: '14vw',
    sortable: true,
    filterable: true,
  },
  {
    Header: 'Last Updated Time',
    accessor: 'lastUpdatedTimestamp',
    id: 'lastUpdatedTimestamp',
    sortable: false,
    filterable: false,
    width: '10vw',
  },
  {
    Header: 'Status',
    accessor: 'status',
    id: 'status',
    sortable: false,
    filterable: false,
    width: '8vw',
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    id: 'actions',
    width: '8vw',
  },
];
