/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import history from './history';
import App from './App';
import 'bootstrap/dist/css/bootstrap.css';
import './paper-dashboard-pro/assets/scss/paper-dashboard.scss?v=1.1.0';
import './paper-dashboard-pro/assets/demo/demo.css';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import './App.css';
import AmazonSellingPartnerApiAuth from './amazon-selling-partner-api-auth/AmazonSellingPartnerApiAuth';
import AmazonSellingPartnerApiRefreshToken from './amazon-selling-partner-api-auth/AmazonSellingPartnerApiRefreshToken';
import AmazonAdvertisingApiAuth from './amazon-advertising-api-auth/AmazonAdvertisingApiAuth';
import AmazonAdvertisingApiRefreshToken from './amazon-advertising-api-auth/AmazonAdvertisingApiRefreshToken';
import AmazonBrandAnalyticsLandingPage from './amazon-brand-analytics-landing-page/AmazonBrandAnalyticsLandingPage';
import TokenRefresh from './V2/Pages/token-refresh/TokenRefresh';
import { AuthProvider } from './AuthContext';
import { SnackbarProvider } from 'notistack';
import AppV2 from './AppV2';
import { TimerProvider } from './V2/Context/UploadFilesTimerContext';

ReactDOM.render(
  <Router history={history}>
    <Routes>
      <Route
        path="/refreshToken/:token"
        element={
          <CookiesProvider>
            <TokenRefresh />
          </CookiesProvider>
        }
      />
      <Route
        path="/sellingPartnerApiAuth"
        element={
          <CookiesProvider>
            <AmazonSellingPartnerApiAuth />
          </CookiesProvider>
        }
      />
      <Route
        path="/sellingPartnerApiRefreshToken"
        element={
          <CookiesProvider>
            <AmazonSellingPartnerApiRefreshToken />
          </CookiesProvider>
        }
      />
      <Route
        path="/amazonAdvertisingApiAuth"
        element={
          <CookiesProvider>
            <AmazonAdvertisingApiAuth />
          </CookiesProvider>
        }
      />
      <Route
        path="/amazonAdvertisingApiRefreshToken"
        element={
          <CookiesProvider>
            <AmazonAdvertisingApiRefreshToken />
          </CookiesProvider>
        }
      />
      <Route
        path="/amazonBrandAnalytics"
        element={
          <CookiesProvider>
            <AmazonBrandAnalyticsLandingPage />
          </CookiesProvider>
        }
      />
      <Route
        path="/V2/*"
        element={
          <AuthProvider>
            <SnackbarProvider>
              <TimerProvider>
                <AppV2 />
              </TimerProvider>
            </SnackbarProvider>
          </AuthProvider>
        }
      />
      <Route path="*" element={<Navigate to="/V2/clientmanager" />} />
    </Routes>
  </Router>,
  document.getElementById('root')
);
