import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Accordion, AccordionSummary, AccordionDetails } from './AccordionStyles';

function AccordionComponent({ AccordionTitle, AccordionContent }) {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>{AccordionTitle}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>{AccordionContent}</Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
export default AccordionComponent;
