
import React from 'react';
const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    p: '1.5rem',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    height: '80%',
    borderRadius: '0.5rem',
    overflowY: 'auto',
    width: '50%',
  };
  
  const FieldWrapper = ({ children }) => {
    return <div style={{ display: 'flex', flexDirection: 'column', gap: '0.3rem', width: '100%' }}>{children}</div>;
  };
  export { modalStyle, FieldWrapper };