export const interfaceBooleanFieldValue = (name, modifiedJobTemplate, selectedInterface) => {
  if (modifiedJobTemplate.parameterDetail && modifiedJobTemplate.parameterDetail[name] != undefined) {
    return modifiedJobTemplate.parameterDetail[name] == 'true' || modifiedJobTemplate.parameterDetail[name] == true;
  }

  return selectedInterface.properties.find((property) => property.name == name).defaultValue;
};

export const interfaceInputBooleanOnChange = (name, modifiedJobTemplate, selectedInterface, setModifiedJobTemplate) => {
  const originalValue =
    modifiedJobTemplate.parameterDetail[name] == undefined
      ? selectedInterface.properties.find((property) => property.name == name).defaultValue
      : modifiedJobTemplate.parameterDetail[name];

  const newValue = !(originalValue == 'true' || originalValue == true);

  setModifiedJobTemplate({
    ...modifiedJobTemplate,
    parameterDetail: {
      ...modifiedJobTemplate.parameterDetail,
      [name]: newValue,
    },
  });
};
export const interfaceInputMultiSelectOnChange = (name, event, setModifiedJobTemplate, modifiedJobTemplate) => {
  setModifiedJobTemplate({
    ...modifiedJobTemplate,
    parameterDetail: { ...modifiedJobTemplate.parameterDetail, [name]: event.toString() },
  });
};

export const interfaceInputDateOnChange = (name, date, setModifiedJobTemplate, modifiedJobTemplate) => {
  setModifiedJobTemplate({
    ...modifiedJobTemplate,
    parameterDetail: { ...modifiedJobTemplate.parameterDetail, [name]: date },
  });
};
export const interfaceInputFieldValue = (name, modifiedJobTemplate) => {
  return (modifiedJobTemplate?.parameterDetail && modifiedJobTemplate.parameterDetail[name]) || '';
};

export const interfaceInputFieldOnChange = (name, event, setModifiedJobTemplate, modifiedJobTemplate) => {
  setModifiedJobTemplate({
    ...modifiedJobTemplate,
    parameterDetail: { ...modifiedJobTemplate.parameterDetail, [name]: event.target.value },
  });
};

export const interfaceInputEnumOnChange = (name, value, setModifiedJobTemplate, modifiedJobTemplate) => {
  setModifiedJobTemplate({
    ...modifiedJobTemplate,
    parameterDetail: { ...modifiedJobTemplate.parameterDetail, [name]: value.props.value },
  });
};
