import { DeleteOutline, EditOutlined } from '@mui/icons-material';
import { Box, Button, Chip, alpha, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { deleteTaskGroup, getDacqMembers } from '../../../Utilities/ForemanApiClient';
import { API_ROOT } from '../../../api-config';
import BasicDeleteModal from '../../Components/DeleteComponent/DeleteModal';
import AddTaskGroupModal from '../../Components/dacq/add-taskgroup-modal/addTaskGroupModal';
import CustomAvatarGroup from '../../Components/dacq/customAvatarGroup';
import { ErrorComponent } from '../../Components/dacq/errorComponent';
import { TaskGroupTableBase } from '../../Components/dacq/taskGroupTableBase';
import TaskGroupTableHeader from '../../Components/dacq/taskgroup-table-header/taskGroupTableHeader';
import { useFetch } from '../../hooks/useFetch';
import { ActionType, taskGroupColumns } from './taskGroupUtils';

function ManageTaskGroups() {
  const [open, setOpen] = useState(false);
  const [actionType, setActionType] = useState(ActionType.ADD);
  const [defaultTaskGroup, setDefaultTaskGroup] = useState(null);
  const [initialTaskGroupData, setInitialTaskGroupData] = useState([]);
  const [filteredTaskGroupData, setFilteredTaskGroupData] = useState([]);
  const [dacqMembersList, setDacqMembersList] = useState({});
  const theme = useTheme();
  const [isDacqMembersListFetching, setIsDacqMembersListFetching] = useState(false);
  const { data, isLoading, error } = useFetch({ url: `${API_ROOT}/TaskGroups/GetTaskGroups`, method: 'GET' });

  useEffect(async () => {
    if (data) {
      setIsDacqMembersListFetching(true);
      const groupmembersList = await getDacqMembers({
        memberEmails: [],
      });

      setIsDacqMembersListFetching(false);
      setDacqMembersList(groupmembersList);
      setInitialTaskGroupData(data);
      setFilteredTaskGroupData(data);
    }
  }, [data]);

  useEffect(() => {
    if (initialTaskGroupData.length > 0) {
      setFilteredTaskGroupData(initialTaskGroupData);
    }
  }, [initialTaskGroupData]);

  const [dialogOpenStates, setDialogOpenStates] = useState([]);

  useEffect(() => {
    if (data && data.length) {
      setDialogOpenStates(new Array(data.length).fill(false));
    }
  }, [data]);

  const handleDeleteButtonClick = (index) => {
    const updatedDialogOpenStates = [...dialogOpenStates];
    updatedDialogOpenStates[index] = true;
    setDialogOpenStates(updatedDialogOpenStates);
  };

  const handleCloseModal = (index) => {
    const updatedDialogOpenStates = [...dialogOpenStates];
    updatedDialogOpenStates[index] = false;
    setDialogOpenStates(updatedDialogOpenStates);
  };

  const handleFilterChange = (filterOption) => {
    if (filterOption['activeOnly']) {
      const filtered = initialTaskGroupData.filter((taskGroup) => taskGroup.isActive);
      setFilteredTaskGroupData(filtered);
    } else {
      setFilteredTaskGroupData(initialTaskGroupData);
    }
  };

  const tableBody = filteredTaskGroupData.map((row, index) => {
    return {
      name: row.name,
      description: row.description,
      minimumRequiredMembers: row.minimumRequiredMembers,
      members: (
        <CustomAvatarGroup
          alignment="start"
          max={3}
          list={
            Array.isArray(dacqMembersList) &&
            dacqMembersList.filter((member) => row.memberEmails.includes(member.email)).map((member) => member.displayName)
          }
        />
      ),
      isActive: (
        <Chip
          label={row.isActive ? 'Active' : 'Inactive'}
          sx={{
            borderRadius: '0.5rem',
            width: '5rem',
            bgcolor: alpha(theme.palette.taskGroupColors[row.isActive ? 'success' : 'red'], 0.1),
            color: theme.palette.taskGroupColors[row.isActive ? 'success' : 'red'],
          }}
        />
      ),
      actions: (
        <div style={{ display: 'flex', gap: '1rem' }}>
          <Button
            onClick={() => {
              setActionType(ActionType.VIEW);
              setDefaultTaskGroup(row);
              setOpen(true);
            }}
            startIcon={<EditOutlined />}
            variant="outlined"
            sx={{
              color: 'button.primary',
              borderColor: 'button.primary',
            }}
          >
            Manage
          </Button>
          <Button
            onClick={() => handleDeleteButtonClick(index)}
            startIcon={<DeleteOutline />}
            variant="contained"
            sx={{
              bgcolor: theme.palette.taskGroupColors.red,
              ':hover': {
                bgcolor: theme.palette.taskGroupColors.red,
              },
            }}
          >
            Delete
          </Button>
          <BasicDeleteModal
            showModal={dialogOpenStates[index]}
            message={`Are you sure you want to delete the task group "${row.name}"?`}
            referenceText={row.name}
            name={row.name}
            setShowModal={(value) => {
              const updatedDialogOpenStates = [...dialogOpenStates];
              updatedDialogOpenStates[index] = value;
              setDialogOpenStates(updatedDialogOpenStates);
            }}
            modalType={row.name}
            confirmedDeleteAction={() => {
              deleteTaskGroup(row.id);
              const newData = [...initialTaskGroupData];
              const index = newData.findIndex((item) => item.id === row.id);
              newData.splice(index, 1);
              setInitialTaskGroupData(newData);
              handleCloseModal(index);
            }}
            onClose={() => {
              handleCloseModal(index);
            }}
          />
        </div>
      ),
    };
  });

  if (error) {
    return <ErrorComponent />;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', gap: '1rem', paddingX: '3rem' }}>
      <TaskGroupTableHeader
        onAddClicked={() => {
          setActionType(ActionType.ADD);
          setDefaultTaskGroup(null);
          setOpen(true);
        }}
        onFilterChanged={handleFilterChange}
      />
      <AddTaskGroupModal
        handleClose={() => setOpen(false)}
        open={open}
        argsActionType={actionType}
        taskGroup={defaultTaskGroup}
        onTaskGroupAdd={(task) => {
          const newData = [...initialTaskGroupData];
          const index = newData.findIndex((item) => item.id === task.id);
          if (index === -1) {
            newData.push(task);
          } else {
            newData[index] = task;
          }
          newData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
          setInitialTaskGroupData(newData);
          handleFilterChange(''); 
        }}
      />

      <TaskGroupTableBase
        fullWidth
        isLoading={isLoading || isDacqMembersListFetching}
        tableBody={tableBody}
        columns={taskGroupColumns}
      />
    </Box>
  );
}

export default ManageTaskGroups;