import React from 'react';
import { ReactComponent as ErrorSvg } from '../../../resources/error_svg.svg';

export const ErrorComponent = () => {
  
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '80vh' }}>
      <ErrorSvg width={450} height={450} />
      <h1>Oops!</h1>
      <p>
        We're sorry, but something went wrong. Please contact {' '}
        <a href="mailto:foremandev@harvestgroup.com">foremandev@harvestgroup.com</a> for assistance.
      </p>
    </div>
  );
};
