import React from 'react'
import { Tooltip } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { styles } from '../../Pages/running-jobs/runningJobs.styles';

function DefaultDeleteActionButton({ elementToDelete, deleteFunction , title}) {
  return (
    <>
      <Tooltip title={title} placement="left">
        <HighlightOffIcon
          sx={styles.ActionButtons}
          onClick={() => {
            deleteFunction(elementToDelete);
          }}
        />
      </Tooltip>
    </>
  );
}

export default DefaultDeleteActionButton