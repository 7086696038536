/* eslint-disable eqeqeq */
import moment from 'moment';
import React, { useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Button, FormGroup, UncontrolledTooltip } from 'reactstrap';
import BasicManagementPage from '../basic-management-page/ManagementPage';
import { getDataRuns } from '../Utilities/ForemanApiClient';
import { getDate, downloadArrayDataCSV } from '../Utilities/Utilities';

const showDelayValue = 1000;
const hideDelayValue = 0;

const dataRunsColumns = [
  {
    Header: 'Data Run Id',
    accessor: 'id',
    width: 115,
  },
  {
    Header: 'Job Id',
    accessor: 'jobId',
    width: 110,
  },
  {
    Header: 'Job Run Id',
    accessor: 'jobRunId',
    width: 110,
  },
  {
    Header: 'File Name',
    accessor: 'filename',
    width: 250,
  },
  {
    Header: (
      <div>
        <span id="datarunsstatus">Status</span>
        <UncontrolledTooltip placement="auto" target="datarunsstatus" delay={{ show: showDelayValue, hide: hideDelayValue }}>
          <span>Most recent status of the Data Run</span>
        </UncontrolledTooltip>
      </div>
    ),
    accessor: 'status',
    width: 100,
    sortable: false,
    filterable: true,
  },
  {
    Header: 'Start Time',
    accessor: 'startTime',
    width: 200,
  },
  {
    Header: (
      <span>
        <span id="datarunselapsedtime">Elapsed Time</span>
        <UncontrolledTooltip placement="auto" target="datarunselapsedtime" delay={{ show: showDelayValue, hide: hideDelayValue }}>
          <span>Elapsed time since start of the Data Run (hours:minutes:seconds:milliseconds)</span>
        </UncontrolledTooltip>
      </span>
    ),
    accessor: 'elapsedTime',
    width: 200,
  },
  {
    Header: 'End Time',
    accessor: 'endTime',
    width: 200,
  },
];

export default function DataRuns(props) {
  const [startDate, setStartDate] = useState(moment(new Date()).format('MM-DD-YYYY'));
  const [endDate, setEndDate] = useState(moment(new Date()).format('MM-DD-YYYY'));
  const [showCalendar, setShowCalendar] = useState(false);
  const [sortDirection, setSortDirection] = useState('ascending');
  const [sort, setSort] = useState('');
  const [filters, setFilters] = useState('');
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  const actionButtons = () => {
    return <div />;
  };

  const onClick = () => {
    showCalendar ? setShowCalendar(false) : setShowCalendar(true);
  };

  const getFilteredRuns = async (limit, offset, paginationFilters, paginationSort, paginationSortDirection, excludeFilter) => {
    setSort(paginationSort);
    setSortDirection(paginationSortDirection);
    setFilters(paginationFilters);

    return await getDataRuns(
      startDate,
      endDate,
      limit,
      offset,
      paginationFilters,
      paginationSort,
      paginationSortDirection,
      excludeFilter
    );
  };

  const getRunsForDownload = async () => {
    const detailedStartDate = getDate(startDate);
    const detailedEndDate = getDate(endDate);

    await getDataRuns(startDate, endDate, 0, 0, filters, sort, sortDirection, false).then((response) => {
      downloadArrayDataCSV(
        false,
        response.records,
        `DataRuns_${detailedStartDate.year}${detailedStartDate.month}${detailedStartDate.day}_${detailedEndDate.year}${detailedEndDate.month}${detailedEndDate.day}.csv`
      );
    });
  };

  const showCalendarButton = (
    <div>
      <Button onClick={onClick} className="pull-right title-button">
        {showCalendar ? <>Submit </> : <>Show Calendar</>}
      </Button>
      {showCalendar ? (
        <FormGroup className="pull-right">
          <div>
            <DateRangePicker
              onChange={(item) => {
                const newStartDate = moment(item.selection.startDate).format('MM-DD-YYYY');
                const newEndDate = moment(item.selection.endDate).format('MM-DD-YYYY');

                setDateRange([item.selection]);
                setStartDate(newStartDate);
                setEndDate(newEndDate);
              }}
              showSelectionPreview
              moveRangeOnFirstSelection={false}
              months={2}
              ranges={dateRange}
              direction="horizontal"
            />
          </div>
        </FormGroup>
      ) : null}
    </div>
  );

  const csvExport = (
    <div>
      <Button onClick={getRunsForDownload} color="info" size="lg" className="btn-icon btn-link like pull-right" id="export">
        <i className="fa fa-cloud-download" />
        <UncontrolledTooltip placement="auto" target="export" delay={{ show: showDelayValue, hide: hideDelayValue }}>
          Export filtered data to CSV
        </UncontrolledTooltip>
      </Button>
    </div>
  );

  /* eslint-disable react-hooks/exhaustive-deps */
  const DataRunsTable = React.useMemo(
    () => (
      <>
        <BasicManagementPage
          key={showCalendar}
          name="Data Runs"
          actionButtons={actionButtons}
          getData={getFilteredRuns}
          columns={dataRunsColumns}
          pagination
          defaultPageSize={50}
          headerButton={showCalendarButton}
          footer={csvExport}
        />
      </>
    ),
    [dateRange, showCalendar, filters]
  );

  return DataRunsTable;
}
