import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';

export const styles = {
  flexContainer: {
    display: 'flex',
    bgcolor: 'background.default',
    height: '100vh',
  },
  drawerHeaderBackground: {
    bgcolor: 'drawerHeader.primary',
  },
  drawerHeaderSecondaryBackground: {
    bgcolor: '#7FBCD2',
  },
  chevronIcon: {
    color: '#ffffff',
    padding: 0,
    margin: 0,
  },
  mainBox: {
    flexGrow: 1,
    p: 3,
  },
  drawerTitle: {
    fontFamily: 'Helvetica',
    color: '#ffffff',
  },
  drawerPaperProps: {
    backgroundColor: 'drawer.primary',
  },
  title: {
    align: 'left',
  },
};

export const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));
