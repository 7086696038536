import React from 'react';
import { Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { styles } from '../../Pages/job-templates/jobTemplates.styles';

function JobTemplatesActionButtons({
  jobTemplate,
  handleEditJobTemplateOnClick,
  handleCreateJobTemplateFromCopyOnClick,
  handleDeleteJobTemplate,
}) {
  return (
    <>
      <Tooltip title="Create from copy " placement="left">
        <ContentCopyIcon
          sx={styles.ActionButtons}
          onClick={async () => {
            handleCreateJobTemplateFromCopyOnClick(jobTemplate);
          }}
        />
      </Tooltip>
      <Tooltip title="Edit Job template " placement="left">
        <EditOutlinedIcon
          sx={styles.ActionButtons}
          onClick={async () => {
            handleEditJobTemplateOnClick(jobTemplate);
          }}
        />
      </Tooltip>
      <Tooltip title="Delete Job template " placement="left">
        <DeleteOutlineOutlinedIcon
          sx={styles.ActionButtons}
          onClick={async () => {
            handleDeleteJobTemplate(jobTemplate);
          }}
        />
      </Tooltip>
    </>
  );
}

export default JobTemplatesActionButtons;
