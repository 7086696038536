import { Divider, Grid, IconButton, Popover, Tab, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import TabList from '@mui/lab/TabList';
import CloseIcon from '@mui/icons-material/Close';
import EmptyState from '../../Assets/EmptyState';
import { styles } from './NotificationMenu.styles';
import FiberNewOutlinedIcon from '@mui/icons-material/FiberNewOutlined';
import { CredentialsContext } from '../../Context/CredentialsWithBadPasswordContext';
import LoginStatusIcon from './LoginStatusIcon';
import { Link } from 'react-router-dom';

function NotificationMenu(props) {
  const { isOpen, handleClose, notificationsAnchorEl } = props;
  const [value, setValue] = useState('0');
  const { credentialsWithBadPassword } = useContext(CredentialsContext);

  const handleValueChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Popover
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      anchorEl={notificationsAnchorEl}
      onClose={handleClose}
      open={isOpen}
    >
      <Grid container justifyContent="space-between" alignItems="center" sx={{ width: 500, ...styles.background }}>
        <Grid item>
          <Typography variant="h7" color="textPrimary" sx={{ padding: 2 }}>
            Notifications:
          </Typography>
        </Grid>
        <Grid item>
          <IconButton aria-label="close" onClick={handleClose} size="large" disableRipple>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
      <TabContext value={value}>
        <div style={{ maxHeight: '800px', overflow: 'hidden' }}>
          <TabList
            onChange={handleValueChange}
            aria-label="Notification_tabs"
            sx={{
              paddingLeft: 2,
              '& .Mui-selected:focus': {
                outline: 'none',
              },
              ...styles.background,
            }}
          >
            <Tab
              label={
                <div>
                  Bad passwords {credentialsWithBadPassword?.length > 0 && <FiberNewOutlinedIcon style={{ color: 'red' }} />}
                </div>
              }
              value="0"
            />
            <Tab label="General" value="1" />
          </TabList>
          <TabPanel value="0" sx={{ maxHeight: 'calc(800px - 50px)', overflowY: 'auto', ...styles.background }}>
            {credentialsWithBadPassword?.map((credential) => (
              <Link
                style={{ color: 'inherit' }}
                to={`/V2/clientmanager/${credential.childClient.parentClientId}/${credential.childClientId}`}
              >
                <div key={credential.id} style={styles.notificationDiv}>
                  <LoginStatusIcon status={credential.loginStatusDescription} />
                  <Typography variant="h7" sx={{ mr: 1 }}>
                    {credential.loginStatusDescription}: {credential.childClient.name}
                  </Typography>
                  <div>
                    <Typography variant="h7">{credential.username}</Typography>
                  </div>
                  <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
                </div>
              </Link>
            ))}
          </TabPanel>
          <TabPanel value="1" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', ...styles.background }}>
            <EmptyState />
            <Typography variant="h7" sx={{ textAlign: 'center' }}>
              Empty inbox! No fresh notifications to view.
            </Typography>
            <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
          </TabPanel>
        </div>
      </TabContext>
    </Popover>
  );
}

export default NotificationMenu;
