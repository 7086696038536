export const credentialJobsColumns = [
  {
    Header: 'Job Id',
    accessor: 'id',
    id: 'id',
    width: '6vw',
  },
  {
    Header: 'Name',
    accessor: 'name',
    id: 'name',
    width: '30vw',
  },
  {
    Header: 'Job Group Id',
    accessor: 'jobGroupId',
    id: 'jobGroupId',
    width: '10vw',
  },
  {
    Header: 'Next Run',
    accessor: 'nextRun',
    id: 'nextRun',
    width: '10vw',
  },
  {
    Header: 'Cron Expression',
    accessor: 'cronExpression',
    id: 'cronExpression',
    width: '10vw',
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    id: 'actions',
    width: '10vw',
  },
];
