import React from 'react';
import {Grid, Skeleton } from '@mui/material';

export default function DacqDashboardSkeleton() {
  return (
    <Grid container spacing={2} sx={{ p: 2 }}>
    {[...Array(4)].map((_, i) => (
      <Grid item xs={12} sm={6} key={i}>
        <Skeleton variant="rectangular" width="100%" height={200} />
      </Grid>
    ))}
  </Grid>
  );
}