/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Col,
  Spinner,
  Form,
  Row,
  Label,
  FormGroup,
  Button,
  UncontrolledTooltip,
  CardText,
} from 'reactstrap';
import Select from 'react-select';
import { useCookies } from 'react-cookie';
import { v4 as uuid } from 'uuid';
import base64 from 'base-64';
import { getAmazonRegionForCredential } from '../Utilities/ForemanApiClient';
import { AMAZON_SELLING_PARTNER_API_REDIRECT_URI } from '../api-config';
import HarvestLogo from '../resources/harvest_logomark.svg';

export default function AmazonSellingPartnerApiAuth() {
  const [cookies, setCookie] = useCookies(['foremanState']);
  const [region, setRegion] = useState([]);
  const [portal, setPortal] = useState([]);
  const [selectedMarketplace, setSelectedMarketplace] = useState({ value: 0, label: 'None' });

  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const queryParameters = new URLSearchParams(window.location.search);
  const credentialId = queryParameters.get('credentialId');
  const foremanState = base64.encode(uuid());

  const genericErrorMessage = 'ERROR: Something went wrong when trying to get the Amazon portal and region.';

  useEffect(() => {
    const firstAvailableRetailerServiceType = mapSelectedRegionToMarketplaceOptions()[0];
    selectedMarketplaceOnChange(firstAvailableRetailerServiceType.value, firstAvailableRetailerServiceType.label);

    initializePage(credentialId);
  }, [credentialId]);

  const initializePage = async (credentialId) => {
    const regionAndPortal = await getAmazonRegionForCredential(credentialId);

    if (regionAndPortal) {
      setRegion(regionAndPortal.Region);
      setPortal(regionAndPortal.Portal);
    } else {
      setErrorMessage(genericErrorMessage);
    }

    setIsLoading(false);
  };

  const saveCredentialIdToCookies = () => {
    setCookie('credentialId', credentialId, {
      path: '/',
      secure: true,
    });
  };

  const saveStateToCookies = () => {
    setCookie('foremanState', foremanState, {
      path: '/',
      secure: true,
    });
  };

  const mapSelectedRegionToMarketplaceOptions = () => {
    if (portal === 'seller') {
      var marketplaceOptions = {
        default: [{ value: 0, label: 'None' }],
        'North America': [
          { value: 'https://sellercentral.amazon.com', label: 'US' },
          { value: 'https://sellercentral.amazon.ca', label: 'Canada' },
          { value: 'https://sellercentral.amazon.com.mx', label: 'Mexico' },
          { value: 'https://sellercentral.amazon.com.br', label: 'Brazil' },
        ],
        Europe: [
          { value: 'https://sellercentral-europe.amazon.com', label: 'Spain' },
          { value: 'https://sellercentral-europe.amazon.com', label: 'UK' },
          { value: 'https://sellercentral-europe.amazon.com', label: 'France' },
          { value: 'https://sellercentral.amazon.nl', label: 'Netherlands' },
          { value: 'https://sellercentral-europe.amazon.com', label: 'Germany' },
          { value: 'https://sellercentral-europe.amazon.com', label: 'Italy' },
          { value: 'https://sellercentral.amazon.se', label: 'Sweden' },
          { value: 'https://sellercentral.amazon.pl', label: 'Poland' },
          { value: 'https://sellercentral.amazon.eg', label: 'Egypt' },
          { value: 'https://sellercentral.amazon.tr', label: 'Turkey' },
          { value: 'https://sellercentral.amazon.sa', label: 'Saudi Arabia' },
          { value: 'https://sellercentral.amazon.ae', label: 'U.A.E.' },
          { value: 'https://sellercentral.amazon.in', label: 'India' },
          { value: 'https://sellercentral.amazon.com.be', label: 'Belgium' },
        ],
        'Far East': [
          { value: 'https://sellercentral.amazon.sg', label: 'Singapore' },
          { value: 'https://sellercentral.amazon.com.au', label: 'Australia' },
          { value: 'https://sellercentral.amazon.co.jp', label: 'Japan' },
        ],
      };
    } else if (portal === 'vendor') {
      var marketplaceOptions = {
        default: [{ value: 0, label: 'None' }],
        'North America': [
          { value: 'https://vendorcentral.amazon.com', label: 'US' },
          { value: 'https://vendorcentral.amazon.ca', label: 'Canada' },
          { value: 'https://vendorcentral.amazon.com.mx', label: 'Mexico' },
          { value: 'https://vendorcentral.amazon.com.br', label: 'Brazil' },
        ],
        Europe: [
          { value: 'https://vendorcentral.amazon.es', label: 'Spain' },
          { value: 'https://vendorcentral.amazon.co.uk', label: 'UK' },
          { value: 'https://vendorcentral.amazon.fr', label: 'France' },
          { value: 'https://vendorcentral.amazon.com.be', label: 'Belgium' },
          { value: 'https://vendorcentral.amazon.nl', label: 'Netherlands' },
          { value: 'https://vendorcentral.amazon.de', label: 'Germany' },
          { value: 'https://vendorcentral.amazon.it', label: 'Italy' },
          { value: 'https://vendorcentral.amazon.se', label: 'Sweden' },
          { value: 'https://vendorcentral.amazon.pl', label: 'Poland' },
          { value: 'https://vendorcentral.amazon.me', label: 'Egypt' },
          { value: 'https://vendorcentral.amazon.tr', label: 'Turkey' },
          { value: 'https://vendorcentral.amazon.me', label: 'U.A.E.' },
          { value: 'https://vendorcentral.amazon.in', label: 'India' },
        ],
        'Far East': [
          { value: 'https://vendorcentral.amazon.sg', label: 'Singapore' },
          { value: 'https://vendorcentral.amazon.com.au', label: 'Australia' },
          { value: 'https://vendorcentral.amazon.co.jp', label: 'Japan' },
        ],
      };
    } else {
      return [{ value: 0, label: 'None' }];
    }

    return marketplaceOptions[region] || marketplaceOptions['default'];
  };

  const selectedMarketplaceOnChange = (value, label) => {
    setSelectedMarketplace({ value: value, label: label });
  };

  const sendToAmazon = async () => {
    saveCredentialIdToCookies();
    saveStateToCookies();

    const applicationId = encodeURI('amzn1.sp.solution.8d69f91b-204d-41f0-ad98-2ce7afffdb60');

    window.location.href = `${selectedMarketplace.value}/apps/authorize/consent?application_id=${applicationId}&state=${foremanState}&redirect_uri=${AMAZON_SELLING_PARTNER_API_REDIRECT_URI}`;
  };

  return (
    <>
      <div className="content compact-centered">
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">
                Authorize Harvest Group
                <img className="float-right" alt="Logo" src={HarvestLogo} />
              </CardTitle>
            </CardHeader>
            <CardBody className="form-modal-body">
              <Form>
                <Row>
                  <Label sm="2">Amazon Marketplace</Label>
                  <Col sm="10">
                    <FormGroup>
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        placeholder="Amazon Marketplace"
                        name="singleSelect"
                        value={selectedMarketplace}
                        onChange={(option) => selectedMarketplaceOnChange(option.value, option.label)}
                        options={mapSelectedRegionToMarketplaceOptions().map((option) => ({
                          value: option.value,
                          label: option.label,
                        }))}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
              <CardText>
                {'Would you like to authorize Harvest Group LLC to access your Amazon data through Selling Partner API?'}
              </CardText>
              <Button
                disabled={selectedMarketplace.value === 0}
                onClick={() => {
                  sendToAmazon();
                }}
              >
                Authorize
              </Button>
            </CardBody>
          </Card>
        </Col>
      </div>
    </>
  );
}
