/* eslint-disable eqeqeq */
import { Button, Divider, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { jobGroupsColumns } from '../../Components/TableColumns/jobGroupsColumns';
import { BasicTable } from '../../Components/Table/Table';
import AddIcon from '@mui/icons-material/Add';
import JobGroupsActionButtons from '../../Components/TableActionButtons/JobGroupsActionButtons';
import { styles } from './jobGroupStyles';
import {
  getJobGroups,
  deleteJobGroup,
  getRetailers,
  updateJobGroup,
  createJobGroup,
  getJobTemplates,
  deleteMultipleJobGroups,
} from '../../../Utilities/ForemanApiClient';
import JobGroupsFormModal from './jobGroupsFormModal';
import DeleteVerificationModal from '../../Components/DeleteComponent/DeleteModal';
import { showSnackbar } from '../../Utils/showSnackBar';
import TableToolbar from '../../Components/Table/TableToolbar';
import JobGroupTemplateManager from './JobGroupTemplateManager';

export default function JobGroups() {
  const [retailers, setRetailers] = useState([]);
  const [searchValues, setSearchValues] = useState({});
  const [modalJobTemplates, setModalJobTemplates] = useState([]);
  const [activeJobGroup, setActiveJobGroup] = useState({});
  const [updatedJobGroup, setUpdatedJobGroup] = useState({});
  const [filteredJobGroups, setFilteredJobGroups] = useState(null);
  const [showDeleteJobGroupModal, setShowDeleteJobGroupModal] = useState(false);
  const [showMultipleDeleteModal, setShowMultipleDeleteModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [formMode, setFormMode] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isCreateMode, setIsCreateMode] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isEditTemplatesMode, setIsEditTemplateMode] = useState(false);
  const [isCopyMode, setIsCopyMode] = useState(false);
  const [jobTemplates, setJobTemplates] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [timer, setTimer] = useState(null);
  const [selectedJobGroups, setSelectedJobGroups] = useState([]);

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const changeToCreateMode = () => {
    setFormMode('Create');
    setIsEditMode(false);
    setIsCopyMode(false);
    setIsCreateMode(true);
    setOpen(true);
  };

  const changeToEditMode = () => {
    setFormMode('Edit');
    setIsCreateMode(false);
    setIsCopyMode(false);
    setIsEditMode(true);
    setOpen(true);
  };

  const changeToCreateFromCopyMode = () => {
    setIsCopyMode(true);
    setIsEditMode(false);
    setIsCreateMode(false);
    setFormMode('Create');
    setOpen(true);
  };

  const changeToTemplatesEditMode = () => {
    setIsEditTemplateMode(true);
  };

  const createJobGroupOnClick = () => {
    const obj = { id: 0, retailerType: '', name: '', description: '', jobTemplateIds: [] };
    setUpdatedJobGroup(obj);
    changeToCreateMode();
  };

  const editJobGroupOnClick = (jobGroup) => {
    const obj = jobGroup;
    setUpdatedJobGroup(obj);
    changeToEditMode();
  };

  const enterJobGroupTemplatesEditMode = (jobGroup) => {
    setActiveJobGroup(jobGroup);
    setModalJobTemplates(jobGroup.jobTemplates);
    changeToTemplatesEditMode();
  };

  const createJobGroupFromCopyOnClick = (jobGroup) => {
    const editedJobGroup = JSON.parse(JSON.stringify(jobGroup));
    editedJobGroup.name = `Copy of ${editedJobGroup.name}`;
    editedJobGroup.description = `Copy of ${editedJobGroup.description}`;
    editedJobGroup.id = 0;

    changeToCreateFromCopyMode();
    setUpdatedJobGroup(editedJobGroup);
    setFormMode('Copy');
    setOpen(true);
  };

  const deleteGroupConfirmationModal = () => {
    return (
      <DeleteVerificationModal
        name={activeJobGroup?.name}
        referenceText={activeJobGroup?.name}
        modalType="Job Group"
        warningMessage="Deleting the Job Group will affect Credentials and Jobs that reference it."
        setShowModal={setShowDeleteJobGroupModal}
        showModal={showDeleteJobGroupModal}
        confirmedDeleteAction={confirmedDeleteAction}
      />
    );
  };

  const handleDeleteJobGroup = (jobGroup) => {
    setShowDeleteJobGroupModal(true);
    setActiveJobGroup(jobGroup);
  };

  const confirmDeleteMultiple = async () => {
    await deleteMultipleJobGroups(selectedJobGroups).then((response) => {
      if (!response) {
        showSnackbar(
          'error',
          `Job Group delete unsuccessful. Please try again later; if the issue persists, contact Dev : foremandev@harvestgroup.com.`
        );
      } else {
        showSnackbar('success', 'deleted successfully!');
      }
    });

    const response = await getJobGroups(rowsPerPage, page * rowsPerPage, getFilters(), '', 'Ascending');
    setCount(response.totalRecords);
    setShowMultipleDeleteModal(false);
    setFilteredJobGroups(filteredJobGroups.filter((item) => !selectedJobGroups.includes(item.id)));
    setSelectedJobGroups([]);
  };

  const confirmedDeleteAction = async () => {
    await deleteJobGroup(activeJobGroup.id).then((response) => {
      if (!response) {
        showSnackbar(
          'error',
          `Job Group ${activeJobGroup.id} delete unsuccessful. Please try again later; if the issue persists, contact Dev : foremandev@harvestgroup.com.`
        );
      } else {
        showSnackbar('success', 'deleted successfully!');
      }
    });

    fetchJobGroups();
    setShowDeleteJobGroupModal(false);
    setFilteredJobGroups(filteredJobGroups.filter((item) => item.id !== activeJobGroup.id));
  };

  const actionButtons = (jobGroup) =>
    JobGroupsActionButtons({
      handleDelete: handleDeleteJobGroup,
      editJobGroupOnClick,
      enterJobGroupTemplatesEditMode,
      createFromCopy: createJobGroupFromCopyOnClick,
      jobGroup,
    });

  const submitJobGroupForm = async (jobGroup) => {
    let success = false;
    if (formMode == 'Edit') {
      success = await updateJobGroup(jobGroup);
    } else {
      success = await createJobGroup(jobGroup);
    }
    if (!success) {
      showSnackbar(
        'error',
        `Job Group ${jobGroup.name} ${formMode} unsuccessful. Please try again later; if the issue persists, contact Dev : foremandev@harvestgroup.com.`
      );
    } else {
      showSnackbar('success', `Job Group ${jobGroup.name} ${formMode} successful`);
    }
    setOpen(false);
    let response = await getJobGroups(rowsPerPage, page * rowsPerPage, getFilters(), '', 'Ascending');
    setCount(response.totalRecords);
    setFilteredJobGroups(response.data);
  };
  const getFilters = () => {
    return searchValues
      ? Object.entries(searchValues)
          .map(([key, value]) => {
            if (value?.length > 0) return `&${key}=${value}`;
          })
          .join('')
      : '';
  };

  const fetchJobGroups = async () => {
    let response = await getJobGroups(rowsPerPage, page * rowsPerPage, getFilters(), '', 'Ascending');
    setCount(response.totalFilteredRecords);
    setFilteredJobGroups(response.data);
  };

  useEffect(async () => {
    setRetailers(await getRetailers());
    setJobTemplates(await getJobTemplates());
  }, []);

  useEffect(() => {
    if (activeJobGroup) {
      const filteredTemplates = modalJobTemplates.filter((template) => template.retailerType == activeJobGroup.retailerType);
      setModalJobTemplates(filteredTemplates);
    }
  }, [activeJobGroup]);

  useEffect(async () => {
    clearTimeout(timer);

    const newTimer = setTimeout(async () => {
      setIsLoading(true);
      await fetchJobGroups();
      setIsLoading(false);
    }, 500);

    setTimer(newTimer);
  }, [searchValues, rowsPerPage, page]);

  return (
    <>
      <Grid container rowSpacing={0}>
        <Grid item xs={6} align="left"></Grid>
        <Grid item xs={6} align="right">
          <Button variant="contained" sx={styles.CreateButton} startIcon={<AddIcon />} onClick={createJobGroupOnClick}>
            Create Group
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ mt: 1, mb: 1 }} />
      </Grid>
      {selectedJobGroups.length > 0 && !isEditTemplatesMode && (
        <TableToolbar
          selected={selectedJobGroups}
          setSelected={setSelectedJobGroups}
          modalType={'Job Groups'}
          warningMessage="Deleting the Job Groups will affect Credentials and Jobs that reference them."
          handleDeleteMultiple={confirmDeleteMultiple}
          filteredItems={filteredJobGroups}
          showModal={showMultipleDeleteModal}
          setShowModal={setShowMultipleDeleteModal}
        />
      )}
      <Grid item xs={12}>
        {!isEditTemplatesMode ? (
          <BasicTable
            data={filteredJobGroups}
            columns={jobGroupsColumns}
            actionButtons={actionButtons}
            setSearchValues={setSearchValues}
            searchValues={searchValues}
            isLoading={isLoading}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            page={page}
            setPage={setPage}
            count={count}
            isFullHeight={true}
            selected={selectedJobGroups}
            setSelected={setSelectedJobGroups}
            hasSelectionCheckboxes={true}
          />
        ) : (
          <JobGroupTemplateManager
            jobGroup={activeJobGroup}
            jobTemplates={jobTemplates}
            setIsEditTemplateMode={setIsEditTemplateMode}
          />
        )}
      </Grid>
      {showDeleteJobGroupModal && deleteGroupConfirmationModal()}
      <JobGroupsFormModal
        key={Date.now()}
        retailers={retailers}
        mode={formMode}
        isCreateMode={isCreateMode}
        isEditMode={isEditMode}
        isCopyMode={isCopyMode}
        open={open}
        handleCloseModal={handleCloseModal}
        handleOpenModal={handleOpenModal}
        jobGroup={updatedJobGroup}
        submit={submitJobGroupForm}
      />
    </>
  );
}
