export const runningJobsColumns = [
  {
    Header: 'Job ID',
    accessor: 'id',
    id: 'JobId',
    width: '6vw',
  },
  {
    Header: 'Name',
    accessor: 'name',
    id: 'Name',
    width: '20vw',
    sortable: true,
    filterable: true,
  },
  {
    id: 'ChildClientName',
    Header: 'Child Client Name',
    accessor: 'childClient.name',
    width: '12vw',
    sortable: true,
    filterable: true,
  },
  {
    Header: 'Username',
    accessor: 'credential.username',
    id: 'Username',
    width: '12vw',
  },
  {
    Header: 'ElapsedTime',
    accessor: 'elapsedTime',
    id: 'elapsedTime',
    width: '12vw',
    sortable: true,
    filterable: true,
  },
  {
    Header: 'Active//Future Data Runs',
    accessor: 'activeFutureCount',
    id: 'activeFutureCount',
    width: '12vw',
    sortable: true,
    filterable: true,
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    id: 'actions',
    sortable: false,
    filterable: false,
    width: '8vw',
  },
];

export const activeFutureDataRunsColumns = [
  {
    Header: 'Job ID',
    accessor: 'jobId',
    id: 'jobId',
    width: '6vw',
  },
  {
    Header: 'Client',
    accessor: 'childClientName',
    id: 'childClientName',
    width: '15vw',
    sortable: true,
    filterable: true,
  },
  {
    Header: 'Username',
    accessor: 'username',
    id: 'DataRunUsername',
    width: '15vw',
  },
  {
    Header: 'Job Template',
    accessor: 'jobTemplateName',
    id: 'jobTemplateName',
    width: '15vw',
    sortable: true,
    filterable: true,
  },
  {
    Header: 'Status',
    accessor: 'status',
    id: 'status',
    width: '15vw',
    sortable: true,
    filterable: true,
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    id: 'actions',
    sortable: false,
    filterable: false,
    width: '8vw',
  },
];
