/* eslint-disable eqeqeq */
import React from 'react';
import { Button } from 'reactstrap';
import { Card, CardText, CardTitle, Col } from 'reactstrap';
import { useCookies } from 'react-cookie';
import { AMAZON_ADVERTISING_API_REDIRECT_URI } from '../api-config';
import HarvestLogo from '../resources/harvest_logomark.svg';

export default function AmazonAdvertisingApiAuth() {
  const [cookies, setCookie] = useCookies(['credentialId']);

  const queryParameters = new URLSearchParams(window.location.search);
  const credentialId = queryParameters.get('credentialId');

  const saveCredentialIdToCookies = () => {
    setCookie('credentialId', credentialId, {
      path: '/',
      secure: true,
    });
  };

  const sendToAmazon = async () => {
    saveCredentialIdToCookies();

    const clientId = encodeURI('amzn1.application-oa2-client.06445c48fe654b3a857e73aecce2c1ef');
    const scope = encodeURI('advertising::campaign_management advertising::audiences');
    const redirectUri = encodeURI(AMAZON_ADVERTISING_API_REDIRECT_URI);

    window.location.href = `https://www.amazon.com/ap/oa?redirect_uri=${redirectUri}&client_id=${clientId}&scope=${scope}&response_type=code`;
  };

  return (
    <>
      <div className="content compact-centered">
        <Col md="12">
          <Card>
            <CardTitle tag="h4">
              Authorize Harvest Group
              <img className="float-right" alt="Logo" src={HarvestLogo} />
            </CardTitle>
            <CardText>
              <div id="advertising-api-result-message-container">
                <p>{'Would you like to authorize Harvest Group LLC to access your Amazon Advertising data?'}</p>
              </div>
            </CardText>
            <Button
              onClick={() => {
                sendToAmazon();
              }}
            >
              Authorize
            </Button>
          </Card>
        </Col>
      </div>
    </>
  );
}
