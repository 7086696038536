import { Autocomplete, FormControl, FormHelperText, Grid, InputLabel, OutlinedInput, TextField, Typography } from '@mui/material';
import React from 'react';
import { useEffect } from 'react';
import AdditionalPropertiesForm from '../../Components/AdditionalPropertiesForm/AdditionalPropertiesForm';
import { styles } from './jobTemplates.styles';
import { isValidJson } from '../../Utils/Utilities';

export default function JobTemplateForm(props) {
  const {
    jobTemplate,
    mode,
    selectedRetailer,
    retailers,
    modifiedJobTemplate,
    setModifiedJobTemplate,
    handleRetailerSelection,
    harvester,
    setHarvester,
    jobInterfaces,
    selectedInterface,
    setSelectedInterface,
    isMetadataValid,
    setIsMetadataValid,
    setDisableButtons,
  } = props;

  const retailerSelectOnChange = (value) => {
    modifiedJobTemplate.retailerType = value?.retailerType;
    handleRetailerSelection(value);
  };

  const harvesterSelectOnChange = (value) => {
    setHarvester(value);
    setModifiedJobTemplate({ ...modifiedJobTemplate, className: value?.value });
    handleSelectedInterfaceChange(jobInterfaces);
  };

  const handleSelectedInterfaceChange = (interfaces) => {
    const jobInterface = interfaces.find((jobInterface) => jobInterface.className === modifiedJobTemplate.className);
    setSelectedInterface(jobInterface);
  };

  const metadataOnChange = (value) => {
    setIsMetadataValid(value === '' || isValidJson(value));
    setModifiedJobTemplate({ ...modifiedJobTemplate, metadata: value });
  };

  useEffect(() => {
    handleSelectedInterfaceChange(jobInterfaces);
  }, [modifiedJobTemplate.className, jobInterfaces]);

  const additionalFields = (jobInterface, className) => {
    return (
      <AdditionalPropertiesForm
        formType="JobTemplate"
        className={className}
        properties={jobInterface.properties}
        modifiedJobTemplate={modifiedJobTemplate}
        setModifiedJobTemplate={setModifiedJobTemplate}
        selectedInterface={selectedInterface}
        setDisableButtons={setDisableButtons}
      />
    );
  };

  return (
    <>
      {mode == 'Edit' && (
        <>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={2} align="left">
              <InputLabel htmlFor="format-input">Id :</InputLabel>
            </Grid>
            <Grid item xs={10} align="left">
              <Typography sx={styles.Inputs}>{jobTemplate.id}</Typography>
            </Grid>
          </Grid>
        </>
      )}
      {mode == 'Create' && (
        <>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={2} align="left">
              <InputLabel htmlFor="format-input">Retailer :</InputLabel>
            </Grid>
            <Grid item xs={10} align="left">
              <Autocomplete
                sx={styles.Inputs}
                id="combo-box-demo"
                getOptionLabel={(option) => option.label || ''}
                value={selectedRetailer}
                options={retailers.map((retailer) => ({
                  value: retailer.id,
                  label: retailer.name,
                  retailerType: retailer.retailerType,
                }))}
                onChange={(event, value) => {
                  retailerSelectOnChange(value);
                }}
                isOptionEqualToValue={(option, value) => option.value === value}
                renderInput={(params) => <TextField {...params} />}
              />
            </Grid>
          </Grid>
        </>
      )}

      <Grid container spacing={1} alignItems="center">
        {selectedRetailer && (
          <>
            <Grid item xs={2} align="left">
              <InputLabel htmlFor="format-input">Harvester :</InputLabel>
            </Grid>
            <Grid item xs={10} align="left">
              <Autocomplete
                sx={styles.Inputs}
                id="combo-box-demo"
                getOptionLabel={(option) => option.label || ''}
                value={harvester ? harvester : ''}
                options={jobInterfaces?.map((jobInterface) => ({
                  value: jobInterface.className,
                  label: jobInterface.className,
                }))}
                onChange={(value, event) => {
                  harvesterSelectOnChange(event);
                }}
                renderInput={(params) => <TextField {...params} />}
              />

              {selectedInterface && (
                <Typography sx={{ m: 1 }} color="default" component="span">
                  {selectedInterface.description}
                </Typography>
              )}
            </Grid>
          </>
        )}
        <Grid item xs={2} align="left">
          <InputLabel htmlFor="format-input">Name* :</InputLabel>
        </Grid>
        <Grid item xs={10} align="left">
          <TextField
            helperText={!modifiedJobTemplate?.name && 'This field is required.'}
            id="outlined-number"
            sx={styles.Inputs}
            autoComplete="off"
            value={modifiedJobTemplate.name || ''}
            onChange={(e) => {
              setModifiedJobTemplate({ ...modifiedJobTemplate, name: e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={2} align="left">
          <InputLabel htmlFor="format-input">Description :</InputLabel>
        </Grid>
        <Grid item xs={10} align="left">
          <TextField
            id="outlined-number"
            sx={styles.Inputs}
            autoComplete="off"
            value={modifiedJobTemplate.description || ''}
            onChange={(e) => {
              setModifiedJobTemplate({ ...modifiedJobTemplate, description: e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={2} align="left">
          <InputLabel htmlFor="metadata-input">Metadata :</InputLabel>
        </Grid>
        <Grid item xs={10} align="left">
          <FormControl fullWidth error={!isMetadataValid} variant="outlined">
            <OutlinedInput
              sx={styles.Inputs}
              id="metadata-input"
              multiline
              rows={3}
              value={modifiedJobTemplate.metadata || ''}
              onChange={(e) => metadataOnChange(e.target.value)}
              label="Metadata"
            />
            {!isMetadataValid && <FormHelperText>Metadata must be valid JSON.</FormHelperText>}
          </FormControl>
        </Grid>
        {selectedInterface && selectedInterface.formatRequired && (
          <>
            <Grid item xs={2} align="left">
              <InputLabel htmlFor="format-input">Format :</InputLabel>
            </Grid>
            <Grid item xs={10} align="left">
              <TextField
                sx={styles.Inputs}
                id="format-input"
                fullWidth
                autoComplete="off"
                value={modifiedJobTemplate.format || ''}
                onChange={(e) => {
                  setModifiedJobTemplate({ ...modifiedJobTemplate, format: e.target.value });
                }}
              />
            </Grid>
          </>
        )}
        {selectedInterface && selectedInterface.properties && additionalFields(selectedInterface)}
      </Grid>
    </>
  );
}
