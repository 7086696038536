import React, { useState, useEffect } from 'react';
import { getJobInterfaces, getJobTemplates, getJobInterfacesByServiceType } from '../../../Utilities/ForemanApiClient';
import { Grid, Typography, Autocomplete, TextField } from '@mui/material';
import Select from '@mui/material/Select';
import CustomModal from '../../Components/Modals/CustomModal';
import JobFormAdditionalFields from './ConditionalInputs/JobFormAdditionalFields';
import { styles } from './Credentials.styles';

const JobForm = ({ job, mode, childClient, handleClose, submitForm, credential, open, handleAfterSubmit }) => {
  const [modifiedJob, setModifiedJob] = useState(job);
  const [disableButtons, setDisableButtons] = useState(false);
  const [jobTemplates, setJobTemplates] = useState([]);
  const [selectedJobTemplateEnum, setSelectedJobTemplateEnum] = useState({});
  const [selectedJobTemplate, setSelectedJobTemplate] = useState({});
  const [selectedInterface, setSelectedInterface] = useState({});

  function isSystemHarvester(template, interfaces) {
    return interfaces.find((jobInterface) => jobInterface.className === template.className && jobInterface.systemHarvester);
  }

  function isMatchingRetailerServiceType(template, interfaces) {
    const result = interfaces.find((jobInterface) => jobInterface.className === template.className);
    return (
      result &&
      (result.retailerServiceType == credential.retailerServiceType ||
        template.retailerType == childClient?.value?.retailer?.retailerType)
    );
  }

  const interfaceInputFieldValue = (name) => {
    if (name != 'CronExpression' && name != 'PriorityLevel') {
      return (modifiedJob.parameterValues && modifiedJob.parameterValues[name]) || '';
    }

    const nameCamelCase = name.charAt(0).toLowerCase() + name.slice(1);

    return modifiedJob[nameCamelCase] || '';
  };

  const interfaceInputFieldOnChange = (name, e) => {
    if (name != 'CronExpression') {
      setModifiedJob({
        ...modifiedJob,
        parameterValues: { ...modifiedJob.parameterValues, [name]: e.target.value },
      });
    } else {
      setModifiedJob({
        ...modifiedJob,
        cronExpression: e.target.value,
      });
    }
  };

  const interfaceInputMultiSelectOnChange = (name, e) => {
    const removeDuplicates = (array) => array.filter((value, index) => array.indexOf(value) === array.lastIndexOf(value));
    let value = '';

    if (e != null) {
      const filteredItems = removeDuplicates(e);
      value = Array.from(filteredItems, (option) => (option.value ? option.value : option)).toString();
    }

    setModifiedJob({
      ...modifiedJob,
      parameterValues: { ...modifiedJob.parameterValues, [name]: value },
    });
  };

  const interfaceInputEnumOnChange = (name, value) => {
    if (name != 'PriorityLevel') {
      setModifiedJob({
        ...modifiedJob,
        parameterValues: { ...modifiedJob.parameterValues, [name]: value },
      });
    } else {
      setModifiedJob({
        ...modifiedJob,
        priorityLevel: value,
      });
    }
  };

  const interfaceInputDateOnChange = (name, date) => {
    setModifiedJob({
      ...modifiedJob,
      parameterValues: { ...modifiedJob.parameterValues, [name]: new Date(date).toISOString() },
    });
  };

  const interfaceBooleanFieldValue = (name) => {
    if (name != 'ShouldObserveDaylightSavingsTime') {
      if (modifiedJob.parameterValues && modifiedJob.parameterValues[name] != undefined) {
        return modifiedJob.parameterValues[name] == 'true' || modifiedJob.parameterValues[name] == true;
      }
    } else if (modifiedJob && modifiedJob.shouldObserveDaylightSavingsTime != undefined) {
      return modifiedJob.shouldObserveDaylightSavingsTime == 'true' || modifiedJob.shouldObserveDaylightSavingsTime == true;
    }

    return selectedInterface.properties.find((property) => property.name == name).defaultValue;
  };

  const interfaceInputBooleanOnChange = (name) => {
    if (name != 'ShouldObserveDaylightSavingsTime') {
      const originalValue =
        modifiedJob.parameterValues[name] == undefined
          ? selectedInterface.properties.find((property) => property.name == name).defaultValue
          : modifiedJob.parameterValues[name];

      const newValue = !(originalValue == 'true' || originalValue == true);

      setModifiedJob({
        ...modifiedJob,
        parameterValues: {
          ...modifiedJob.parameterValues,
          [name]: newValue,
        },
      });
    } else {
      setModifiedJob({
        ...modifiedJob,
        shouldObserveDaylightSavingsTime: !(
          modifiedJob.shouldObserveDaylightSavingsTime == 'true' || modifiedJob.shouldObserveDaylightSavingsTime == true
        ),
      });
    }
  };

  const jobTemplateSelectOnChange = (value) => {
    setSelectedJobTemplateEnum(value);
    const changedCredential = { ...modifiedJob, jobTemplateId: value?.value ? value?.value : value, parameterValues: {} };
    setModifiedJob(changedCredential);
  };

  const handleSubmit = async () => {
    setDisableButtons(true);
    await submitForm(modifiedJob);
    setDisableButtons(false);
  };

  const additionalFields = () => {
    return (
      <JobFormAdditionalFields
        formType="Job"
        className={selectedInterface.className}
        setDisableButtons={setDisableButtons}
        properties={selectedInterface.properties}
        interfaceBooleanFieldValue={interfaceBooleanFieldValue}
        interfaceInputBooleanOnChange={interfaceInputBooleanOnChange}
        interfaceInputDateOnChange={interfaceInputDateOnChange}
        interfaceInputFieldValue={interfaceInputFieldValue}
        interfaceInputFieldOnChange={interfaceInputFieldOnChange}
        interfaceInputEnumOnChange={interfaceInputEnumOnChange}
        interfaceInputMultiSelectOnChange={interfaceInputMultiSelectOnChange}
        modifiedJob={modifiedJob}
        setModifiedJob={setModifiedJob}
      />
    );
  };

  useEffect(() => {
    setModifiedJob(job);
  }, [job]);

  useEffect(async () => {
    const templates = await getJobTemplates();

    if (job?.credentialId) {
      const interfaces = await getJobInterfacesByServiceType(credential?.retailerServiceType);

      const clientTemplates = templates.filter(
        (template) => isMatchingRetailerServiceType(template, interfaces) || isSystemHarvester(template, interfaces)
      );
      setJobTemplates(clientTemplates);
    } else {
      const retailerType = childClient?.value == '' ? 'NotSet' : childClient?.value?.retailer?.retailerType;
      const interfaces = await getJobInterfaces(retailerType);
      const systemTemplates = templates.filter((template) => isSystemHarvester(template, interfaces));
      setJobTemplates(systemTemplates);
    }
  }, []);

  useEffect(async () => {
    if (selectedJobTemplate) {
      const retailerType = childClient?.value == '' ? 'NotSet' : childClient?.value?.retailer?.retailerType;
      const interfaces = await getJobInterfaces(retailerType);

      const jobInterface = interfaces.find((jobInterface) => jobInterface?.className === selectedJobTemplate.className);

      selectedJobTemplate.parameterDetail &&
        Object.keys(selectedJobTemplate.parameterDetail).forEach((parameter) => {
          const foundInterfaceProperty = jobInterface?.properties?.find((prop) => prop.name == parameter);

          if (foundInterfaceProperty) {
            foundInterfaceProperty.defaultValue =
              selectedJobTemplate.parameterDetail[parameter] != 'false' && selectedJobTemplate.parameterDetail[parameter];

            foundInterfaceProperty.required =
              foundInterfaceProperty.required == true
                ? selectedJobTemplate.parameterDetail[parameter]
                : foundInterfaceProperty.required;
          }
        });

      if (jobInterface) {
        jobInterface.properties.forEach((property) => {
          if (
            !(property.name in selectedJobTemplate.parameterDetail) &&
            property.defaultValue != '' &&
            property.defaultValue != undefined &&
            property.defaultValue != false
          ) {
            property.defaultValue = undefined;
          }
        });
      }

      setSelectedInterface(jobInterface);
    }
  }, [selectedJobTemplate, childClient]);

  useEffect(() => {
    const jobTemplateId = job && job.jobTemplateId;
    const jobTemplate = jobTemplateId && jobTemplates.find((template) => template.id == jobTemplateId);
    const jobGroupObj = jobTemplate ? { value: jobTemplateId, label: jobTemplate.name } : {};

    setSelectedJobTemplateEnum(jobGroupObj);
  }, [job, credential, jobTemplates]);

  useEffect(() => {
    const jobTemplate = jobTemplates?.find(
      (template) => template?.id == (selectedJobTemplateEnum?.value || selectedJobTemplateEnum)
    );
    setSelectedJobTemplate(jobTemplate);

    if (jobTemplate) {
      const parameterValues = { ...jobTemplate.parameterDetail };
      delete parameterValues.CronExpression;
      delete parameterValues.PriorityLevel;
      delete parameterValues.ShouldObserveDaylightSavingsTime;

      const job = {
        ...modifiedJob,
        cronExpression: modifiedJob.cronExpression || (jobTemplate.parameterDetail && jobTemplate.parameterDetail.CronExpression),
        priorityLevel: modifiedJob.priorityLevel || (jobTemplate.parameterDetail && jobTemplate.parameterDetail.PriorityLevel),
        shouldObserveDaylightSavingsTime:
          modifiedJob.shouldObserveDaylightSavingsTime ||
          (jobTemplate.parameterDetail && jobTemplate.parameterDetail.ShouldObserveDaylightSavingsTime),
        parameterValues: modifiedJob.parameterValues || {},
      };
      setModifiedJob(job);
    }
  }, [selectedJobTemplateEnum]);

  return (
    <CustomModal
      open={open}
      handleClose={handleClose}
      title={mode + ' job'}
      isEditMode={mode == 'edit'}
      isCreateMode={mode != 'edit'}
      confirmEnabled={true}
      disabled={disableButtons}
      handleConfirm={handleSubmit}
    >
      <>
        {mode == 'edit' && (
          <Grid container alignItems="center">
            <Grid item xs={2}>
              <Typography>Id</Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography>{job?.id}</Typography>
            </Grid>
          </Grid>
        )}
        {credential?.id && (
          <Grid container alignItems="center">
            <Grid item xs={2}>
              <Typography>Credential Id</Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography>{credential && credential?.id}</Typography>
            </Grid>
          </Grid>
        )}
        {jobTemplates && (
          <Grid container alignItems="center">
            <Grid item sm={2}>
              <Typography>Job Template</Typography>
            </Grid>
            <Grid item sm={10}>
              <Autocomplete
                sx={styles.Inputs}
                id="combo-box-demo"
                getOptionLabel={(option) => option.label || ''}
                value={selectedJobTemplateEnum || null}
                options={jobTemplates.map((jobTemplate) => ({
                  value: jobTemplate.id,
                  label: jobTemplate.name,
                }))}
                onChange={(event, value) => {
                  jobTemplateSelectOnChange(value);
                }}
                isOptionEqualToValue={(option, value) => option.value === value}
                renderInput={(params) => <TextField {...params} />}
              />
            </Grid>
          </Grid>
        )}
        {selectedInterface && selectedInterface.properties && additionalFields(selectedInterface)}
      </>
    </CustomModal>
  );
};

export default JobForm;
