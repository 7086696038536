import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { styles } from '../../../Pages/job-groups/jobGroupStyles';
import Menu from '@mui/material/Menu';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Checkbox, FormControlLabel } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

export default function TaskGroupTableHeader({ onAddClicked, onFilterChanged }) {
  const [filterOptions, setFilterOptions] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);

  function openAddTaskGroupDialog(event) {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  }

  function closeAddTaskGroupDialog() {
    setAnchorEl(null);
  }

  const handleFilterChange = (event) => {
    const option = event.target.name;
    const checked = event.target.checked;
    const updatedFilterOptions = { ...filterOptions, [option]: checked };
    setFilterOptions(updatedFilterOptions);
    onFilterChanged(updatedFilterOptions);
  };

  return (
    <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', gap: '1rem' }}>
      <Button
        aria-owns={anchorEl ? 'simple-menu' : undefined}
        aria-haspopup="true"
        sx={{ ...styles.CreateButton }}
        onClick={openAddTaskGroupDialog}
        variant="contained"
        startIcon={<FilterAltIcon />}
      >
        Filter
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeAddTaskGroupDialog}
        MenuListProps={{
          sx: {
            ...styles.MenuList,
            padding: '1rem',
          },
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          <h6>Filter Options</h6>
          <FormControlLabel
            control={
              <Checkbox
                name="activeOnly"
                checked={filterOptions.activeOnly || false}
                onChange={handleFilterChange}
              />
            }
            label="Is Active"
          />
        </div>
      </Menu>
      <Button sx={{ ...styles.CreateButton }} variant="contained" onClick={onAddClicked} startIcon={<AddIcon />}>
        Add
      </Button>
    </div>
  );
}
