import React from 'react';
import { useTheme } from '@emotion/react';

const AmazonIcon = (props) => {
  const theme = useTheme();
  const src = theme.palette.mode === 'dark' ? '/amazonLightIcon.svg' : '/amazonIcon.svg';
  return <img alt="amazonIcon" src={src} {...props} sx={{ mr: 2 }} />;
};

export default AmazonIcon;
