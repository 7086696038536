import React from 'react';
import { Tooltip } from '@mui/material';
import ListIcon from '@mui/icons-material/FormatListBulleted';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { styles } from '../../Pages/running-jobs/runningJobs.styles';

function RunningJobsActionButtons({ runningJob, handleShowDataRuns, handleShowRunningJobInfo }) {
  return (
    <>
      <Tooltip title="View Data Runs " placement="left">
        <ListIcon
          sx={styles.ActionButtons}
          onClick={() => {
            handleShowDataRuns(runningJob);
          }}
        />
      </Tooltip>
      <Tooltip title="Show Job Info " placement="left">
        <InfoOutlinedIcon
          sx={styles.ActionButtons}
          onClick={() => {
            handleShowRunningJobInfo(runningJob);
          }}
        />
      </Tooltip>
    </>
  );
}

export default RunningJobsActionButtons;
