import { InputAdornment, SvgIcon, TextField, Select, MenuItem } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import React, { useState } from 'react';
import { retailers } from '../../Assets/Retailers';
import {RetailerIcon} from '../Icons/RetailerIcon';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

const SearchBox = ({ searchKey, listType, width, setSearchValues, isRetailer }) => {
  const [selectedRetailer, setSelectedRetailer] = useState('');

  const handleSearch = (event) => {
    if (isRetailer) {
      setSelectedRetailer(
        event.target.value == ''
          ? ''
          : retailers.filter((retailer) => retailer.retailerType == event.target.value)[0].retailerName
      );
    }
    setSearchValues((prevSearchValues) => ({
      ...prevSearchValues,
      [listType]: event.target.value.toString().toLowerCase(),
    }));
  };

  if (!isRetailer) {
    return (
      <TextField
        key={searchKey}
        sx={{ width: width, p: 0.5 }}
        variant="standard"
        placeholder={`Search`}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SvgIcon fontSize="small">
                <SearchIcon />
              </SvgIcon>
            </InputAdornment>
          ),
        }}
        onChange={(event) => handleSearch(event, listType)}
      />
    );
  } else {
    return (
      <Select
        key={searchKey}
        sx={{ width: '-webkit-fill-available', p: 0.5 }}
        variant="standard"
        value={selectedRetailer}
        onChange={(event) => {
          setSelectedRetailer(event.target.name);
          handleSearch(event, listType);
        }}
        displayEmpty
        renderValue={() => (
          <>
            <div style={{ display: 'flex' }}>
              <InputAdornment position="start" style={{ alignItems: 'flex-start' }}>
                <SvgIcon fontSize="small">
                  <SearchIcon />
                </SvgIcon>
              </InputAdornment>
              {selectedRetailer === '' ? '-All-' : selectedRetailer}
            </div>
          </>
        )}
      >
        <MenuItem value={''}>
          <FormatListBulletedIcon sx={{ margin: '0px 10px 0px 10px' }} /> -All-{' '}
        </MenuItem>
        {retailers.map((retailer, index) => {
          return (
            <MenuItem name={retailer.retailerName} value={retailer.retailerType} key={index}>
              <RetailerIcon retailerName={retailer.retailerName} retailerType={retailer.retailerType} />
              {retailer.retailerName}
            </MenuItem>
          );
        })}
      </Select>
    );
  }
};

export default SearchBox;
