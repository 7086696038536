import { Button, Divider, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  createJobTemplate,
  deleteJobTemplate,
  deleteMultipleJobTemplates,
  getPaginatedJobTemplates,
  getRetailers,
  updateJobTemplate,
} from '../../../Utilities/ForemanApiClient';
import { BasicTable } from '../../Components/Table/Table';
import AddIcon from '@mui/icons-material/Add';
import JobTemplatesActionButtons from '../../Components/TableActionButtons/JobTemplatesActionButtons';
import { styles } from './jobTemplates.styles';
import { jobTemplatesColumns } from '../../Components/TableColumns/jobTemplateColumns';
import { showSnackbar } from '../../Utils/showSnackBar';
import JobTemplateFormModal from './jobTemplateFormModal';
import DeleteVerificationModal from '../../Components/DeleteComponent/DeleteModal';
import TableToolbar from '../../Components/Table/TableToolbar';

export default function JobTemplates(props) {
  const [retailers, setRetailers] = useState([]);
  const [filteredJobTemplates, setFilteredJobTemplates] = useState(null);
  const [searchValues, setSearchValues] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formMode, setFormMode] = useState('');
  const [activeJobTemplate, setActiveJobTemplate] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showDeleteJobTemplateModal, setShowDeleteJobTemplateModal] = useState(false);
  const [showMultipleDeleteModal, setShowMultipleDeleteModal] = useState(false);
  const [isCreateMode, setIsCreateMode] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isCopyMode, setIsCopyMode] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [timer, setTimer] = useState(null);
  const [selectedJobTemplates, setSelectedJobTemplates] = useState([]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const fetchRetailers = async () => {
      const result = await getRetailers();
      result.unshift({ id: 99, name: 'SYSTEM', retailerType: 'NotSet' });
      setRetailers(result);
    };

    fetchRetailers();
  }, []);

  const handleCreateJobTemplateOnClick = () => {
    const obj = { id: 0, retailerType: '', name: '', className: '', description: '', format: '', parameterDetail: {} };
    setActiveJobTemplate(obj);
    changeToCreateMode();
    setIsModalOpen(true);
  };

  const handleCreateJobTemplateFromCopyOnClick = (jobTemplate) => {
    const obj = JSON.parse(JSON.stringify(jobTemplate));
    obj.id = 0;
    setActiveJobTemplate(obj);
    changeToCopyMode();
    handleOpenModal();
  };

  const handleEditJobTemplateOnClick = (jobTemplate) => {
    setActiveJobTemplate(jobTemplate);
    changeToEditMode();
    handleOpenModal();
  };

  const changeToCreateMode = () => {
    setFormMode('Create');
    setIsEditMode(false);
    setIsCopyMode(false);
    setIsCreateMode(true);
  };

  const changeToEditMode = () => {
    setFormMode('Edit');
    setIsCreateMode(false);
    setIsCopyMode(false);
    setIsEditMode(true);
  };
  const changeToCopyMode = () => {
    setFormMode('Copy');
    setIsCreateMode(false);
    setIsEditMode(false);
    setIsCopyMode(true);
  };

  const submitJobTemplateForm = async (jobTemplate) => {
    let success = false;
    setIsSubmitting(true);

    if (formMode == 'Edit') {
      success = await updateJobTemplate(jobTemplate);
      setFilteredJobTemplates(filteredJobTemplates.map((item) => (item.id === jobTemplate.id ? jobTemplate : item)));
    } else {
      success = await createJobTemplate(jobTemplate);
    }

    if (success) {
      showSnackbar('success', `Job Template ${jobTemplate?.name} ${formMode}${formMode === 'edit' ? 'ed' : 'e'}d  successfully.`);
    } else {
      showSnackbar(
        'error',
        `Job Template ${jobTemplate.name} ${formMode} unsuccessful. Please try again later; if the issue persists, contact Dev: foremandev@harvestgroup.com.`
      );
    }
    setIsSubmitting(false);
    handleCloseModal();
  };

  const deleteJobTemplateConfirmationModal = () => {
    return (
      <DeleteVerificationModal
        name={activeJobTemplate?.name}
        referenceText={activeJobTemplate?.name}
        modalType="Job Template"
        warningMessage="Deleting the Job Template will delete all of the Jobs referencing it!."
        setShowModal={setShowDeleteJobTemplateModal}
        showModal={showDeleteJobTemplateModal}
        confirmedDeleteAction={confirmedDeleteJobTemplate}
      />
    );
  };

  const confirmDeleteMultiple = async () => {
    await deleteMultipleJobTemplates(selectedJobTemplates).then((response) => {
      if (!response) {
        showSnackbar(
          'error',
          `Job Templates delete unsuccessful. Please try again later; if the issue persists, contact Dev: foremandev@harvestgroup.com.`
        );
      } else {
        showSnackbar('success', 'deleted successfully!');
      }
    });
    setShowMultipleDeleteModal(false);
    setFilteredJobTemplates(filteredJobTemplates.filter((item) => !selectedJobTemplates.includes(item.id)));
    setSelectedJobTemplates([]);
  };

  const handleDeleteJobTemplate = (jobTemplate) => {
    setActiveJobTemplate(jobTemplate);
    setShowDeleteJobTemplateModal(true);
  };

  const confirmedDeleteJobTemplate = async () => {
    await deleteJobTemplate(activeJobTemplate.id).then((response) => {
      if (!response) {
        showSnackbar(
          'error',
          `Job Template ${activeJobTemplate.id} delete unsuccessful. Please try again later; if the issue persists, contact Dev: foremandev@harvestgroup.com.`
        );
      } else {
        showSnackbar('success', 'deleted successfully!');
      }
    });
    setShowDeleteJobTemplateModal(false);
    setFilteredJobTemplates(filteredJobTemplates.filter((item) => item.id !== activeJobTemplate.id));
  };

  useEffect(async () => {
    const filters = searchValues
      ? Object.entries(searchValues)
          .map(([key, value]) => {
            if (value?.length > 0) return `&${key}=${value}`;
          })
          .join('')
      : '';
    clearTimeout(timer);

    const newTimer = setTimeout(async () => {
      setIsLoading(true);
      const response = await getPaginatedJobTemplates(rowsPerPage, page * rowsPerPage, filters, '', 'Ascending');
      setCount(response.totalFilteredRecords);
      setFilteredJobTemplates(response.data);
      setIsLoading(false);
    }, 500);

    setTimer(newTimer);
  }, [searchValues, rowsPerPage, page]);

  const actionButtons = (jobTemplate) => {
    return (
      <JobTemplatesActionButtons
        jobTemplate={jobTemplate}
        handleEditJobTemplateOnClick={handleEditJobTemplateOnClick}
        handleCreateJobTemplateFromCopyOnClick={handleCreateJobTemplateFromCopyOnClick}
        handleDeleteJobTemplate={handleDeleteJobTemplate}
      />
    );
  };

  return (
    <>
      <Grid container rowSpacing={0}>
        <Grid item xs={6} align="left">
          <Typography>Showing {filteredJobTemplates?.length} filtered records </Typography>
        </Grid>
        <Grid item xs={6} align="right">
          <Button variant="contained" sx={styles.CreateButton} startIcon={<AddIcon />} onClick={handleCreateJobTemplateOnClick}>
            Create Template
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ mt: 1, mb: 1 }} />
      </Grid>

      {selectedJobTemplates.length > 0 && (
        <TableToolbar
          selected={selectedJobTemplates}
          setSelected={setSelectedJobTemplates}
          modalType={'Job Templates'}
          warningMessage="Deleting the Job Templates will delete all of the Jobs referencing them!"
          handleDeleteMultiple={confirmDeleteMultiple}
          filteredItems={filteredJobTemplates}
          showModal={showMultipleDeleteModal}
          setShowModal={setShowMultipleDeleteModal}
        />
      )}

      <Grid item xs={12}>
        <BasicTable
          data={filteredJobTemplates}
          columns={jobTemplatesColumns}
          actionButtons={actionButtons}
          setSearchValues={setSearchValues}
          searchValues={searchValues}
          isLoading={isLoading}
          isFullHeight={true}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          page={page}
          setPage={setPage}
          count={count}
          selected={selectedJobTemplates}
          setSelected={setSelectedJobTemplates}
          hasSelectionCheckboxes={true}
        />
      </Grid>
      {showDeleteJobTemplateModal && deleteJobTemplateConfirmationModal()}
      {isModalOpen && (
        <JobTemplateFormModal
          jobTemplate={activeJobTemplate}
          retailers={retailers}
          submit={submitJobTemplateForm}
          handleCloseModal={handleCloseModal}
          isModalOpen={isModalOpen}
          mode={formMode}
          isCreateMode={isCreateMode}
          isEditMode={isEditMode}
          isCopyMode={isCopyMode}
          isSubmitting={isSubmitting}
          setIsSubmitting={setIsSubmitting}
        />
      )}
    </>
  );
}
