import React from 'react';
import { grey } from '@mui/material/colors';

export const getDesignTokens = (mode) => ({
  palette: {
    mode,
    primary: {
      ...(mode === 'dark'
        ? {
          main: '#B7E3E8',
        }
        : { main: '#0371b6' }),
    },
    secondary: {
      ...(mode === 'dark'
        ? {
          main: '#ffffff',
        }
        : { main: '#0371b6' }),
    },
    ...(mode === 'dark'
      ? {
        background: {
          default: '#131e29',
          paper: '#26394c',
        },
      }
      : {
        background: {
          default: '#f6f8fb',
          paper: '#ffffff',
        },
      }),
    text: {
      ...(mode === 'light'
        ? {
          primary: grey[900],
          secondary: grey[800],
          danger: 'red',
        }
        : {
          primary: '#fff',
          secondary: grey[500],
          danger: '#A30000',
        }),
    },
    drawer: {
      ...(mode === 'light'
        ? {
          primary: '#ffffff',
          secondary: grey[800],
        }
        : {
          primary: '#1f3041',
          secondary: grey[500],
        }),
    },
    drawerHeader: {
      ...(mode === 'light'
        ? {
          primary: '#1f3041',
          secondary: grey[800],
        }
        : {
          primary: '#1f3041',
          secondary: grey[500],
        }),
    },
    popover: {
      ...(mode === 'light'
        ? {
          primary: '#ffffff',
        }
        : {
          primary: '#000000',
        }),
    },
    appBar: {
      ...(mode === 'light'
        ? {
          primary: '#1f3041',
        }
        : {
          primary: '#0F283D',
        }),
    },
    stepper: {
      ...(mode === 'light'
        ? {
          primary: '#ffffff',
          secondary: '#ff3333',
        }
        : {
          primary: '#000000',
          secondary: '#991e1e',
        }),
    },
    title: {
      ...(mode === 'light'
        ? {
          primary: '#ffffff',
        }
        : {
          primary: '#ffffff',
        }),
    },
    table: {
      ...(mode === 'light'
        ? {
          primary: '#ffffff',
        }
        : {
          primary: '#1f3041',
        }),
    },

    button: {
      ...(mode === 'light'
        ? {
          primary: '#0F283D',
        }
        : {
          primary: '#ffffff',
        }),
    },
    actionButtons: {
      ...(mode === 'light'
        ? {
          primary: '#0F283D',
        }
        : {
          primary: '#6E5D48',
        }),
    },
    notificationMenu: {
      ...(mode === 'light'
        ? {
          primary: '#ffffff',
        }
        : {
          primary: '#253a4d',
        }),
    },
    userMenu: {
      ...(mode === 'light'
        ? {
          primary: '#ffffff',
        }
        : {
          primary: '#26394c',
        }),
    },
    infoIcons: {
      ...(mode === 'light'
        ? {
          info: '#0371b6',
          critical: '#bf360c',
          alert: '#c4b351',
        }
        : {
          info: '#d5d3d8',
          critical: '#7f1805',
          alert: '#9c933d',
        }),
    },
    modal: {
      ...(mode === 'dark'
        ? {
          main: '#0371b6',
        }
        : { main: '#ffffff' }),
    },
    statusChips: {
      ...(mode === 'dark'
        ? {
          dangerChip: '#b32e15',
          successChip: '#437d43',
        }
        : { dangerChip: '#bf360c', successChip: '#71A471' }),
    },
    taskGroupColors: {
      ...(mode === 'dark'
        ? {
          primary: '#ffffff',
          red: '#FF8B8B',
          success: '#57F18D',
          grey: '#26394c',
          btnTxtPrimary: '#0f283d',
          btnSecondary: '#e3e3e3',
          greyBg: '#26394c',
          darkGrey: '#1f3041',
          whiteBg: '#0f171e',
          black: '#ffffff',
        }
        : {
          primary: '#0371b6',
          btnTxtPrimary: '#0f283d',
          red: '#bf2601',
          success: '#17C653',
          grey: '#eeeeee',
          btnSecondary: '#e6e9ed',
          greyBg: '#eeeeee',
          darkGrey: '#1f3041',
          whiteBg: '#ffffff',
          black: '#000000',
        }),
    },
  },
});
export const ColorModeContext = React.createContext({ toggleColorMode: () => { } });