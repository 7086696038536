import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress } from '@mui/material';
import ModalTitleIcon from '../Icons/ModalTitleIcon';
import { styles } from './CustomModal.styles';
import { getDesignTokens } from '../../Theme/theme';
import DoneAllIcon from '@mui/icons-material/DoneAll';

const CustomModal = ({
  open,
  handleClose,
  handleConfirm,
  children,
  title,
  isCreateMode,
  isEditMode,
  isCopyMode,
  isCalendarMode,
  disabled,
  customSize,
  isSubmitting,
  retailerType,
  confirmEnabled,
}) => {
  const designTokens = getDesignTokens(localStorage.getItem('mode'));
  const style = styles(designTokens);

  return (
    <>
      <Dialog open={open} onClose={handleClose} PaperProps={{ style: style.paper }} maxWidth={customSize ? customSize : 'xl'}>
        <DialogTitle style={style.title}>
          <ModalTitleIcon
            isCreateMode={isCreateMode}
            isEditMode={isEditMode}
            isCopyMode={isCopyMode}
            isCalendarMode={isCalendarMode}
            retailer={retailerType}
          />
          {title.toUpperCase()}
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            {confirmEnabled ? 'CANCEL' : 'CLOSE'}
          </Button>
          {handleConfirm && confirmEnabled && (
            <Button startIcon={<DoneAllIcon />} variant="contained" onClick={handleConfirm} disabled={disabled} color="primary">
              {isSubmitting ? <CircularProgress style={style.loader} /> : 'CONFIRM'}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CustomModal;