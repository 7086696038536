export const jobGroupsColumns = [
  {
    Header: 'Retailer',
    accessor: 'retailerType',
    id: 'retailerType',
    width: '6vw',
  },
  {
    Header: 'Name',
    accessor: 'name',
    id: 'name',
    width: '15vw',
  },
  {
    Header: 'Description',
    accessor: 'description',
    id: 'description',
    width: '20vw',
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    id: 'actions',
    sortable: false,
    filterable: false,
    width: '8vw',
  },
];
