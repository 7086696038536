import React, { useState } from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Checkbox, Button, Menu, FormControlLabel } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { format } from 'date-fns';
import { taskGroupHeaderStyles } from './taskGroupHeaderStyles';
import { styles } from '../../Pages/job-groups/jobGroupStyles';
import dayjs from 'dayjs';
import { isDateWithinCurrentWeek } from '../../Utils/Utilities';

export default function TaskGroupWeeklyViewHeader({ onFilterChanged, onWeekChanged }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterOptions, setFilterOptions] = useState({});
  const [selectedDate, setSelectedDate] = useState(dayjs());
  
  const getMonday = (date) => {
    const day = date.day();
    return day === 1 ? date : date.subtract(day - 1, 'day');
  };

  const goToPreviousWeek = () => {
    if (selectedDate) {
      const monday = getMonday(selectedDate);
      const newDate = monday.subtract(1, 'week');
      setSelectedDate(newDate);
      onWeekChanged(newDate);
    }
  };

  const goToNextWeek = () => {
    if (selectedDate) {
      const monday = getMonday(selectedDate);
      const newDate = monday.add(1, 'week');
      setSelectedDate(newDate);
      onWeekChanged(newDate);
    }
  };

  const handleOpenFilter = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function DateButtonField(props) {
    const { setOpen, label, id, disabled, InputProps: { ref } = {}, inputProps: { 'aria-label': ariaLabel } = {} } = props;

    return (
      <Button
        variant="text"
        id={id}
        disabled={disabled}
        ref={ref}
        aria-label={ariaLabel}
        sx={{
          fontSize: '1.5rem',
          outline: 'none',
          width: '15rem',
          '&:focus': {
            outline: 'none',
          },
          '&:hover': {
            bgcolor: 'transparent',
          },
        }}
        onClick={() => {
          setOpen(true);
        }}
      >
        {label
          ? !isDateWithinCurrentWeek(new Date(label))
            ? format(new Date(label), 'dd MMM yyyy')
            : 'Current Week'
          : 'Current Week'}
      </Button>
    );
  }

  function ButtonDatePicker(props) {
    const [open, setOpen] = useState(false);

    return (
      <DatePicker
        slots={{ ...props.slots, field: DateButtonField }}
        slotProps={{ ...props.slotProps, field: { setOpen } }}
        onChange={(newDate) => {
          setSelectedDate(newDate);
          onWeekChanged(newDate);
        }}
        {...props}
        open={open}
        shouldDisableDate={(date) => date.day() !== 1}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
      />
    );
  }

  const handleFilterChange = (event) => {
    const option = event.target.name;
    const checked = event.target.checked;
    const updatedFilterOptions = { ...filterOptions, [option]: checked };
    setFilterOptions(updatedFilterOptions);
    onFilterChanged(updatedFilterOptions);
  };

  return (
    <div style={taskGroupHeaderStyles.headerContainer}>
      <div style={{ display: 'flex', alignItems: 'center', width: 'fitContent' }}>
        <ArrowBackIosIcon
          sx={{
            cursor: 'pointer',
          }}
          onClick={goToPreviousWeek}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ButtonDatePicker
            label={selectedDate == null ? null : selectedDate.format('MM/DD/YYYY')}
            value={selectedDate}
            onChange={(newDate) => {
              setSelectedDate(newDate);
              onWeekChanged(newDate);
            }}
          />
        </LocalizationProvider>
        <ArrowForwardIosIcon
          sx={{
            cursor: 'pointer',
          }}
          onClick={goToNextWeek}
        />
      </div>
      <Button
        aria-owns={anchorEl ? 'simple-menu' : undefined}
        aria-haspopup="true"
        sx={{ ...styles.CreateButton }}
        onClick={handleOpenFilter}
        variant="contained"
        startIcon={<FilterAltIcon />}
      >
        Filter
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          sx: {
            ...styles.MenuList,
            padding: '1rem',
          },
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          <h6>Filter Options</h6>
          <FormControlLabel
            control={
              <Checkbox
                name="lowCapacity"
                checked={filterOptions.lowCapacity || false}
                onChange={handleFilterChange}
              />
            }
            label="Low Capacity"
          />
        </div>
      </Menu>
    </div>
  );
}
