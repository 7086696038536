import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import MainList from '../../Components/Sidebar/Mainlist';
import ForemanNavbar from '../../Components/ForemanNavBar/ForemanNavBar';
import { Drawer, DrawerHeader } from '../../Pages/main-page/page.styles';
import { styles } from './page.styles';
import { useTheme } from '@mui/material';
import Logo from '../../Assets/Logo';
import { ENVIRONMENT, isProductionEnvironment } from '../../../api-config';
import { CredentialsWithBadPasswordProvider } from '../../Context/CredentialsWithBadPasswordContext';

export default function Page(props) {
  const { children, title } = props;
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const theme = useTheme();

  const handleOpenDrawer = () => {
    setIsDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
  };

  return (
    <CredentialsWithBadPasswordProvider>
      <Box sx={styles.flexContainer}>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <CssBaseline />
        <ForemanNavbar isDrawerOpen={isDrawerOpen} handleOpenDrawer={handleOpenDrawer} />
        <Drawer
          variant="permanent"
          open={isDrawerOpen}
          PaperProps={{
            sx: styles.drawerPaperProps,
          }}
        >
          <DrawerHeader sx={isProductionEnvironment() ? styles.drawerHeaderBackground : styles.drawerHeaderSecondaryBackground}>
            {isDrawerOpen && (
              <>
                <Box sx={{ mr: 1.5 }}>
                  <Logo />
                </Box>
                <Typography variant="h6" noWrap component="div" sx={styles.drawerTitle}>
                  Foreman{isProductionEnvironment() ? '' : ` ${ENVIRONMENT}`}
                </Typography>
              </>
            )}
            <IconButton onClick={handleCloseDrawer} sx={{ margin: 0 }}>
              {theme.direction === 'rtl' ? (
                <ChevronRightIcon sx={styles.chevronIcon} />
              ) : (
                <ChevronLeftIcon sx={styles.chevronIcon} />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <MainList open={isDrawerOpen} />
        </Drawer>
        <Box component="main" sx={styles.mainBox}>
          <DrawerHeader />
          <Typography variant="h6" sx={styles.title}>
            {title}
          </Typography>
          <div>{children}</div>
        </Box>
      </Box>
    </CredentialsWithBadPasswordProvider>
  );
}
