import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  alpha,
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  useTheme,
} from '@mui/material';
import Menu from '@mui/material/Menu';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { getJobRunById } from '../../../../Utilities/ForemanApiClient';
import InfoChip from '../../../Components/Chip/Chip';
import { RetailerIcon } from '../../../Components/Icons/RetailerIcon';
import { getDacqTaskStatus, getDacqTaskStatusColor, getDacqTaskStatusName } from './dacqMemberTasksUtils';
import useAuth from '../../../../useAuthContext';
import { Link } from 'react-router-dom';
import { styles } from '../../../Components/Table/Table.styles';
import { retailers } from '../../../Assets/Retailers';
import { useFetch } from '../../../hooks/useFetch';
import { API_ROOT } from '../../../../api-config';
import { TableSkeleton } from '../taskGroupUtils';
import NoDataFound from '../../../Assets/NoDataFound';
import Select from '@mui/material/Select';
import { Button } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { styles as btnStyle } from '../../job-groups/jobGroupStyles';
import { ErrorComponent } from '../../../Components/dacq/errorComponent';
export default function DacqMemberTasksSummary() {
  const [memberTasks, setMemberTasks] = useState([]);
  const [openIndexes, setOpenIndexes] = useState([]);
  const [filteredMemberTasks, setFilteredMemberTasks] = useState([]);
  const [filterOptions, setFilterOptions] = useState({ retailer: null, status: 'allStatus' });

  const [anchorEl, setAnchorEl] = useState(null);

  const theme = useTheme();
  const { user } = useAuth();
  const { data, isLoading, error } = useFetch({
    url: `${API_ROOT}/DacqTask/GetDacqMemberTasks?taskOwner=${user.mail}`,
    method: 'GET',
  });

  useEffect(() => {
    const fetchMemberTasks = async () => {
      if (!data) return;
      const updatedTasks = await Promise.all(
        data.map(async (task) => {
          const updatedSubTasks = await Promise.all(
            task.subTasks.map(async (subTask) => {
              const jobRunStatusResponse = await getJobRunById(subTask.jobRunId);
              const jobRunStatusData = await jobRunStatusResponse.json();
              return {
                ...subTask,
                jobRunStatus: jobRunStatusData.status,
                jobRunStartTime: jobRunStatusData.startTime,
              };
            })
          );

          return {
            ...task,
            subTasks: updatedSubTasks,
          };
        })
      );
      setMemberTasks(updatedTasks);
      setFilteredMemberTasks(updatedTasks);
    };

    fetchMemberTasks();
  }, [data]);

  if (!filteredMemberTasks.length && filterOptions.retailer === null && filterOptions.status === 'allStatus') {
    return <NoDataFound />;
  }
  
  if (error) {
    return <ErrorComponent />;
  }

  const mainTableHeader = ['retailer', 'name', 'status'];
  const subTableHeader = ['jobrun id', 'name', 'job run status', 'subtask status'];

  const filterTasks = (tasks, filters) => {
    return tasks.filter((task) => {
      const matchesRetailer = filters.retailer === null || task.task.retailerId === filters.retailer;
      const matchesStatus =
        filters.status === 'allStatus' || getDacqTaskStatus(task.subTasks) === (filters.status === 'completed' ? 1 : 2);
  
      return matchesRetailer && matchesStatus;
    });
  };  

  const handleRetailerChange = (event) => {
    const selectedRetailer = event.target.value; 
    setFilterOptions((prev) => ({ ...prev, retailer: selectedRetailer }));
    setFilteredMemberTasks(filterTasks(memberTasks, { ...filterOptions, retailer: selectedRetailer }));
  };

  const handleStatusChange = (event) => {
    const selectedStatus = event.target.value;
    setFilterOptions((prev) => ({ ...prev, status: selectedStatus }));
    setFilteredMemberTasks(filterTasks(memberTasks, { ...filterOptions, status: selectedStatus }));
  };
  const toggleAccordion = (index) => {
    if (openIndexes.includes(index)) {
      setOpenIndexes(openIndexes.filter((i) => i !== index));
    } else {
      setOpenIndexes([...openIndexes, index]);
    }
  };

  return (
    <Box display={'flex'} flexDirection={'column'} gap={4} padding={'3rem 2rem'}>
      <Box display={'flex'} justifyContent={'flex-end'} gap={3} width={'100%'}>
        <Button
          variant="contained"
          aria-owns={anchorEl ? 'simple-menu' : undefined}
          aria-haspopup="true"
          sx={{ ...btnStyle.CreateButton }}
          startIcon={<FilterAltIcon />}
          onClick={(event) => setAnchorEl(event.currentTarget)}
        >
          Filters
        </Button>

        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          MenuListProps={{
            sx: {
              ...styles.MenuList,
              padding: '1rem',
            },
          }}
        >
          <Box display={'flex'} flexDirection={'column'} gap={2}>
            <Typography variant="subtitle1" fontWeight={600}>
              Filter Options
            </Typography>

            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Select Retailer</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={filterOptions.retailer}
                label="Select Retailer"
                onChange={(retailer) => {
                  handleRetailerChange(retailer);
                }}
              >
                   <MenuItem value={null}>All Retailers</MenuItem>
                {retailers.map((retailer) => (
                  <MenuItem key={retailer.retailerType} value={retailer.retailerType}>
                    {retailer.retailerName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">DacqTask Status</FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={filterOptions.status}
                onChange={(status) => {
                  handleStatusChange(status);
                }}
              >
                <FormControlLabel value="allStatus" control={<Radio />} label=" All Status" />
                <FormControlLabel value="completed" control={<Radio />} label="Completed" />
                <FormControlLabel value="blocked" control={<Radio />} label="Blocked" />
              </RadioGroup>
            </FormControl>
          </Box>
        </Menu>
      </Box>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              {mainTableHeader.map((header, index) => (
                <TableCell align={index === 2 ? 'right' : 'left'} key={header}>
                  <Typography variant="subtitle2" gutterBottom fontWeight={'bold'}>
                    {header.toUpperCase()}
                  </Typography>
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableSkeleton ColumnCount={mainTableHeader.length} />
            ) : filteredMemberTasks.length === 0 ? (
              <TableRow>
                <TableCell colSpan={mainTableHeader.length} align="center">
                  <NoDataFound />
                </TableCell>
              </TableRow>
            ) : (
              filteredMemberTasks.map((taskWithSubTasks, index) => (
                <React.Fragment key={taskWithSubTasks.task.id}>
                  <TableRow
                    sx={{ '& > *': { borderBottom: 'unset' } }}
                    onClick={() => toggleAccordion(index)}
                    style={{ cursor: 'pointer' }}
                  >
                    <RetailerCell retailerId={taskWithSubTasks.task.retailerId} />
                    <TableCell component="th" scope="row">
                      {taskWithSubTasks.task.name}
                    </TableCell>
                    <TableCell align="right">
                      <Chip
                        label={getDacqTaskStatusName(getDacqTaskStatus(taskWithSubTasks.subTasks))}
                        sx={{
                          backgroundColor: alpha(
                            getDacqTaskStatusColor(getDacqTaskStatus(taskWithSubTasks.subTasks), theme),
                            0.1
                          ),
                          color: getDacqTaskStatusColor(getDacqTaskStatus(taskWithSubTasks.subTasks), theme),
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <Box display={'flex'} justifyContent={'flex-end'}>
                        <IconButton aria-label="expand row" size="small">
                          {openIndexes.includes(index) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      colSpan={6}
                      sx={{ backgroundColor: 'rgba(0, 0, 0, 0.02)' }}
                    >
                      <Collapse in={openIndexes.includes(index)} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                          <Table size="small" aria-label="purchases">
                            <TableHead>
                              <TableRow>
                                {subTableHeader.map((header, index) => (
                                  <TableCell align={index === 0 ? 'left' : 'left'} key={header}>
                                    <Typography variant="subtitle2" gutterBottom fontWeight={'bold'}>
                                      {header.toUpperCase()}
                                    </Typography>
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {taskWithSubTasks.subTasks.map((subTask) => (
                                <TableRow key={subTask.id}>
                                  <TableCell component="th" scope="row">
                                    <Link
                                      to={`/V2/dataruns/${subTask?.jobRunId}/${encodeURIComponent(subTask?.jobRunStartTime)}`}
                                    >
                                      <Typography sx={styles.ClickableSpan}>{subTask?.jobRunId}</Typography>
                                    </Link>
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    {subTask.name}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    <InfoChip label={subTask?.jobRunStatus} jobStatus={subTask?.jobRunStatus} />
                                  </TableCell>
                                  <TableCell>
                                    <Chip
                                      label={getDacqTaskStatusName(subTask.currentStatus)}
                                      sx={{
                                        backgroundColor: alpha(getDacqTaskStatusColor(subTask.currentStatus, theme), 0.1),
                                        color: getDacqTaskStatusColor(subTask.currentStatus, theme),
                                      }}
                                    />
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

function RetailerCell({ retailerId }) {
  const retailer = retailers.find((retailer) => retailer.retailerType === retailerId);

  return (
    <TableCell component="th" scope="row">
      <Box display="flex" alignItems="center" gap={2}>
        <RetailerIcon retailerType={retailerId} />
        <Typography variant="subtitle2" fontWeight="semibold">
          {retailer?.retailerName}
        </Typography>
      </Box>
    </TableCell>
  );
}
