export const generalInformation = (runningJob) => {
  return [
    { label: 'Id', value: runningJob?.id },
    { label: 'Name', value: runningJob?.name },
    { label: 'Credential Id', value: runningJob?.credentialId },
    { label: 'Username', value: runningJob?.credential.username },
    { label: 'Job Template Id', value: runningJob?.jobTemplateId },
    { label: 'ClassName', value: runningJob?.jobTemplate.className },
  ];
};

export const timeInformation = (runningJob) => {
  return [
    { label: 'CronExpression', value: runningJob?.cronExpression },
    { label: 'Should Run Now', value: runningJob?.shouldRunAt },
    { label: 'Is Enabled', value: runningJob?.isEnabled },
    { label: 'Start Time', value: runningJob?.startTime },
    { label: 'End Time', value: runningJob?.endTime },
  ];
};

export const additionalInformation = (runningJob) => {
  return [
    { label: 'Is Running', value: runningJob?.isRunning },
    { label: 'Priority Level', value: runningJob?.priorityLevel },
    { label: 'Job Group Id', value: runningJob?.jobGroupId },
  ];
};

export const parameterValuesCreation = (runningJob) => {
  const mergedParameterValues = {
    ...runningJob?.credential.parameterValues,
    ...runningJob?.jobTemplate.parameterDetail,
    ...runningJob?.parameterValues,
  };

  const result = [];

  if (Object.keys(mergedParameterValues).length !== 0) {
    Object.keys(mergedParameterValues).forEach((name) => {
      result.push({ label: name, value: mergedParameterValues[name] });
    });
  }

  return result;
};