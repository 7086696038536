import { Button, Divider, Grid, Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import RunningJobsActionButtons from '../../Components/TableActionButtons/RunningJobsActionButtons';
import { runningJobsColumns } from '../../Components/TableColumns/runningJobsColumns';
import { BasicTable } from '../../Components/Table/Table';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { styles } from './runningJobs.styles';
import { getRunningJobs } from '../../../Utilities/ForemanApiClient';
import { downloadArrayDataCSV } from '../../Utils/DataArraytoCSV';
import RunningJobInfoModal from './runningJobInfoModal';
import ActiveAndFutureDataRunsModal from './activeAndFutureDataRunsModal';
import CircularProgress from '@mui/material/CircularProgress';

export default function RunningJobs(props) {
  const [filteredRunningJobs, setFilteredJRunningJobs] = useState(null);
  const [searchValues, setSearchValues] = useState({});
  const [activeRunningJob, setActiveRunningJob] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [sortDirection, setSortDirection] = useState('ascending');
  const [limit, setLimit] = useState(50);
  const [sort, setSort] = useState('');
  const [filters, setFilters] = useState('');
  const [timer, setTimer] = useState(null);
  const [exportLoader, setExportLoader] = useState(false);
  const [isDataRunsListOpen, setIsDataRunsListOpen] = useState(false);
  const [isInfoDialogOpen, setIsInfoDialogOpen] = useState(false);

  const handleShowDataRuns = (runningJob) => {
    setActiveRunningJob(runningJob);
    setIsDataRunsListOpen(true);
  };

  const handleHideDataRuns = () => {
    setIsDataRunsListOpen(false);
  };

  const handleShowRunningJobInfo = (runningJob) => {
    setActiveRunningJob(runningJob);
    setIsInfoDialogOpen(true);
  };

  const handleHideRunningJobInfo = () => {
    setIsInfoDialogOpen(false);
  };

  const actionButtons = (runningJob) => {
    return (
      <RunningJobsActionButtons
        runningJob={runningJob}
        handleShowDataRuns={handleShowDataRuns}
        handleShowRunningJobInfo={handleShowRunningJobInfo}
      />
    );
  };

  const getAllRunningJobs = async (limit, offset, filters, sort, sortDirection, excludeFilter) => {
    setFilters(filters);
    setSort(sort);
    setLimit(limit);
    setSortDirection(sortDirection);
    setIsLoading(true);

    const response = await getRunningJobs(
      limit,
      offset,
      filters,
      sort ? sort : 'ElapsedTime',
      sort ? sortDirection : 'Descending',
      excludeFilter
    );
    let runningJobsList = response?.records;
    let totalFilteredRecords = response?.totalFilteredRecords;

    setCount(totalFilteredRecords);
    setFilteredJRunningJobs(runningJobsList);
    setIsLoading(false);
  };

  useEffect(async () => {
    const filters = searchValues
      ? Object.entries(searchValues)
          .map(([key, value]) => {
            if (value?.length > 0) return `&${key}=${value}`;
          })
          .join('')
      : '';
    clearTimeout(timer);

    const newTimer = setTimeout(async () => {
      setIsLoading(true);
      getAllRunningJobs(rowsPerPage, rowsPerPage * page, filters, sort, sortDirection, false);
    }, 500);

    setTimer(newTimer);
  }, [searchValues, rowsPerPage, page]);
  
  const getRunningJobsForDownload = async () => {
    setExportLoader(true);
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      timeZone: 'UTC',
    };
    const DownloadDateTime = new Date().toLocaleString('en-US', options);

    await getRunningJobs(limit, 0, filters, sort, sortDirection, false).then((response) => {
      downloadArrayDataCSV(true, response.records, `RunningJobs_${DownloadDateTime}$.csv`);
    });
    setExportLoader(false);
  };

  return (
    <>
      <Grid container rowSpacing={0}>
        <Grid item xs={6} align="left"></Grid>
        <Grid item xs={6} align="right">
          <Button
            variant="contained"
            sx={styles.CreateButton}
            startIcon={!exportLoader && <CloudDownloadIcon sx={styles.DownloadIcon} />}
            onClick={getRunningJobsForDownload}
            disabled={exportLoader}
          >
            {exportLoader && (
              <div style={{ width: 'inherit' }}>
                <Box>
                  <CircularProgress size={20} />
                </Box>
              </div>
            )}
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ mt: 1, mb: 1 }} />
      </Grid>
      <Grid item xs={12}>
        {isDataRunsListOpen ? (
          <ActiveAndFutureDataRunsModal runningJob={activeRunningJob} handleClose={handleHideDataRuns} />
        ) : (
          <BasicTable
            data={filteredRunningJobs}
            columns={runningJobsColumns}
            actionButtons={actionButtons}
            setSearchValues={setSearchValues}
            searchValues={searchValues}
            isLoading={isLoading}
            isFullHeight={true}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            page={page}
            setPage={setPage}
            count={count}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ mt: 1, mb: 1 }} />
      </Grid>
      {isInfoDialogOpen && (
        <RunningJobInfoModal runningJob={activeRunningJob} open={isInfoDialogOpen} handleClose={handleHideRunningJobInfo} />
      )}
    </>
  );
}
