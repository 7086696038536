import React from 'react';
import { Tooltip } from '@mui/material';
import { styles } from '../../Pages/upload-files/UploadFilesStyles';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import DownloadingOutlinedIcon from '@mui/icons-material/DownloadingOutlined';
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import DangerousOutlinedIcon from '@mui/icons-material/DangerousOutlined';

export default function UploadFilesActionButtons({ handleRunJob, job, handleStopActiveAndFutureDataRuns }) {
  const jobStatusAndDescription = (job) => {
    if (!job.jobRunId || !job.status || !job.description) {
      return 'No status available for this job yet.';
    }

    return `${job.status}: ${job.description}\nPlease see the records associated with Job Run ${job.jobRunId} for more information.`;
  };

  return (
    <>
      {job.isRunning ? (
        <>
          <Tooltip title="Running" placement="top">
            <DownloadingOutlinedIcon sx={{ m: 0.5 }} />
          </Tooltip>
        </>
      ) : (
        <Tooltip title="Run" placement="top">
          <PlayCircleFilledWhiteOutlinedIcon
            sx={styles.ActionButtons}
            onClick={() => {
              handleRunJob(job);
            }}
          />
        </Tooltip>
      )}

      <Tooltip title={`Job ${job.id} : ${job.jobTemplate.description}`} placement="top">
        <TipsAndUpdatesOutlinedIcon sx={{ m: 0.5 }} />
      </Tooltip>

      <Tooltip title={jobStatusAndDescription(job)} placement="top">
        <ErrorOutlineOutlinedIcon sx={{ m: 0.5 }} />
      </Tooltip>
      {job?.isRunning && (
        <Tooltip title="Stop active and future data runs" placement="top">
          <DangerousOutlinedIcon
            sx={{ m: 0.5, color: '#d32f2f' }}
            onClick={() => {
              handleStopActiveAndFutureDataRuns(job);
            }}
          />
        </Tooltip>
      )}
    </>
  );
}
