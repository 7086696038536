import React from 'react';
import { Tooltip } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { styles } from '../../Pages/credentials/Credentials.styles';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import PauseCircleOutlineOutlinedIcon from '@mui/icons-material/PauseCircleOutlineOutlined';
import PowerOutlinedIcon from '@mui/icons-material/PowerOutlined';
import PublishedWithChangesOutlinedIcon from '@mui/icons-material/PublishedWithChangesOutlined';
import PowerOffOutlinedIcon from '@mui/icons-material/PowerOffOutlined';

export default function CredentialJobsActionButtons({
  job,
  runNowIsLoading,
  shouldRunAtOnClick,
  editJobOnClick,
  deleteJobOnClick,
  jobActivationToggle,
}) {
  return (
    <>
      {runNowIsLoading != job.id ? (
        <Tooltip title={job.shouldRunAt <= new Date().toISOString() ? 'Run on cron' : `Run Job`} placement="left">
          {job?.shouldRunAt <= new Date().toISOString() ? (
            <PauseCircleOutlineOutlinedIcon sx={styles.ActionButtons} onClick={() => shouldRunAtOnClick(job)} />
          ) : (
            <PlayArrowOutlinedIcon sx={styles.ActionButtons} onClick={() => shouldRunAtOnClick(job)} />
          )}
        </Tooltip>
      ) : (
        <>
          <PublishedWithChangesOutlinedIcon />
        </>
      )}
      {job.jobGroupId == null && (
        <>
          <Tooltip title="Edit Job" placement="left">
            <EditOutlinedIcon sx={styles.ActionButtons} onClick={() => editJobOnClick(job)} />
          </Tooltip>
          <Tooltip title="Delete Job" placement="left">
            <DeleteOutlineOutlinedIcon sx={styles.ActionButtons} onClick={() => deleteJobOnClick(job)} />
          </Tooltip>
        </>
      )}
      {job.isEnabled ? (
        <Tooltip title="Pause Job" placement="left">
          <PowerOutlinedIcon sx={styles.ActionButtons} onClick={() => jobActivationToggle(job)} />
        </Tooltip>
      ) : (
        <Tooltip title="Activate Job" placement="left">
          <PowerOffOutlinedIcon sx={styles.ActionButtons} onClick={() => jobActivationToggle(job)} />
        </Tooltip>
      )}
    </>
  );
}
