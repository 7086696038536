import React from 'react';
import { Grid, Typography, FormControl, TextField } from '@mui/material';

export const KrogerRetailer = ({ selectedRetailerServiceType, modifiedCredential, setModifiedCredential }) => {
  return (
    <>
      {selectedRetailerServiceType.value === 17 && (
        <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
          <Grid item sm={2}>
            <Typography>Advertiser Id</Typography>
          </Grid>
          <Grid item sm={10}>
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                type="text"
                autoComplete="off"
                value={
                  (modifiedCredential && modifiedCredential.parameterValues && modifiedCredential.parameterValues.AdvertiserId) ||
                  ''
                }
                onChange={(event) => {
                  setModifiedCredential({
                    ...modifiedCredential,
                    parameterValues: {
                      ...modifiedCredential.parameterValues,
                      AdvertiserId: event.target.value,
                    },
                  });
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      )}
      {selectedRetailerServiceType.value === 14 && (
        <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
          <Grid item sm={2}>
            <Typography>Agency Code</Typography>
          </Grid>
          <Grid item sm={10}>
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                type="text"
                autoComplete="off"
                value={
                  (modifiedCredential && modifiedCredential.parameterValues && modifiedCredential.parameterValues.AgencyCode) ||
                  ''
                }
                onChange={(event) => {
                  setModifiedCredential({
                    ...modifiedCredential,
                    parameterValues: {
                      ...modifiedCredential.parameterValues,
                      AgencyCode: event.target.value,
                    },
                  });
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      )}
      {selectedRetailerServiceType.value === 10 && (
        <>
          <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
            <Grid item sm={2}>
              <Typography>Commodities</Typography>
            </Grid>
            <Grid item sm={10}>
              <FormControl fullWidth>
                <TextField
                  variant="outlined"
                  type="text"
                  autoComplete="off"
                  value={
                    (modifiedCredential &&
                      modifiedCredential.parameterValues &&
                      modifiedCredential.parameterValues.Commodities) ||
                    ''
                  }
                  onChange={(event) => {
                    setModifiedCredential({
                      ...modifiedCredential,
                      parameterValues: {
                        ...modifiedCredential.parameterValues,
                        Commodities: event.target.value,
                      },
                    });
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
            <Grid item sm={2}>
              <Typography>Stratum Client Key</Typography>
            </Grid>
            <Grid item sm={10}>
              <FormControl fullWidth>
                <TextField
                  variant="outlined"
                  type="text"
                  autoComplete="off"
                  value={
                    (modifiedCredential && modifiedCredential.parameterValues && modifiedCredential.parameterValues.ClientKey) ||
                    ''
                  }
                  onChange={(event) => {
                    setModifiedCredential({
                      ...modifiedCredential,
                      parameterValues: {
                        ...modifiedCredential.parameterValues,
                        ClientKey: event.target.value,
                      },
                    });
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};
