import React, { useState } from 'react';
import { Tooltip } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { styles } from '../../Pages/credentials/Credentials.styles';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import PowerOutlinedIcon from '@mui/icons-material/PowerOutlined';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import ForwardToInboxOutlinedIcon from '@mui/icons-material/ForwardToInboxOutlined';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import VerifiedIcon from '@mui/icons-material/Verified';
import CancelIcon from '@mui/icons-material/Cancel';
import { getLoginStatus } from '../../../Utilities/ForemanApiClient';
import { showSnackbar } from '../../Utils/showSnackBar';

function CredentialsActionButtons({
  credential,
  handleListCredentialJobs,
  handleDeleteCredential,
  handleEditCredential,
  handleCredentialActivationOnClick,
  handleSendResetTokenEmail,
  handleCopyResetLink,
  setCredentialsByChildClientId,
}) {
  const [ValidationInProgress, setValidationInProgress] = useState(false);
  const [isValid, setIsValid] = useState(null);

  const validateBotCredential = async (credential) => {
    setValidationInProgress(true);

    const response = await getLoginStatus(credential.id);
    var isValidCredential = response.success && response.payload?.redirectTo != '/change-password';
    setIsValid(isValidCredential);

    if (!isValidCredential) {
      showSnackbar(
        'error',
        ` Error message : ${
          response.message ? response.message : response.payload.redirectTo
        }, The credential will be set to not fresh `
      );
    } else {
      showSnackbar('success', 'This bot credential is valid!');
    }

    setValidationInProgress(false);
  };

  return (
    <>
      {credential.isActive ? (
        <Tooltip title="Deactivate Credential" placement="left">
          <PowerOutlinedIcon
            sx={{ ...styles.ActionButtons, color: '#388e3c' }}
            onClick={() => handleCredentialActivationOnClick(credential)}
          />
        </Tooltip>
      ) : (
        <Tooltip title="Activate Credential" placement="left">
          <PowerOutlinedIcon
            sx={{ ...styles.ActionButtons, color: '#b71c1c' }}
            onClick={() => handleCredentialActivationOnClick(credential)}
          />
        </Tooltip>
      )}

      <Tooltip title="List Credential Jobs" placement="left">
        <FormatListBulletedOutlinedIcon sx={styles.ActionButtons} onClick={() => handleListCredentialJobs(credential)} />
      </Tooltip>
      <Tooltip title="Edit Credential" placement="left">
        <EditOutlinedIcon sx={styles.ActionButtons} onClick={() => handleEditCredential(credential)} />
      </Tooltip>
      <Tooltip title="Delete Credential" placement="left">
        <DeleteOutlineOutlinedIcon sx={styles.ActionButtons} onClick={() => handleDeleteCredential(credential)} />
      </Tooltip>

      {credential.retailerServiceType === 7 && (
        <>
          <Tooltip title="Copy Refresh Link" placement="left">
            <FileCopyOutlinedIcon sx={styles.ActionButtons} onClick={() => handleCopyResetLink(credential)} />
          </Tooltip>
          <Tooltip title="Send Reset Email" placement="left">
            <ForwardToInboxOutlinedIcon sx={styles.ActionButtons} onClick={() => handleSendResetTokenEmail(credential)} />
          </Tooltip>
          {!ValidationInProgress ? (
            isValid === null ? (
              <Tooltip title="Validate credential" placement="left">
                <VerifiedOutlinedIcon
                  sx={styles.ActionButtons}
                  onClick={() => {
                    validateBotCredential(credential);
                  }}
                />
              </Tooltip>
            ) : (
              <>
                {isValid ? (
                  <VerifiedIcon sx={{ ...styles.ActionButtons, color: '#7fbcd2' }} />
                ) : (
                  <CancelIcon sx={{ ...styles.ActionButtons, color: '#cd525b' }} />
                )}
              </>
            )
          ) : (
            <CircularProgress size={15} sx={{ mt: 0.5, ml: 0.5 }} />
          )}
        </>
      )}
    </>
  );
}

export default CredentialsActionButtons;
