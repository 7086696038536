export const styles = {
  info: {
    color: 'infoIcons.info',
    marginLeft: '5px',
  },
  alert: {
    color: 'infoIcons.alert',
    marginLeft: '5px',
  },
  critical: {
    color: 'infoIcons.critical',
    marginLeft: '5px',
  },
  Inputs: {
    m: 1,
    width: '99%',
    '& legend': { display: 'none' },
  },
};
