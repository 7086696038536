export const allRetailerServiceTypes = [
  { value: 1, label: 'Amazon Advertising API' },
  { value: 2, label: 'Amazon Seller Central' },
  { value: 3, label: 'Amazon Selling Partner API' },
  { value: 4, label: 'Amazon Vendor Central' },
  { value: 5, label: 'Target Partners Online' },
  { value: 7, label: 'Walmart US Retail Link' },
  { value: 8, label: 'Walmart Canada' },
  { value: 9, label: 'Walmart China' },
  { value: 10, label: 'Kroger' },
  { value: 11, label: 'Costco Wholesale' },
  { value: 12, label: "Sam's Club" },
  { value: 13, label: 'Walmart US Advertising API' },
  { value: 14, label: 'Kroger IRC Portal' },
  { value: 15, label: 'The Trade Desk Target' },
  { value: 16, label: 'The Trade Desk Walmart' },
  { value: 17, label: 'The Trade Desk Kroger' },
  { value: 18, label: 'Skai' },
  { value: 19, label: 'Amazon Marketing Cloud' },
  { value: 20, label: "Sam's Club US Advertising API" },
  { value: 21, label: 'Walmart US Retail Link MFA' },
  { value: 23, label: 'Walmart Luminate Charter Portal' },
  { value: 28, label: 'Walmart Luminate Basic' },
  { value: 24, label: 'Criteo API' },
  { value: 25, label: 'Walmart Luminate Charter API' },
  { value: 27, label: '8451 Stratum' },
  { value: 29, label: 'Instacart API' },
  { value: 30, label: "Sam's Club Retail Link" },
  { value: 31, label: 'Walmart Marketplace' },
];
