import React, { useState } from 'react';
import {
  Grid,
  Typography,
  FormControl,
  TextField,
  Switch,
  DialogActions,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from '@mui/material';

export const WalmartUSLuminateAPI = ({
  selectedRetailerServiceType,
  modifiedCredential,
  setModifiedCredential,
  FormHelperText,
  mode,
  isLoading,
  setDisableButtons,
}) => {
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleConfirmDataExport = () => {
    setModifiedCredential({
      ...modifiedCredential,
      shouldExportSharedData: true,
    });
    setShowConfirmation(false);
  };

  const handleDataExport = (value) => {
    if (value == true) {
      setShowConfirmation(true);
    } else {
      setModifiedCredential({
        ...modifiedCredential,
        shouldExportSharedData: false,
      });
    }
  };

  return (
    <>
      {selectedRetailerServiceType.value == 25 && (
        <>
          <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
            <Grid item sm={2}>
              <Typography>Consumer Id</Typography>
            </Grid>
            <Grid item sm={10}>
              <FormControl fullWidth>
                <TextField
                  variant="outlined"
                  type="text"
                  autoComplete="off"
                  value={
                    (modifiedCredential && modifiedCredential.parameterValues && modifiedCredential.parameterValues.ConsumerId) ||
                    ''
                  }
                  onChange={(event) => {
                    setModifiedCredential({
                      ...modifiedCredential,
                      parameterValues: {
                        ...modifiedCredential.parameterValues,
                        ConsumerId: event.target.value,
                      },
                    });
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
            <Grid item sm={2}>
              <Typography>Private Key</Typography>
            </Grid>
            <Grid item sm={10}>
              <FormControl fullWidth>
                <TextField
                  variant="outlined"
                  type="text"
                  autoComplete="off"
                  value={
                    (modifiedCredential && modifiedCredential.parameterValues && modifiedCredential.parameterValues.PrivateKey) ||
                    ''
                  }
                  onChange={(event) => {
                    setModifiedCredential({
                      ...modifiedCredential,
                      parameterValues: {
                        ...modifiedCredential.parameterValues,
                        PrivateKey: event.target.value,
                      },
                    });
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container alignItems="center" sx={{ marginTop: '1rem' }}>
            <Grid item sm={2}>
              <Typography>Passcode</Typography>
            </Grid>
            <Grid item sm={10}>
              <FormControl fullWidth>
                <TextField
                  variant="outlined"
                  type="text"
                  autoComplete="off"
                  value={
                    (modifiedCredential && modifiedCredential.parameterValues && modifiedCredential.parameterValues.Passcode) ||
                    ''
                  }
                  onChange={(event) => {
                    setModifiedCredential({
                      ...modifiedCredential,
                      parameterValues: {
                        ...modifiedCredential.parameterValues,
                        Passcode: event.target.value,
                      },
                    });
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item sm={2}>
              <Typography>Export Shared Data</Typography>
            </Grid>
            <Grid item sm={10}>
              <FormControl fullWidth>
                <Switch
                  checked={modifiedCredential.shouldExportSharedData}
                  onChange={(event) => {
                    handleDataExport(event.target.checked);
                  }}
                  color="primary"
                />
              </FormControl>
            </Grid>
          </Grid>
          <Dialog
            open={showConfirmation}
            onClose={() => setShowConfirmation(false)}
            aria-labelledby="alert-delete-title"
            aria-describedby="alert-delete-description"
          >
            <DialogTitle id="alert-delete">{'Do you want to export shared data ?'}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Enabling this toggle will initiate the process of cloning data over to a new storage account. This new storage
                account will be accessible for the client <strong>{modifiedCredential?.childClient?.name}</strong>.
                <p>Please ensure that enabling this feature is intended.</p>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setShowConfirmation(false);
                }}
              >
                Cancel
              </Button>

              <Button
                onClick={() => {
                  handleConfirmDataExport();
                }}
                autoFocus
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
};
