/* eslint-disable eqeqeq */
import React from 'react';
import {
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
  CardBody,
  CardImg,
  CardImgOverlay,
  Card,
  CardTitle,
  CardText,
  CardHeader,
  CardFooter,
} from 'reactstrap';
import HarvestLogo from '../resources/harvest_logomark_white.svg';
import './AmazonBrandAnalyticsLandingPage.css';

export default function AmazonBrandAnalyticsLandingPage(props) {
  return (
    <>
      <Card>
        <CardImg
          alt="A sprawling field, glowing in the morning sun"
          src="https://picsum.photos/id/107/1300/165"
          style={{
            height: '165px',
          }}
          width="100%"
        />
        <CardImgOverlay>
          <img style={{ margin: '10px', height: '100px', width: '100px', float: 'right' }} alt="Logo" src={HarvestLogo} />
        </CardImgOverlay>
      </Card>
      <Card className="contentCard">
        <CardTitle tag="h1" sm={10} align="center">
          How does Harvest Group serve Amazon Sellers?
        </CardTitle>
        <CardBody>
          <CardText tag="h2">What we do</CardText>
          <CardText tag="h3">Reporting</CardText>
          <CardText tag="h4">
            We use in depth reporting to measure our success for our clients. We then take these numbers and discover insights to
            influence our strategy for the future.
          </CardText>
          <ListGroup className="listGroup">
            <ListGroupItem>
              <ListGroupItemHeading>Sales</ListGroupItemHeading>
              <ListGroupItemText className="listGroupItemText">
                We pull and analyze sales daily to identify positive and negative trends, catch logistical issues, and feed
                strategic decision making on how to grow the business.
              </ListGroupItemText>
            </ListGroupItem>
            <ListGroupItem>
              <ListGroupItemHeading>Page & Site</ListGroupItemHeading>
              <ListGroupItemText className="listGroupItemText">
                We track users that hit your page so that we can analyze trends and changes in page traffic, wait time,
                conversion, and other site behaviors.
              </ListGroupItemText>
            </ListGroupItem>
            <ListGroupItem>
              <ListGroupItemHeading>Content Health</ListGroupItemHeading>
              <ListGroupItemText className="listGroupItemText">
                We scrape and analyze your items’ content as it appears on site to determine both the correctness and general
                health of your content as it relates to discoverability
              </ListGroupItemText>
            </ListGroupItem>
            <ListGroupItem>
              <ListGroupItemHeading>Buy Box & Price</ListGroupItemHeading>
              <ListGroupItemText className="listGroupItemText">
                We track item pricing and who’s winning the buy box so that unauthorized sellers’ unhealthy pricing can be caught
                and dealt with quickly and efficiently.
              </ListGroupItemText>
            </ListGroupItem>
            <ListGroupItem>
              <ListGroupItemHeading>Search Rank</ListGroupItemHeading>
              <ListGroupItemText className="listGroupItemText">
                We track your items’ search result rankings on your most relevant search terms to gauge the discoverability of
                your item.
              </ListGroupItemText>
            </ListGroupItem>
          </ListGroup>
          <CardText tag="h3">Strategy & Account Management</CardText>
          <CardText tag="h4">
            We’re not just a software system cranking out content, we’re a team of strategic thinkers using technology and data to
            create smart solutions for every area of your online business.
          </CardText>
          <ListGroup className="listGroup">
            <ListGroupItem>
              <ListGroupItemHeading>Marketplace Intelligence</ListGroupItemHeading>
              <ListGroupItemText className="listGroupItemText">
                We harvest the vast amount of competitive and strategic data available on site to identify category trends,
                competitors’ tactics, and retailer initiatives to inform a strategy that wins.
              </ListGroupItemText>
            </ListGroupItem>
            <ListGroupItem>
              <ListGroupItemHeading>Profitability Analysis</ListGroupItemHeading>
              <ListGroupItemText className="listGroupItemText">
                As pressure mounts for profitability, we identify possible issues and opportunities within your assortment to help
                drive the bottom line.
              </ListGroupItemText>
            </ListGroupItem>
            <ListGroupItem>
              <ListGroupItemHeading>Assortment Optimization</ListGroupItemHeading>
              <ListGroupItemText className="listGroupItemText">
                We analyze your assortment to identify the top performing items that will help drive profitable topline growth and
                fit your retailers' goals.
              </ListGroupItemText>
            </ListGroupItem>
            <ListGroupItem>
              <ListGroupItemHeading>Marketing & Sales Strategy</ListGroupItemHeading>
              <ListGroupItemText className="listGroupItemText">
                We break down the traditional barriers between sales and marketing teams to deliver a retailer-specific marketing
                plan that spans the gap between retailer goals and traditional marketing objectives.
              </ListGroupItemText>
            </ListGroupItem>
            <ListGroupItem>
              <ListGroupItemHeading>Buyer Negotiation</ListGroupItemHeading>
              <ListGroupItemText className="listGroupItemText">
                We interface directly with your buyer(s) to understand their goals and objectives, and work with them to plan and
                execute a strategy that benefits both parties.
              </ListGroupItemText>
            </ListGroupItem>
          </ListGroup>
          <CardText tag="h3">Content Creation</CardText>
          <CardText tag="h4">
            We built a world class content creation software that works in tandem with our team of content experts to create
            consumer specific, SEO optimized, rich content for your items.
          </CardText>
          <ListGroup className="listGroup">
            <ListGroupItem>
              <ListGroupItemHeading>SEO</ListGroupItemHeading>
              <ListGroupItemText className="listGroupItemText">
                We begin the content creation process by using Google AdWords to generate a curated list of relevant keywords for
                each item based on brand, category, competitor and item level.
              </ListGroupItemText>
            </ListGroupItem>
            <ListGroupItem>
              <ListGroupItemHeading>Copywriting</ListGroupItemHeading>
              <ListGroupItemText className="listGroupItemText">
                After generating keywords for each item individually, we write content that accounts for searchability, shopper
                engagement, and retailer content guidelines, all while staying true to each client’s brand voice.
              </ListGroupItemText>
            </ListGroupItem>
            <ListGroupItem>
              <ListGroupItemHeading>Rich Media</ListGroupItemHeading>
              <ListGroupItemText className="listGroupItemText">
                Using our proprietary software, we’re able to mix and match layouts for Marketing Modules, upload videos, PDFs,
                Comparison Charts and other below-the-fold rich media experiences for our clients’ item pages.
              </ListGroupItemText>
            </ListGroupItem>
            <ListGroupItem>
              <ListGroupItemHeading>Content Syndication</ListGroupItemHeading>
              <ListGroupItemText className="listGroupItemText">
                Once we have created your optimized content, our tools and people can syndicate with speed.
              </ListGroupItemText>
            </ListGroupItem>
          </ListGroup>
          <CardText tag="h3">Marketing</CardText>
          <CardText tag="h4">
            We are your guide to Walmart Media Group from strategy and planning, to deciding how to spend your dollars, to making
            sure those dollars were spent well.
          </CardText>
          <ListGroup className="listGroup">
            <ListGroupItem>
              <ListGroupItemHeading>Strategy</ListGroupItemHeading>
              <ListGroupItemText className="listGroupItemText">
                We consider the full landscape of available activations and identify the best options to achieve your goals
                (growth, ROI, awareness, etc.).
              </ListGroupItemText>
            </ListGroupItem>
            <ListGroupItem>
              <ListGroupItemHeading>Media Spend & Execution</ListGroupItemHeading>
              <ListGroupItemText className="listGroupItemText">
                We take that plan and put it into action, programmatically and/or via a direct interface with the retail
                advertising team.
              </ListGroupItemText>
            </ListGroupItem>
            <ListGroupItem>
              <ListGroupItemHeading>Analysis & Learning</ListGroupItemHeading>
              <ListGroupItemText className="listGroupItemText">
                Using sales, traffic, and advertising report data, we measure the impact and the return of your campaigns, drawing
                insights to feed future strategies.
              </ListGroupItemText>
            </ListGroupItem>
          </ListGroup>
          <CardText tag="h3">Replenishment</CardText>
          <CardText tag="h4">
            A combination of our reporting, tracking, strategy and forecasting capabilities enables us to optimize your inventory
            to keep your items in stock and your customers happy.
          </CardText>
          <ListGroup className="listGroup">
            <ListGroupItem>
              <ListGroupItemHeading>Inventory Analysis</ListGroupItemHeading>
              <ListGroupItemText className="listGroupItemText">
                We monitor and analyze all available inventory data to anticipate out of stocks and diagnose supply chain issues.
              </ListGroupItemText>
            </ListGroupItem>
            <ListGroupItem>
              <ListGroupItemHeading>Order Recommendation</ListGroupItemHeading>
              <ListGroupItemText className="listGroupItemText">
                We create and deliver order recommendations to the retailer based on data-driven insights derived from our
                inventory analysis.
              </ListGroupItemText>
            </ListGroupItem>
          </ListGroup>
          <CardText tag="h2">What we don't do</CardText>
          <ListGroup className="listGroup">
            <ListGroupItem>
              <ListGroupItemHeading>Data Aggregation</ListGroupItemHeading>
              <ListGroupItemText className="listGroupItemText">
                We <strong>do not</strong> aggregate data obtained across clients or customers through the Amazon Services API to
                provide or sell to any parties, including competing users.
              </ListGroupItemText>
            </ListGroupItem>
            <ListGroupItem>
              <ListGroupItemHeading>Amazon Business Insights</ListGroupItemHeading>
              <ListGroupItemText className="listGroupItemText">
                We <strong>do not</strong> promote, publish, or share insights about Amazon's business, or use insights about
                Amazon's business for our own business purposes.
              </ListGroupItemText>
            </ListGroupItem>
          </ListGroup>
        </CardBody>
      </Card>
      <div style={{ background: 'black', color: 'white', height: '200px' }} align="Center">
        <div style={{ padding: '50px 0' }}>
          <a style={{ color: 'white', lineHeight: '100%' }} href="https://harvestgroup.com/digital/contact-us/">
            <h2>Contact Us</h2>
          </a>
        </div>
      </div>
    </>
  );
}
