import { Logout } from '@mui/icons-material';
import { Divider, IconButton, ListItemIcon, Menu, MenuItem, useTheme } from '@mui/material';
import React from 'react';
import { paperProps } from './UserMenu.styles';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import { ColorModeContext } from '../../Theme/theme';
import { useContext } from 'react';
import useAuth from '../../../useAuthContext';

function UserMenu(props) {
  const { user, logout } = useAuth();

  const { handleMenuClose, menuAnchorEl, isUserMenuOpen } = props;
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);

  const handleLogout = () => {
    logout();
  };

  return (
    <React.Fragment>
      <Menu
        anchorEl={menuAnchorEl}
        id="account-menu"
        open={isUserMenuOpen}
        onClose={handleMenuClose}
        PaperProps={paperProps}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={colorMode.toggleColorMode}>
          <IconButton>{theme.palette.mode === 'dark' ? <LightModeOutlinedIcon /> : <DarkModeOutlinedIcon />}</IconButton>
          {theme.palette.mode === 'dark' ? 'Enable Light Mode' : 'Enable Dark Mode'}
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => handleLogout()}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}

export default UserMenu;
