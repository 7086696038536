import { List } from '@mui/material';
import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Tooltip } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import routesV2 from '../../../routesV2';
import { styles } from './Mainlist.styles';
import { useIsDacqManager, useIsForemanDeveloper } from '../../../custom-hooks/userIsForemanDeveloper';

export default function MainList({ open }) {
  const location = useLocation();
  const isForemanDeveloper = useIsForemanDeveloper();
  const isDacqManager = useIsDacqManager();

  const filteredRoutes = routesV2.filter(route => {
    return route.isAccessibleOnSidebar && (isForemanDeveloper || !route.path.includes("dacq"));
  });

  const isActiveRoute = (route) => {
    return location.pathname.startsWith(`${route.layout}${route.path}`);
  };

  return (
    <>
      <List>
        {filteredRoutes.map((route, index) => (
          <Tooltip key={index} title={route.name} placement="right" arrow>
            <Link to={route.layout + route.path} style={styles.link}>
              <ListItemButton
                key={route.name}
                sx={styles.list}
                selected={isActiveRoute(route)} 
              >
                <ListItemIcon
                  sx={{
                    ...styles.icon,
                    mr: open ? 3 : 'auto',
                  }}
                >
                  {route.icon}
                </ListItemIcon>
                <ListItemText primary={route.name} sx={{ ...styles.listItemText, opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </Link>
          </Tooltip>
        ))}
      </List>
    </>
  );
}
