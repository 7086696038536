import { Skeleton, TableCell, TableRow } from '@mui/material';
import React from 'react';

export function stringToColor(string) {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  const luminance =
    0.299 * parseInt(color.substring(1, 3), 16) +
    0.587 * parseInt(color.substring(3, 5), 16) +
    0.114 * parseInt(color.substring(5, 7), 16);

  if (luminance > 200) {
    color = darkenColor(color);
  }

  return color;
}

function darkenColor(color) {
  let darkenedColor = '#';

  for (let i = 1; i < 7; i += 2) {
    const value = Math.max(0, parseInt(color.substr(i, 2), 16) - 30);
    darkenedColor += `00${value.toString(16)}`.slice(-2);
  }

  return darkenedColor;
}

export function stringAvatar(name) {
  return {
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}

export const ActionType = {
  VIEW: 'VIEW',
  EDIT: 'EDIT',
  ADD: 'ADD',
  ASSIGNMENTEDIT: 'ASSIGNMENTEDIT',
};

export const taskGroupColumns = [
  'Name',
  'Description',
  'Capacity',
  'Members',
  'Is active',
  'Actions'
];

export const TableSkeleton = ({ ColumnCount }) => {
  return [...Array(6)].map((row, index) => (
    <TableRow key={index}>
      {[...Array(ColumnCount)].map((col, colIndex) => (
        <TableCell key={colIndex}>
          <Skeleton animation="wave" variant="text" />
        </TableCell>
      ))}
    </TableRow>
  ));
};

export const routesToSkip = ['/dacq/taskGroups', '/dacq/taskGroups/details/:date', '/dacq/memberTasks'];
