export const styles = {
  wrapper: {
    width: '22ch',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    borderRight: '3px solid',
    fontFamily: 'monospace',
    fontSize: '2em',
  },
  ContainingBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '60vh',
  },
  typingDemo: {
    animation: 'typing 2s steps(22) infinite, blink .5s step-end infinite alternate',
    borderColor: 'transparent',
    animationDelay: '2s',
  },
  '@keyframes typing': {
    from: {
      width: '0',
    },
  },
  '@keyframes blink': {
    '50%': {
      borderColor: 'transparent',
    },
  },
};
