import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import { styles } from '../../Pages/job-groups/jobGroupStyles';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Switch from '@mui/material/Switch';
import { Grid, Typography } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ConfirmationModal({
  warningMessage,
  modalHeader,
  modalBody,
  tipBody,
  referenceText,
  setShowModal,
  confirmedAction,
  showModal,
  isDelete,
  enabled,
  isMultipleDelete,
  handleDeleteMultiple,
  isSwitch,
}) {
  const [isTextMatching, setIsTextMatching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const handleSwitchChange = () => {
    setDisabled((prevDisabled) => !prevDisabled);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <>
      {!isMultipleDelete ? (
        <div>
          <Dialog
            open={showModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
            size={'xl'}
          >
            <DialogTitle sx={styles.background}>
              {!isSwitch && (
                <>
                  {isDelete && <DeleteOutlineIcon />}
                  {modalHeader}
                </>
              )}
            </DialogTitle>
            <DialogContent sx={styles.background}>
                <div>{modalBody}</div>
                <br />

                <Grid container>
                  <Grid item xs={1} align="left">
                    <WarningAmberIcon sx={styles.WarningIcon} />
                  </Grid>
                  <Grid item xs={11} align="left">
                    <b>{warningMessage}</b>
                  </Grid>
                </Grid>
                <br />
                {tipBody}
                <br />
                <TextField
                  className="input-textMatch"
                  sx={{ width: '100%' }}
                  hidden={!referenceText}
                  onChange={(event) => {
                    referenceText == event.target.value ? setIsTextMatching(true) : setIsTextMatching(false);
                  }}
                  placeholder="Please enter item name to confirm."
                  variant="standard"
                />
            </DialogContent>
            <DialogActions sx={styles.background}>
              <Button onClick={handleClose} variant="outlined" color="error">
                cancel
              </Button>
              <Button
                disabled={enabled ? !enabled : isLoading || !isTextMatching}
                onClick={confirmedAction}
                variant="contained"
                sx={styles.CreateButton}
              >
                <DoneAllIcon />
                confirm
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      ) : (
        <div>
          <Dialog
            open={showModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
            maxWidth={'xl'}
          >
            <DialogTitle sx={styles.background}>
              {isDelete && <DeleteOutlineIcon />}
              {modalHeader}
            </DialogTitle>
            <DialogContent sx={styles.background}>
              <DialogContentText id="alert-dialog-slide-description">
                <Typography>{modalBody}</Typography>
                <br />
                <Grid container sx={{ alignItems: 'baseline' }}>
                  <Grid item xs={1} align="left">
                    <WarningAmberIcon sx={styles.WarningIcon} fontSize="xl" />
                  </Grid>
                  <Grid item xs={11} align="left">
                    <b>{warningMessage}</b>
                  </Grid>
                </Grid>
                <br />
                <Grid align="left">
                  <FormControlLabel
                    control={
                      <Switch id="agreementSwitch" aria-label="Agree" defaultChecked={false} onChange={handleSwitchChange} />
                    }
                    label="Agree to deleting all the items listed above"
                  />
                </Grid>
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={styles.background}>
              <Button onClick={handleClose} variant="outlined" color="error">
                cancel
              </Button>
              <Button onClick={handleDeleteMultiple} variant="contained" sx={styles.CreateButton} disabled={disabled}>
                <DoneAllIcon />
                confirm
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </>
  );
}
