import React from 'react';
import WalmartIcon from './WalmartIcon';
import TargetIcon from '../Icons/TargetIcon';
import SamsClubIcon from '../Icons/SamsClubIcon';
import AmazonIcon from '../Icons/AmazonIcon';
import KrogerIcon from '../Icons/KrogerIcon';
import SystemIcon from '../Icons/SystemIcon';
import CostcoIcon from './CostcoIcon';
import AlbertsonsIcon from './AlberstonsIcon';
import InstacartIcon from './InstacartIcon';
import AdsChannelsIcon from './AdsChannelsIcon';

const RetailerIcon = ({ retailerType, retailerName }) => {
  return (
    <>
      {((typeof retailerName === 'string' && retailerName.includes('Walmart')) ||
        retailerType == 1 ||
        retailerType == 6 ||
        retailerType == 7) && <WalmartIcon />}
        {((typeof retailerName === 'string' && retailerName.includes('Ads Channels')) ||
        retailerType == 9) && <AdsChannelsIcon />}
      {(retailerName == 'Target' || retailerType == 3) && <TargetIcon />}
      {(retailerName == "Sam's Club" || retailerType == 5) && <SamsClubIcon />}
      {(retailerName == 'Amazon' || retailerType == 4) && <AmazonIcon />}
      {(retailerName == 'Kroger' || retailerType == 2) && <KrogerIcon />}
      {(retailerName == 'Costco' || retailerType == 8) && <CostcoIcon />}
      {(retailerName == 'Not Set' || retailerType == 99) && <SystemIcon />}
      {(retailerName == 'Albertsons' || retailerType == 10) && <AlbertsonsIcon />}
      {(retailerName == 'Instacart' || retailerType == 12) && <InstacartIcon />}
    </>
  );
};

const retailerColorMapping = [
  {
    isMatch: (type, name) =>
      (typeof name === 'string' && name.includes('Walmart')) || [1, 6, 7].includes(type),
    colorCode: '#ffb819',
  },
  {
    isMatch: (type, name) =>
      (typeof name === 'string' && name.includes('Ads Channels')) || type === 9,
    colorCode: '#ff9900',
  },
  {
    isMatch: (type, name) =>
      name === 'Target' || type === 3,
    colorCode: '#cc0000',
  },
  {
    isMatch: (type, name) =>
      name === "Sam's Club" || type === 5,
    colorCode: '#0067a5',
  },
  {
    isMatch: (type, name) =>
      name === 'Amazon' || type === 4,
    colorCode: '#ff9900',
  },
  {
    isMatch: (type, name) =>
      name === 'Kroger' || type === 2,
    colorCode: '#004684',
  },
  {
    isMatch: (type, name) =>
      name === 'Costco' || type === 8,
    colorCode: '#004c91',
  },
  {
    isMatch: (type, name) =>
      name === 'Not Set' || type === 99,
    colorCode: '#cccccc',
  },
  {
    isMatch: (type, name) =>
      name === 'Albertsons' || type === 10,
    colorCode: '#0072ce',
  },
  {
    isMatch: (type, name) =>
      name === 'Instacart' || type === 12,
    colorCode: '#43b02a',
  },
];

const getRetailerColor = (retailerType, retailerName) => {
  const match = retailerColorMapping.find(({ isMatch }) => isMatch(retailerType, retailerName));
  return match ? match.colorCode : '#000000'; 
};

export { RetailerIcon, getRetailerColor };