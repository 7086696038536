import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { styles } from '../Credentials.styles';
import { createRefreshTokenURL, getRefreshTokenUrl } from '../../../../Utilities/ForemanApiClient';
import CircularProgress from '@mui/material/CircularProgress';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function RetailLinkBotTokenUrlDialog({ modalHeader, setShowModal, showModal, enabled, credential }) {
  const [tokenURL, setTokenURL] = useState(null);
  const [showFullURL, setShowFullURL] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    setShowModal(false);
  };

  const handleRedirectClick = (path) => {
    window.open(path, '_blank');
  };

  const handleCopyClick = async () => {
    await navigator.clipboard.writeText(tokenURL);
  };

  const fetchTokenURL = async () => {
    const response = await getRefreshTokenUrl(credential.id);
    if (response != 'CredentialUpdateToken not found.') setTokenURL(response);
  };

  const createTokenURL = async () => {
    setIsLoading(true);
    const response = await createRefreshTokenURL(credential.id);
    if (response) await fetchTokenURL();
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);

    fetchTokenURL();
    setIsLoading(false);
  }, [credential.id]);

  const toggleShowFullURL = () => {
    setShowFullURL(!showFullURL);
  };

  const renderURL = () => {
    if (tokenURL) {
      const displayURL = showFullURL ? tokenURL : tokenURL.substring(0, 80) + '...';
      const toggleLabel = showFullURL ? 'View Less' : 'View More';
      const icon = showFullURL ? <KeyboardArrowUpOutlinedIcon /> : <KeyboardArrowDownOutlinedIcon />;

      return (
        <div>
          <Box display="flex" justifyContent="flex-start" alignItems="center" flexWrap="wrap">
            <Typography style={styles.rowText}>{displayURL}</Typography>
          </Box>
          <Typography color="link" onClick={toggleShowFullURL} sx={styles.viewActionText}>
            {toggleLabel}
            {icon}
          </Typography>
        </div>
      );
    } else {
      return (
        <>
          <b>This Credential does not have a URL to refresh the token</b>
          <Button color="primary" style={{ marginLeft: '28%' }} onClick={() => createTokenURL()}>
            Generate a URL
          </Button>
        </>
      );
    }
  };

  return (
    <Dialog
      open={showModal}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      fullWidth
      maxWidth="md" // Set the maximum width of the dialog
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>{modalHeader}</Box>
          <Box>
            {' '}
            <Tooltip placement="auto" title="Copy URL" arrow enterDelay={500}>
              <IconButton id="copy" onClick={handleCopyClick}>
                <ContentCopyOutlinedIcon />
              </IconButton>
            </Tooltip>
            <Tooltip placement="auto" title="Follow Link" arrow enterDelay={500}>
              <IconButton id="link" onClick={() => handleRedirectClick(tokenURL)}>
                <OpenInNewOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </DialogTitle>

      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          <Grid container>
            {!isLoading ? (
              <>
                <br />
                {renderURL()}
                <br />
              </>
            ) : (
              <div>
                <CircularProgress />
              </div>
            )}
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined" color="error">
          cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default RetailLinkBotTokenUrlDialog;
