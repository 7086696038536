import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { API_ROOT } from '../../../../api-config';
import { Typography, Grid, Skeleton, Card } from '@mui/material';
import { grey } from '@mui/material/colors';
import { getDacqMembers } from '../../../../Utilities/ForemanApiClient';
import NoDataFound from '../../../Assets/NoDataFound';
import { useFetch } from '../../../hooks/useFetch';
import { CapacityChip } from '../capacityChip';
import CustomAvatarGroup from '../customAvatarGroup';
import { ErrorComponent } from '../errorComponent';

export default function TaskGroupAssignmentDetails() {
  const { date } = useParams();
  const [membersList, setMembersList] = useState([]);
  const [isMembersFetching, setIsMembersFetching] = useState(false);
  const { TaskGroupAssignments, isLoading, error } = useFetch({
    url: `${API_ROOT}/taskgroupassignment/GetByScheduledDate?scheduledDate=${date}`,
    method: 'GET',
  });

  useEffect(async () => {
    if (TaskGroupAssignments) {
      setIsMembersFetching(true);
      const groupmembersList = await getDacqMembers({
        membersIds: TaskGroupAssignments.map((group) => group.membersIds).flat(),
      });
      setIsMembersFetching(false);
      setMembersList(groupmembersList);
    }
  }, [TaskGroupAssignments]);

  if (error) {
    return <ErrorComponent />;
  }

  return (
    <div style={{ padding: '1rem' }}>
      <h3>{date} Details</h3>
      {isLoading || isMembersFetching ? (
        <Grid container spacing={2}>
          {[...Array(4)].map((_, index) => (
            <Grid item xs={6} key={index}>
              <Skeleton
                variant="rectangular"
                sx={{
                  borderRadius: '0.5rem',
                }}
                width="100%"
                height="300px"
                animation="wave"
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid container spacing={2}>
          {TaskGroupAssignments && TaskGroupAssignments.length > 0 ? (
            TaskGroupAssignments.map((taskGroup, index) => (
              <Grid key={index} item xs={6}>
                <Card style={{ padding: '1.5rem', gap: '5rem', width: '100%' }}>
                  <div>
                    <Typography gutterBottom variant="h5" component="div">
                      {taskGroup.taskGroupName}
                    </Typography>
                    <Typography color={grey[700]} gutterBottom variant="subtitle1" component="div">
                      Capacity: {taskGroup.capacity}
                    </Typography>
                    <CapacityChip value={taskGroup.membersIds.length} minimumValue={taskGroup.capacity} />
                  </div>
                  <div>
                    <Typography gutterBottom variant="h6" component="div">
                      Members
                    </Typography>
                    <CustomAvatarGroup
                      max={3}
                      list={membersList.filter(member => taskGroup.membersIds.includes(member.id)).map(member => member.displayName)}
                    />
                  </div>
                </Card>
              </Grid>
            ))
          ) : (
            <NoDataFound />
          )}
        </Grid>
      )}
    </div>
  );
}