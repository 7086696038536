export const styles = {
  ActionButtons: {
    m: 0.5,
    '&:hover': {
      backgroundColor: 'actionButtons.primary',
      borderRadius: 2,
      color: 'white',
      p: 0.5,
    },
  },
  LinkStyle: {
    cursor: 'pointer',
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      textDecoration: 'underline',
      color: 'inherit',
    },
  },
  ClickableSpan: {
    cursor: 'pointer',
    color: 'text.primary',
    textDecoration: 'none',
    fontSize: 'small',
    '&:hover': {
      cursor: 'pointer',
      color: 'lightgrey',
      textDecoration: 'underline',
    },
  },
  background: {
    bgcolor: 'notificationMenu.primary',
  },
  text: {
    color: 'text.primary',
  },
};
