import cronstrue from 'cronstrue';
import dayjs from 'dayjs';

export const containsProperty = (object, propertyName) => {
  return (
    object !== null &&
    object !== undefined &&
    object.hasOwnProperty(propertyName) &&
    object[propertyName] !== null &&
    object[propertyName] !== undefined
  );
};

export const isValidJson = (string) => {
  try {
    JSON.parse(string);
  } catch (e) {
    return false;
  }

  return true;
};


export const cronExpressionDate = (expression) => {

  return validateQuartzCron(expression) 
  ? `This Job will run ${cronstrue
      .toString(expression)
      .replace('At', 'at')
      .replace('Every', 'every')} (All times UTC)`
  : 'Invalid Cron Expression';
}

export   function getWeekdayFromDate(date) {
  const weekdaysNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const weekdayIndex = date.getDay();
  return weekdaysNames[weekdayIndex];
}

export function getCurrentWeekDates() {
  const today = new Date();
  const currentDayOfWeek = today.getDay();
  const mondayOffset = currentDayOfWeek === 0 ? -6 : 1 - currentDayOfWeek;
  const monday = new Date(today);
  monday.setDate(today.getDate() + mondayOffset);
  const weekDates = [];
  for (let i = 0; i < 5; i++) {
    const currentDate = new Date(monday);
    currentDate.setDate(monday.getDate() + i);
    const formattedDate = currentDate.toLocaleDateString('en-US', { month: 'numeric', day: 'numeric', year: 'numeric' });
    weekDates.push(formattedDate);
  }

  return weekDates;
}

export const isDateWithinCurrentWeek = (date) => {
  const currentDate = new Date();
  const firstDayOfWeek = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay()));
  const lastDayOfWeek = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay() + 6));
  return date >= firstDayOfWeek && date <= lastDayOfWeek;
};

export const validateQuartzCron = (expression) => {
  let isValid = false;
  try {
    cronstrue.toString(expression);
    isValid = true;
  } catch (error) {
    isValid = false;
  }
  return isValid;
};

export const mondayOfCurrentWeek = dayjs().startOf('week').add(1, 'day').format('YYYY-MM-DD');
