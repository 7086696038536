import React from 'react';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import moment from 'moment';
import { Grid } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const theme = createTheme({
  components: {
    MuiDateRange: {
      styleOverrides: {
        root: {
          backgroundColor: '#f0f0f0',
        },
      },
    },
  },
});

export default function DateRangeSelector({ setDateRange, dateRange, setStartDate, setEndDate }) {
  return (
    <Grid item xs={12}>
      <ThemeProvider theme={theme}>
        <DateRangePicker
          onChange={(item) => {
            const newStartDate = moment(item.selection.startDate).format('MM-DD-YYYY');
            const newEndDate = moment(item.selection.endDate).format('MM-DD-YYYY');

            setDateRange([item.selection]);
            setStartDate(newStartDate);
            setEndDate(newEndDate);
          }}
          showSelectionPreview
          moveRangeOnFirstSelection={false}
          months={1}
          ranges={dateRange}
          direction="horizontal"
        />
      </ThemeProvider>
    </Grid>
  );
}
