export const styles = {
  CreateButton: {
    bgcolor: 'button.primary',
    width: '100%',
  },
  Inputs: {
    m: 1,
    width: '99%',
    '& legend': { display: 'none' },
  },
  ActionButtons: {
    m: 0.5,
    '&:hover': {
      backgroundColor: 'actionButtons.primary',
      borderRadius: 2,
      color: 'white',
      p: 0.5,
    },
  },
  alert: {
    bgcolor: 'infoIcons.alert',
  },
  addButton: {
    ml: 1,
    bgcolor: '#388e3c',
    '&:hover': { bgcolor: '#388e3c' },
  },
  SelectCredential: {
    fontSize: '120px',
    color: 'gainsboro',
  },
  SelectText: {
    fontSize: '18px',
    color: 'gainsboro',
  },
  SelectContainer: {
    marginTop: '5%',
  },
  Inputs: {
    marginTop: '1rem',
    '& legend': { display: 'none' },
  },
  CloseButton: {
    m: 0.5,
    '&:hover': {
      borderRadius: 2,
      p: 0.5,
      cursor: 'pointer',
    },
  },
  WarningIcon: {
    color: '#D60B00',
    fontSize: 'xx-large',
    height: '-webkit-fill-available',
  },
  TipIcon: {
    color: '#8C3F2E',
    fontSize: 'large',
    mr:1
  },
  TipText: {
    fontSize: 14,
    fontWeight: 500
  },
  rowText: {
    overflow: 'hidden',
    whiteSpace: 'normal',
    wordBreak: 'break-all',
    textOverflow: 'ellipsis',
    fontSize: 16,
    '&:not(:hover):not(:focus)': {
      whiteSpace: 'nowrap',
      wordBreak: 'normal',
    },
  },
  viewActionText: { cursor: 'pointer', fontWeight: 'bold' },
};
