export function filterTableData(data, searchValues) {
  const areAllSearchValuesEmpty = Object.values(searchValues).every((value) => value === '');

  if (areAllSearchValuesEmpty) {
    return data;
  } else {
    const tableAsArray = data.filter((row) => {
      return Object.entries(searchValues).every(([key, searchValue]) => {
        const rowValue = key === 'CronExpression' ? row.parameterDetail?.CronExpression : row[key];

        if (typeof rowValue === 'number') {
          return JSON.stringify(rowValue)?.includes(searchValue);
        } else {
          return JSON.stringify(rowValue?.toLowerCase())?.includes(searchValue);
        }
      });
    });

    return tableAsArray;
  }
}

export const getTimespanAsString = (elapsedTime) => {
  const elapsedTimeValues = elapsedTime.split('.');
  const days = elapsedTimeValues[0].includes(':') ? 0 : elapsedTimeValues[0];
  const time = days === 0 ? elapsedTimeValues[0] : elapsedTimeValues[1];
  const formattedTime = days === 0 ? `${time}` : `${days} days ${time} `;

  return formattedTime;
};

export const getCalculatedElapsedTime = (elapsedTime) => {
  const timeComponents = elapsedTime.split(':');

  const milliseconds = parseInt(timeComponents[3]);
  const seconds = parseInt(timeComponents[2]);
  const minutes = parseInt(timeComponents[1]);
  const hours = parseInt(timeComponents[0]);

  const totalMilliseconds = milliseconds + seconds * 1000 + minutes * 60 * 1000 + hours * 60 * 60 * 1000;

  const days = Math.floor(totalMilliseconds / (24 * 60 * 60 * 1000));
  const remainingMilliseconds = totalMilliseconds % (24 * 60 * 60 * 1000);
  const remainingHours = Math.floor(remainingMilliseconds / (60 * 60 * 1000));
  const remainingMinutes = Math.floor((remainingMilliseconds % (60 * 60 * 1000)) / (60 * 1000));
  const remainingSeconds = Math.floor((remainingMilliseconds % (60 * 1000)) / 1000);

  let result = '';

  if (days > 0) {
    result += `${days} days `;
  }

  result += `${remainingHours.toString().padStart(2, '0')}:${remainingMinutes.toString().padStart(2, '0')}:${remainingSeconds
    .toString()
    .padStart(2, '0')}`;

  return result;
};
