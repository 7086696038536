import React from 'react';
import { Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { styles } from '../../Pages/job-groups/jobGroupStyles';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';

function JobGroupsActionButtons({ handleDelete, jobGroup, editJobGroupOnClick, createFromCopy, enterJobGroupTemplatesEditMode }) {
  return (
    <>
      <Tooltip title="Create from copy " placement="left">
        <ContentCopyIcon sx={styles.ActionButtons} onClick={() => createFromCopy(jobGroup)} />
      </Tooltip>
      <Tooltip title="Add/Remove Job Templates " placement="left">
        <FormatListBulletedOutlinedIcon sx={styles.ActionButtons} onClick={() => enterJobGroupTemplatesEditMode(jobGroup)} />
      </Tooltip>
      <Tooltip title="Edit Job group " placement="left">
        <EditOutlinedIcon sx={styles.ActionButtons} onClick={() => editJobGroupOnClick(jobGroup)} />
      </Tooltip>
      <Tooltip title="Delete Job group " placement="left">
        <DeleteOutlineOutlinedIcon sx={styles.ActionButtons} onClick={() => handleDelete(jobGroup)} />
      </Tooltip>
    </>
  );
}

export default JobGroupsActionButtons;
