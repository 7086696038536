import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ChecklistIcon from '@mui/icons-material/Checklist';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, Card, CardContent, Tooltip, Typography, alpha } from '@mui/material';
import React from 'react';
import { styles } from '../../main-page/page.styles';

const getStatIcon = (name) => {
  switch (name.toLowerCase()) {
    case 'total':
      return <ChecklistIcon />;
    case 'completed':
      return <CheckCircleOutlineIcon />;
    case 'blocked':
      return <ErrorOutlineIcon />;
    default:
      return <ChecklistIcon />;
  }
};

export default  function DacqTaskStatsItem({ name, value }) {
  const mainColor = styles.drawerHeaderSecondaryBackground.bgcolor;

  return (
    <Card
      sx={{
        borderRadius: '0.8rem',
        border: '1px solid rgba(0,0,0,.1)',
        boxShadow: '0 1px 3px 0 rgba(0,0,0,.1), 0 1px 2px -1px rgba(0,0,0,.1)',
        flex: 1,
      }}
    >
      <CardContent>
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <Box display={'flex'} gap={1}>
            <Typography variant="subtitle1">{name}</Typography>
          </Box>
          <div style={{ color: mainColor, backgroundColor: alpha(mainColor, 0.1), borderRadius: '50%', padding: '8px' }}>
            {getStatIcon(name)}
          </div>
        </Box>
        <Typography variant="h4" mt={6} fontWeight={600}>
          {value}
        </Typography>
      </CardContent>
    </Card>
  );
}