export const jobTemplatesColumns = [
  {
    Header: 'Retailer',
    accessor: 'retailerType',
    id: 'retailerType',
    width: '6vw',
  },
  {
    Header: 'Name',
    accessor: 'name',
    id: 'name',
    width: '22vw',
  },
  {
    Header: 'Description',
    accessor: 'description',
    id: 'description',
    width: '14vw',
  },
  {
    Header: 'Harvester',
    accessor: 'className',
    id: 'className',
    width: '11vw',
  },
  {
    Header: 'CronExpression',
    accessor: 'parameterDetail.CronExpression',
    id: 'CronExpression',
    width: '8vw',
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    id: 'actions',
    sortable: false,
    filterable: false,
    width: '7vw',
  },
];
