import { useTheme } from '@emotion/react';
import React from 'react';

const SystemIcon = (props) => {
  const theme = useTheme();
  const src = theme.palette.mode === 'dark' ? '/systemJob.svg' : '/systemJobLightMode.svg';
  return <img alt="system" src={src} {...props} sx={{ mr: 2 }} />;
};

export default SystemIcon;
