import React, { useEffect, useState } from 'react';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

function ActionButtons({ isChildClient, selectedChildClient, selectedParentClient, createAction, editAction, deleteAction }) {
  const [selectedClient, setSelectedClient] = useState(undefined);

  useEffect(() => {
    if (!isChildClient) {
      setSelectedClient(selectedParentClient);
    } else {
      setSelectedClient(selectedChildClient);
    }
  }, [selectedParentClient, selectedChildClient]);

  return (
    <>
      {(!isChildClient || selectedParentClient) && (
        <AddCircleOutlineOutlinedIcon
          sx={{
            mr: '5px',
            '&:hover': {
              color: '#388e3c',
              cursor: 'pointer',
            },
          }}
          onClick={() => {
            createAction();
          }}
        />
      )}
      {selectedClient && (
        <>
          <CreateOutlinedIcon
            sx={{
              mr: '5px',
              '&:hover': {
                color: '#fbc02d',
                cursor: ` ${
                  isChildClient && !(selectedClient && selectedParentClient?.value?.isActive) ? 'not-allowed' : 'pointer'
                } `,
              },
            }}
            onClick={() => {
              isChildClient
                ? selectedClient && selectedParentClient?.value?.isActive && editAction(selectedClient)
                : editAction(selectedClient);
            }}
          />
          <DeleteOutlineOutlinedIcon
            sx={{
              mr: '5px',
              '&:hover': {
                color: '#b71c1c',
                cursor: ` ${
                  !(selectedClient && selectedClient?.value?.isActive && selectedParentClient?.value?.isActive)
                    ? 'not-allowed'
                    : 'pointer'
                } `,
              },
            }}
            onClick={() => {
              selectedClient &&
                selectedClient?.value?.isActive &&
                selectedParentClient?.value?.isActive &&
                deleteAction(selectedClient);
            }}
          />
        </>
      )}
    </>
  );
}

export default ActionButtons;
