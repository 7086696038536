export const jobRunsColumns = [
  {
    Header: 'Job Run Id',
    accessor: 'jobRunId',
    id: 'id',
    width: '6vw',
  },
  {
    Header: 'Job Id',
    accessor: 'jobId',
    id: 'jobId',
    width: '6vw',
  },
  {
    Header: 'Status',
    accessor: 'status',
    id: 'status',
    sortable: false,
    filterable: false,
    width: '7vw',
  },
  {
    Header: 'Description',
    accessor: 'description',
    id: 'description',
    sortable: false,
    filterable: false,
    width: '14vw',
  },
  {
    Header: 'Start Time',
    accessor: 'startTime',
    id: 'startTime',
    width: '8vw',
  },
  {
    Header: 'Elapsed Time',
    accessor: 'jobrunselapsedtime',
    id: 'jobrunselapsedtime',
    width: '6vw',
  },
  {
    Header: 'End Time',
    accessor: 'endTime',
    id: 'endTime',
    width: '8vw',
  },
];
