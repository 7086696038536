import useAuth from '../useAuthContext';

 const useIsForemanDeveloper = () => {
  const { user } = useAuth();


  return user.groups.includes('Foreman Developers Security');
};

const DACQ_TITLES = ["Manager", "Director", "Team Lead"];

const useIsDacqManager = () => {
  const { user } = useAuth();
  return DACQ_TITLES.some(title => user.jobTitle.includes(title));
};
export { useIsForemanDeveloper, useIsDacqManager };
