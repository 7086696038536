import { Divider, Grid, Tooltip, Link, Breadcrumbs, Typography } from '@mui/material';
import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { BasicTable } from '../../Components/Table/Table';
import { logEntriesColumns } from '../../Components/TableColumns/logEntriesColumns';
import { styles } from './LogentriesStyles';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import LogEntriesDetails from './LogEntriesDetails';
import { getLogEntriesByDataRunId } from '../../../Utilities/ForemanApiClient';
import { filterTableData } from '../../Utils/filterTableData';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

function LogEntries() {
  const params = useParams();
  const id = params.id;
  const jobId = params.jobId;
  const jobName = params.jobName;
  const [searchValues, setSearchValues] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedLogEntry, setSelectedLogEntry] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [logEntries, setLogEntries] = useState([]);
  const [filteredLogEntries, setFilteredLogEntries] = useState([]);

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/V2/jobruns" sx={styles.LinkStyle}>
      Job Runs
    </Link>,
    <Typography
      key="1"
      onClick={() => {
        handleGetLastUrlClick();
      }}
      sx={styles.LinkStyle}
    >
      Data Runs
    </Typography>,
    <Typography key="3" color="text.primary">
      Log Entries
    </Typography>,
  ];

  const jobInsightsBreadCrumbs = [
    <Link href={`/V2/job-extraInfo/${jobId}/${encodeURIComponent(jobName)}`} color="inherit" sx={styles.LinkStyle}>
      <Typography>Job Insights</Typography>
    </Link>,
    <Typography key="3" color="text.primary">
      Log Entries
    </Typography>,
  ];

  const handleGetLastUrlClick = (event) => {
    window.history.go(-1);
  };

  const handleShowDetails = (logEntry) => {
    setSelectedLogEntry(logEntry);
    setShowDetails(true);
  };

  const handleClose = () => {
    setShowDetails(false);
    setSelectedLogEntry(null);
  };

  const actionButtons = useCallback((logEntry) => {
    return (
      <>
        <Tooltip title="show more details" placement="left">
          <MoreHorizIcon sx={styles.ActionButtons} onClick={() => handleShowDetails(logEntry)} />
        </Tooltip>
      </>
    );
  }, []);

  useEffect(async () => {
    setIsLoading(true);
    const response = await getLogEntriesByDataRunId(id);
    setLogEntries(response);
    setFilteredLogEntries(response);
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    if (logEntries && searchValues) {
      const tableAsArray = filterTableData(logEntries, searchValues);
      setFilteredLogEntries(tableAsArray);
    }
  }, [searchValues]);

  return (
    <>
      <Grid container rowSpacing={0}>
        <Grid item xs={6} align="left">
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
            {jobId ? jobInsightsBreadCrumbs : breadcrumbs}
          </Breadcrumbs>
        </Grid>
        <Grid item xs={6} align="right"></Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ mt: 1, mb: 1 }} />
      </Grid>
      <Typography variant="h7" sx={{ textAlign: 'center' }}>
        <BasicTable
          data={filteredLogEntries}
          columns={logEntriesColumns}
          actionButtons={actionButtons}
          setSearchValues={setSearchValues}
          searchValues={searchValues}
          isLoading={isLoading}
          hasSelectionCheckboxes={false}
          isFullHeight={true}
          noPagination={true}
        />
      </Typography>
      <LogEntriesDetails open={showDetails} handleClose={handleClose} logEntry={selectedLogEntry} />
    </>
  );
}

export default React.memo(LogEntries);
