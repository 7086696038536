import { PlayArrow } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import EastIcon from '@mui/icons-material/East';

import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
  alpha,
} from '@mui/material';

import { Timeline, timelineItemClasses } from '@mui/lab';

import HistoryIcon from '@mui/icons-material/History';
import { grey } from '@mui/material/colors';
import { useTheme } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { API_ROOT } from '../../../../api-config';
import useAuth from '../../../../useAuthContext';
import {
  claimDacqTask,
  enqueueBulkByDacqSubtasks,
  getDacqMembers,
  updateDacqSubTaskStatus,
} from '../../../../Utilities/ForemanApiClient';
import CustomAvatarGroup from '../../../Components/dacq/customAvatarGroup';
import { ErrorComponent } from '../../../Components/dacq/errorComponent';
import { useFetch } from '../../../hooks/useFetch';
import { showSnackbar } from '../../../Utils/showSnackBar';
import { stringAvatar, stringToColor } from '../taskGroupUtils';
import './claimButtonStyles.css';
import DacqTaskClaimButton from './DacqTaskClaimButton';
import { getDacqTaskStatusColor, getDacqTaskStatusName } from './dacqMemberTasksUtils';
import NoDacqTasksComponent from './NoDacqTasksComponent';
export const ClaimDacqTaskPage = () => {
  const theme = useTheme();
  const [claimedTask, setClaimedTask] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [isClaimEnabled, setIsClaimEnabled] = useState(true);
  const [hasStartedForemanProcess, setHasStartedForemanProcess] = useState(false);
  const [dacqMembersList, setDacqMembersList] = useState({});
  const [isDacqMembersListLoading, setIsDacqMembersListLoading] = useState(false);

  const inactiveTasks = tasks.filter((task) => task.subTasks.every((subTask) => subTask.isDone));

  const tableBody = inactiveTasks.map((row, index) => ({
    name: row.title,
    taskGroup: row.taskGroup,
    members: <CustomAvatarGroup alignment="start" max={3} list={row.members} />,
    startDate: row.startDate.toLocaleString(),
    endDate: row.endDate.toLocaleString(),
  }));

  const { user } = useAuth();
  const { data, isLoading, error } = useFetch({
    url: `${API_ROOT}/DacqTask/GetUncompletedMemberDacqTask?taskOwner=${user.mail}`,
    method: 'GET',
  });

  const {
    data: taskGroupData,
    isLoading: isTaskGroupLoading,
    error: taskGroupError,
  } = useFetch({
    url: `${API_ROOT}/TaskGroups/GetDacqMemberTaskGroups?email=${user.mail}`,
    method: 'GET',
  });
  
  useEffect(async () => {
    if (data) {
      setClaimedTask(data);
    }
  }, [data]);
  useEffect(() => {
    const fetchDacqMembers = async () => {
      if (!taskGroupData || taskGroupData.length === 0) {
        return;
      }

      setIsDacqMembersListLoading(true);

      const groupMembersResponse = await getDacqMembers({ memberEmails: [] });

      setDacqMembersList(groupMembersResponse);

      setIsDacqMembersListLoading(false);
    };

    fetchDacqMembers();
  }, [taskGroupData]);

  const claimNextTask = async () => {
    try {
      const response = await claimDacqTask(user.mail);

      if (response.status === 404) {
        showSnackbar('warning', 'No more tasks to claim for today');
        setClaimedTask(null);
        return;
      }

      const data = await response.json();
      setClaimedTask(data);
      setIsClaimEnabled(false);
      setHasStartedForemanProcess(false);
    } catch (error) {
      console.error(error);
    }
  };
  const updateSubTaskStatus = async (subtask) => {
    try {
      const updateResponse = await updateDacqSubTaskStatus(subtask.id, subtask.currentStatus);
      const updateData = await updateResponse.json();

      return updateData;
    } catch (error) {
      console.error(`An error occurred during update: ${error}`);
      throw error;
    }
  };

  const enqueueDacqTask = async (task) => {
    try {
      if (task) {
        await enqueueBulkByDacqSubtasks(task.dacqSubTasks.map((subTask) => subTask.id));
        showSnackbar('success', `Task ${task.dacqTask.name} has been successfully enqueued`);
        setIsClaimEnabled(true);
        setHasStartedForemanProcess(true);
      }
    } catch (error) {
      console.error(`An error occurred during enqueue: ${error}`);
      showSnackbar('error', `An error occurred during enqueue: ${error}`);
    }
  };

  if (taskGroupError) {
    return <ErrorComponent />;
  }

  if (isLoading || isTaskGroupLoading || isDacqMembersListLoading) {
    return (
      <Box display="flex" justifyContent="center" height="100vh" paddingTop={10}>
        <Skeleton
          variant="rectangular"
          width="80%"
          height={300}
          bgcolor={theme.palette.taskGroupColors.whiteBg}
          sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}
        >
          {Array.from({ length: 5 }).map((_, index) => (
            <Skeleton key={index} variant="rectangular" p={40} height={20} />
          ))}
        </Skeleton>
      </Box>
    );
  }
  return (
    <Box display={'flex'} flexDirection={'column'} pr={16} pl={16} pt={8} pb={8} gap={6}>
      <Link to="/V2/dacq/memberTasks/summary">
        <Button style={{ width: 'fit-content' }} variant="outlined" startIcon={<HistoryIcon />}>
          Claim summary
        </Button>
      </Link>
      <Box display={'flex'} flexDirection={'column'} gap={6}>
        <Box
          display={'flex'}
          gap={6}
          border={`1px solid ${alpha(theme.palette.taskGroupColors.black, 0.15)}`}
          bgcolor={theme.palette.taskGroupColors.whiteBg}
          borderRadius={5}
          p={3}
        >
          {claimedTask ? (
            <>
              <Box
                display={'flex'}
                gap={4}
                flexDirection={'column'}
                bgcolor={theme.palette.taskGroupColors.whiteBg}
                border={'1px solid transparent'}
                borderRadius={5}
                p={3}
                flex={2}
              >
                <Box display={'flex'} justifyContent={'space-between'}>
                  <Typography variant="h5" fontWeight={600}>
                    {claimedTask.dacqTask.name}
                  </Typography>
                  <DacqTaskClaimButton disabled={!isClaimEnabled} onClick={claimNextTask} buttonText={'Claim Next Task'} />
                </Box>
                <Box key={claimedTask.dacqTask.id}>
                  <Box display="flex" justifyContent="space-between" width="100%" mb={2}>
                    {claimedTask.dacqTask.contributors && claimedTask.dacqTask.contributors.length > 0 && (
                      <CustomAvatarGroup key={claimedTask.dacqTask.id} list={claimedTask.dacqTask.contributors} />
                    )}
                  </Box>
                  <Timeline
                    sx={{
                      [`& .${timelineItemClasses.root}:before`]: {
                        flex: 0,
                        padding: 0,
                      },
                    }}
                  >
                    {claimedTask.dacqSubTasks.map(function (subTask) {
                      return (
                        <div>
                          <Box display="flex" gap={1} alignItems="center" justifyContent="space-between" width="100%">
                            <Typography
                              sx={{
                                flex: 5,
                                textTransform: 'none',
                              }}
                              variant="subtitle1"
                            >
                              {subTask.name}
                            </Typography>

                            <Box flex={3} display="flex" flexDirection={'column'} gap={1}>
                              <Box display="flex" gap={1} justifyContent={'end'} alignItems="center">
                                <Typography color={getDacqTaskStatusColor(subTask.lastStatus, theme)} variant="subtitle1">
                                  {getDacqTaskStatusName(subTask.lastStatus)}
                                </Typography>

                                <EastIcon sx={{ color: getDacqTaskStatusColor(subTask.lastStatus, theme) }} />
                                <FormControl sx={{ m: 1, minWidth: 120 }}>
                                  <Select
                                    sx={{
                                      backgroundColor: alpha(getDacqTaskStatusColor(subTask.currentStatus, theme), 0.1),
                                      borderRadius: 99,
                                      fontWeight: 600,
                                      padding: '5px 15px',
                                      color: getDacqTaskStatusColor(subTask.currentStatus, theme),
                                      width: '9rem',
                                    }}
                                    disableUnderline={true}
                                    disabled={hasStartedForemanProcess}
                                    variant="standard"
                                    value={parseInt(subTask.currentStatus)}
                                    onChange={(e) =>
                                      handleSubTaskStatusChange(e, subTask, claimedTask, setClaimedTask, updateSubTaskStatus)
                                    }
                                    displayEmpty
                                  >
                                    <MenuItem value="0">Ready</MenuItem>
                                    <MenuItem value="1">Completed</MenuItem>
                                    <MenuItem value="2">Blocked</MenuItem>
                                  </Select>
                                </FormControl>
                              </Box>
                              {subTask.currentStatus === 'Blocked' && (
                                <Box display="flex" gap={1} justifyItems={'end'} justifyContent={'end'}>
                                  <TextField placeholder="Add a note..." id="standard-basic" variant="standard" />
                                  <IconButton
                                    sx={{
                                      backgroundColor: theme.palette.primary.main,
                                      borderRadius: '50%',
                                      color: 'white',
                                      fill: 'white',
                                    }}
                                    variant="contained"
                                  >
                                    <AddIcon />
                                  </IconButton>
                                </Box>
                              )}
                            </Box>
                          </Box>
                        </div>
                      );
                    })}
                  </Timeline>
                  <Box display="flex" p={2} width={'100%'} justifyContent="flex-end">
                    <Button
                      onClick={() => {
                        enqueueDacqTask(claimedTask);
                      }}
                      disabled={claimedTask.dacqSubTasks.some(
                        (subTask) => subTask.currentStatus === 0 || hasStartedForemanProcess
                      )}
                      startIcon={<PlayArrow />}
                      variant="contained"
                    >
                      Start Foreman process
                    </Button>
                  </Box>
                </Box>
              </Box>
              {taskGroupData.length > 0 &&
              <Box border={'1px solid transparent'} borderRadius={5} p={3} flex={1}>
                <Box display={'flex'} justifyContent={'space-between'}>
                  <Typography variant="h5" fontWeight={600} mb={2}>
                    Task Group
                  </Typography>
                </Box>
                <Box
                  border={'1px solid'}
                  bgcolor={theme.palette.background.paper}
                  borderColor={grey[400]}
                  borderRadius={5}
                  display={'flex'}
                  flexDirection={'column'}
                  alignItems={'center'}
                  p={3}
                  justifyContent={'space-between'}
                  mb={2}
                >
                  { taskGroupData.map((taskGroup) => (
                    <Box
                      key={taskGroup.name}
                      display={'flex'}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                      width={'100%'}
                      mb={2}
                    >
                      <Typography variant="h6" fontWeight={600} mb={2}>
                        {taskGroup.name}
                      </Typography>
                      <Box display="flex" gap={2}>
                        <AvatarGroup sx={{ '&.MuiAvatarGroup-root': { justifyContent: 'start' } }}>
                          {taskGroup.memberEmails.map((member) => {
                            const memberName = dacqMembersList.find((dacqMember) => dacqMember.email === member).displayName;

                            return (
                              <Tooltip key={member} title={memberName} placement="top">
                                <Avatar {...stringAvatar(memberName)} sx={{ bgcolor: stringToColor(memberName) }} />
                              </Tooltip>
                            );
                          })}
                        </AvatarGroup>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
              }
            </>
          ) : (
            <NoDacqTasksComponent onCLaimClick={claimNextTask} />
          )}
        </Box>
      </Box>
    </Box>
  );
};

function handleSubTaskStatusChange(e, subTask, claimedTask, setClaimedTask, updateSubTaskStatus) {
  const newStatus = parseInt(e.target.value);

  if (newStatus === subTask.currentStatus) {
    return;
  }

  const updatedClaimedTask = { ...claimedTask };
  updatedClaimedTask.dacqSubTasks.find((sub) => sub.id === subTask.id).currentStatus = newStatus;

  setClaimedTask(updatedClaimedTask);

  const updatedSubTask = {
    id: subTask.id,
    currentStatus: newStatus,
  };

  updateSubTaskStatus(updatedSubTask);
}