/* eslint-disable eqeqeq */
import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  getCredentialsByChildClientId,
  getChildClientsByParentId,
  getJobGroupsWithTemplates,
  deleteMultipleCredentials,
  deleteCredential,
  getRetailers,
  toggleCredentialActivation,
  getAmazonSPAPIMarketplaceOptions,
  deleteChildClient,
  deleteParentClient,
  updateJob,
  createJob,
  SendRefreshEmail,
  getSystemJobs,
  getJobsByCredential,
  getJobGroupById,
} from '../../../Utilities/ForemanApiClient';
import ClientDropdown from './ClientsDropdowns/Clientsdropdowns';
import { BasicTable } from '../../Components/Table/Table';
import { credentialsColumns } from '../../Components/TableColumns/CredentialsColumns';
import CredentialsActionButtons from '../../Components/TableActionButtons/CredentialsActionButtons';
import ActionButtons from './ActionButtons';
import { styles } from './Credentials.styles';
import TableToolbar from '../../Components/Table/TableToolbar';
import { showSnackbar } from '../../Utils/showSnackBar';
import CredentialsFormModal from './credentialsFormModal';
import { filterTableData } from '../../Utils/filterTableData';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import DeleteVerificationModal from '../../Components/DeleteComponent/DeleteModal';
import ChildClientForm from './ChildClientForm';
import ParentClientForm from './ParentClientForm';
import CredentialJobsManager from './CredentialJobsManager';
import JobForm from './JobForm';
import { useParams } from 'react-router-dom';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ConfirmationModal from '../../Components/DeleteComponent/ConfirmationModal';
import RetailLinkBotTokenUrlDialog from './Dialogs/RetailLinkBotTokenUrlDialog';

export default function Credentials() {
  const [amazonSPAPIMarketplaceOptions, setAmazonSPAPIMarketplaceOptions] = useState([]);
  const [credentials, setCredentials] = useState(undefined);
  const [filteredCredentials, setFilteredCredentials] = useState(undefined);
  const [activeParentClient, setActiveParentClient] = useState({});
  const [activeChildClient, setActiveChildClient] = useState({});
  const [activeCredential, setActiveCredential] = useState({});
  const [selectedParentClient, setSelectedParentClient] = useState(undefined);
  const [selectedChildClient, setSelectedChildClient] = useState(undefined);
  const [parentClients, setParentClients] = useState([]);
  const [childClients, setChildClients] = useState([]);
  const [retailers, setRetailers] = useState([]);
  const [searchValues, setSearchValues] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCredentials, setSelectedCredentials] = useState([]);
  const [showMultipleDeleteModal, setShowMultipleDeleteModal] = useState(false);
  const [isCredentialsModalOpen, setIsCredentialsModalOpen] = useState(false);
  const [isCreateMode, setIsCreateMode] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isJobListingMode, setIsJobListingMode] = useState(false);
  const [formMode, setFormMode] = useState('');
  const [showDeleteCredentialModal, setShowDeleteCredentialModal] = useState(false);
  const [showDeactivateCredentialModal, setShowDeactivateCredentialModal] = useState(false);
  const [childClientFormMode, setChildClientFormMode] = useState('');
  const [showChildClientFormModal, setShowChildClientFormModal] = useState(false);
  const [showDeleteChildClientModal, setShowDeleteChildClientModal] = useState(false);
  const [showParentClientFormModal, setShowParentClientFormModal] = useState(false);
  const [parentClientFormMode, setParentClientFormMode] = useState('');
  const [showDeleteParentClientModal, setShowDeleteParentClientModal] = useState(false);
  const [jobFormMode, setJobFormMode] = useState('create');
  const [showJobForm, setShowJobForm] = useState(false);
  const [activeJob, setActiveJob] = useState({});
  const [showSystemJobsModal, setShowSystemJobsModal] = useState(false);
  const [credentialJobsList, setCredentialJobsList] = useState([]);
  const [FilteredredentialJobsList, setFilteredCredentialJobsList] = useState([]);
  const [showSendResetTokenEmailConfirmationModal, setShowSendResetTokenEmailConfirmationModal] = useState(false);
  const [botCredentialsToReset, setBotCredentialsToReset] = useState({});
  const [showResetTokenUrlDialog, setShowResetTokenUrlDialog] = useState(false);

  const params = useParams();
  const parentClientIdParam = params.parentClientId;
  const childClientIdParam = params.childClientId;

  useEffect(() => {
    if (parentClientIdParam && parentClients?.length > 0) {
      const filteredClients = parentClients.filter((item) => parentClientIdParam == item.id);
      if (filteredClients.length > 0) {
        const parentClientObj = getObjectForDropdown(filteredClients[0]);

        setSelectedParentClient(parentClientObj);
        setActiveJob({});
      }
    }
  }, [parentClients, parentClientIdParam]);

  useEffect(() => {
    if (
      childClientIdParam &&
      childClients?.length > 0 &&
      parentClients?.length > 0 &&
      selectedParentClient &&
      !selectedChildClient
    ) {
      const filteredClients = childClients.filter((item) => childClientIdParam == item.id);
      if (filteredClients.length > 0) {
        const parentClientObj = getObjectForDropdown(filteredClients[0]);
        setSelectedChildClient(parentClientObj);
      }
    }
  }, [childClients, childClientIdParam]);

  const createChildClientOnClick = () => {
    const obj = {
      id: 0,
      parentClientId: selectedParentClient.value.id,
      isActive: true,
      name: '',
      pbcsChildClientId: '',
      retailerId: 0,
      securityGroupName: '',
      amazonRegion: 0,
    };
    setActiveChildClient(obj);
    setChildClientFormMode('create');
    setShowChildClientFormModal(true);
  };

  const editChildClientOnClick = (childClient) => {
    setActiveChildClient(childClient);
    setChildClientFormMode('edit');
    setShowChildClientFormModal(true);
  };

  const handleCloseChildClientModal = () => {
    setShowChildClientFormModal(false);
  };

  const deleteChildClientOnClick = (childClient) => {
    setActiveChildClient(childClient);
    setShowDeleteChildClientModal(true);
  };

  const deleteChildClientConfirmationModal = () => {
    return (
      <DeleteVerificationModal
        name={activeChildClient.value ? activeChildClient.value?.name : activeChildClient.name}
        referenceText={activeChildClient.value ? activeChildClient.value?.name : activeChildClient.name}
        modalType="Child Client"
        warningMessage="Deleting the Child Client will delete all Credentials and Jobs that reference it."
        setShowModal={setShowDeleteChildClientModal}
        showModal={showDeleteChildClientModal}
        confirmedDeleteAction={confirmedDeleteChildClientActionAction}
      />
    );
  };

  const confirmedDeleteChildClientActionAction = async () => {
    await deleteChildClient(activeChildClient.value ? activeChildClient.value.id : activeChildClient.id).then((response) => {
      if (!response) {
        showSnackbar(
          'error',
          `Child Client ${activeChildClient.id} delete unsuccessful. Please try again later; if the issue persists, contact dev: foremandev@harvestgroup.com`
        );
      } else {
        showSnackbar('success', 'deleted successfully!');
      }
    });

    setChildClients(childClients.filter((item) => activeChildClient.value.id !== item.id));
    setSelectedChildClient(undefined);
    setShowDeleteChildClientModal(false);
  };

  const createParentClientOnClick = () => {
    const obj = {
      id: 0,
      isActive: true,
      name: '',
      externalId: '',
      securityGroupName: '',
    };
    setActiveParentClient(obj);
    setParentClientFormMode('create');
    setShowParentClientFormModal(true);
  };

  const editParentClientOnClick = (parentClient) => {
    setActiveParentClient(parentClient);
    setParentClientFormMode('edit');
    setShowParentClientFormModal(true);
  };

  const handleCloseParentClientModal = () => {
    setShowParentClientFormModal(false);
  };

  const deleteParentClientOnClick = (parentClient) => {
    setActiveParentClient(parentClient);
    setShowDeleteParentClientModal(true);
  };

  const confirmedDeleteParentClientActionAction = async () => {
    await deleteParentClient(activeParentClient.value ? activeParentClient.value.id : activeParentClient.id).then((response) => {
      if (!response) {
        showSnackbar(
          'error',
          `Parent Client ${activeParentClient.id} delete unsuccessful. Please try again later; if the issue persists, contact dev: foremandev@harvestgroup.com`
        );
      } else {
        showSnackbar('success', 'deleted successfully!');
      }
    });

    setParentClients(parentClients.filter((item) => activeParentClient.value.id !== item.id));
    setSelectedParentClient(undefined);
    setActiveJob({});
    setShowDeleteParentClientModal(false);
  };

  const deleteParentClientConfirmationModal = () => {
    return (
      <DeleteVerificationModal
        name={activeParentClient.value ? activeParentClient.value?.name : activeParentClient.name}
        referenceText={activeParentClient.value ? activeParentClient.value?.name : activeParentClient.name}
        modalType="parent Client"
        warningMessage={`Deleting the Parent Client will delete all Child Clients, Credentials, and Jobs that reference it.`}
        setShowModal={setShowDeleteParentClientModal}
        showModal={showDeleteParentClientModal}
        confirmedDeleteAction={confirmedDeleteParentClientActionAction}
      />
    );
  };

  const handleSendResetTokenEmail = (credential) => {
    setBotCredentialsToReset(credential);
    setShowSendResetTokenEmailConfirmationModal(true);
  };

  const handleSendRefreshEmail = () => {
    SendRefreshEmail(botCredentialsToReset.id);
    setShowSendResetTokenEmailConfirmationModal(false);
  };

  const SendResetTokenEmailConfirmationModal = () => {
    return (
      <ConfirmationModal
        modalHeader={<>RL Bot Credential Token/Password Reset</>}
        warningMessage={
          <>{`Are you sure you want to send RL Bot Token/Password reset emails for "${botCredentialsToReset?.username}`}</>
        }
        setShowModal={setShowSendResetTokenEmailConfirmationModal}
        showModal={showSendResetTokenEmailConfirmationModal}
        confirmedAction={handleSendRefreshEmail}
        enabled={true}
      />
    );
  };

  const handleCopyResetLink = (credential) => {
    setBotCredentialsToReset(credential);
    setShowResetTokenUrlDialog(true);
  };

  const ResetTokenUrlDialog = () => {
    return (
      <RetailLinkBotTokenUrlDialog
        modalHeader={<>Refresh Bot Token/Password Link</>}
        setShowModal={setShowResetTokenUrlDialog}
        showModal={showResetTokenUrlDialog}
        enabled={true}
        credential={botCredentialsToReset}
      />
    );
  };

  const handleOpenModal = () => {
    setIsCredentialsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsCredentialsModalOpen(false);
  };

  const getObjectForDropdown = (client) => {
    return {
      value: client,
      label: `${client?.name} - ${client?.id}`,
    };
  };

  const handleCredentialActivationOnClick = async (credential) => {
    setShowDeactivateCredentialModal(true);
    setActiveCredential(credential);
  };

  const handleDeactivateCredential = async () => {
    await toggleCredentialActivation(activeCredential).then((response) => {
      if (!response) {
        showSnackbar(
          'error',
          `Toggling Credential ${activeCredential.id} "IsActive" failed . Please try again later; if the issue persists, contact Dev : foremandev@harvestgroup.com.`
        );
      } else {
        showSnackbar(
          'success',
          `${activeCredential.isActive ? 'Deactivated' : 'Activated'} Credential ${activeCredential.id} successfully!`
        );
      }
    });

    setFilteredCredentials((prevCredentials) =>
      prevCredentials.map((cred) => (cred.id === activeCredential.id ? { ...cred, isActive: !cred.isActive } : cred))
    );
    setCredentials((prevCredentials) =>
      prevCredentials.map((cred) => (cred.id === activeCredential.id ? { ...cred, isActive: !cred.isActive } : cred))
    );

    setShowDeactivateCredentialModal(false);
  };

  const deactivateCredentialConfirmationModal = () => {
    return (
      <DeleteVerificationModal
        name={activeCredential?.username}
        referenceText={activeCredential?.username}
        modalType="Credential"
        message={
          activeCredential.isActive
            ? `Are you sure you want to deactivate ${activeCredential.username}?`
            : `Are you sure you want to activate ${activeCredential.username}?`
        }
        warningMessage={
          activeCredential.isActive
            ? `This will deactivate and delete all Jobs for Credential ${activeCredential.id}. Do you wish to continue?`
            : `This will reactivate and create new Jobs for Credential ${activeCredential.id}. Do you wish to continue?`
        }
        setShowModal={setShowDeactivateCredentialModal}
        showModal={showDeactivateCredentialModal}
        confirmedDeleteAction={handleDeactivateCredential}
        isSwitch={true}
      />
    );
  };

  const handleDeleteCredential = (credential) => {
    setActiveCredential(credential);
    setShowDeleteCredentialModal(true);
  };

  const confirmedDeleteAction = async () => {
    await deleteCredential(activeCredential.id).then((response) => {
      if (!response) {
        showSnackbar(
          'error',
          `Credential delete unsuccessful. Please try again later; if the issue persists, contact Dev : foremandev@harvestgroup.com.`
        );
      } else {
        showSnackbar('success', 'deleted successfully!');
      }
    });
    setShowDeleteCredentialModal(false);
    setCredentials(credentials.filter((item) => !(activeCredential.id === item.id)));
    setFilteredCredentials(credentials.filter((item) => !(activeCredential.id === item.id)));
  };

  const deleteCredentialConfirmationModal = () => {
    return (
      <DeleteVerificationModal
        name={activeCredential?.id + ' - ' + activeCredential?.username}
        referenceText={activeCredential?.username}
        modalType="Credential"
        warningMessage="Deleting the Credential will delete the Jobs that reference it as well as effect Lantern and Snowflake Data Sets keyed on Credential Id. It's rare to delete a Credential and can involve multiple teams."
        setShowModal={setShowDeleteCredentialModal}
        showModal={showDeleteCredentialModal}
        confirmedDeleteAction={confirmedDeleteAction}
        tipBody={
          <Grid container>
            <InfoOutlinedIcon sx={styles.TipIcon} />
            <Grid item xs={11} sx={styles.TipText} align="left">
              <p>
                If you were provided a new username to replace the current one, please update the current Credential with the new
                username and relevant information (do not delete and create new).
              </p>
            </Grid>
            <InfoOutlinedIcon sx={styles.TipIcon} />
            <Grid item xs={11} sx={styles.TipText} align="left">
              <p>If you are retiring a Client, simply disable the Client to keep a record of historic Clients and Credentials.</p>
            </Grid>
            <InfoOutlinedIcon sx={styles.TipIcon} />
            <Grid item xs={11} sx={styles.TipText} align="left">
              <p>If you still need to delete the Credential, manually type the Credential username.</p>
            </Grid>
          </Grid>
        }
      />
    );
  };

  const confirmDeleteMultiple = async () => {
    await deleteMultipleCredentials(selectedCredentials).then((response) => {
      if (!response) {
        showSnackbar(
          'error',
          `Credentials delete unsuccessful. Check if you're authorized to delete these Credentials or please try again later;\n if the issue persists, contact Dev: foremandev@harvestgroup.com.`
        );
      } else {
        showSnackbar('success', 'deleted successfully!');
      }
    });
    setShowMultipleDeleteModal(false);
    setCredentials(credentials.filter((item) => !selectedCredentials.includes(item.id)));
    setFilteredCredentials(credentials.filter((item) => !selectedCredentials.includes(item.id)));
    setSelectedCredentials([]);
  };

  const setCredentialsByChildClientId = async (childClientId) => {
    setIsLoading(true);

    const credentials = await getCredentialsByChildClientId(childClientId);

    const credentialsWithJobName = await Promise.all(
      credentials.map(async (credential) => {
        let group = await getJobGroupById(credential.jobGroupId);

        return { ...credential, jobGroupName: group?.name };
      })
    );

    setCredentials(credentialsWithJobName);
    setFilteredCredentials(credentialsWithJobName);
    setIsLoading(false);
  };

  const setChildClientsOnSelectedParentId = async () => {
    const childClientsByParent = await getChildClientsByParentId(selectedParentClient.value.id);

    setChildClients(childClientsByParent);
  };

  useEffect(() => {
    if (selectedChildClient) {
      if (!selectedChildClient || selectedChildClient?.value?.parentClientId !== selectedParentClient?.value?.id) {
        const parentOfChild = parentClients.find((parentClient) => parentClient.id == selectedChildClient.value.parentClientId);
        const parentClientObj = getObjectForDropdown(parentOfChild);
        setSelectedParentClient(parentClientObj);
        setActiveJob({});
      }

      setCredentialsByChildClientId(selectedChildClient.value.id);
    }
    setIsJobListingMode(false);
  }, [selectedChildClient]);

  useEffect(() => {
    const hasSelectedChildClient = selectedChildClient && selectedChildClient.value;
    const hasSelectedParentClient = selectedParentClient && selectedParentClient.value;

    const isMatchingParentClient =
      hasSelectedChildClient &&
      hasSelectedParentClient &&
      selectedChildClient.value.parentClientId === selectedParentClient.value.id;

    if (!isMatchingParentClient) {
      if (hasSelectedParentClient && selectedParentClient.value.id !== 0) {
        setSelectedChildClient(undefined);
        setCredentials(undefined);
        setFilteredCredentials(undefined);
        setChildClientsOnSelectedParentId();
        setShowSystemJobsModal(false);
      } else if (selectedParentClient && selectedParentClient.value.id === 0) {
        setShowSystemJobsModal(true);
        setSelectedChildClient(undefined);
      } else {
        setSelectedChildClient(undefined);
        setShowSystemJobsModal(false);
      }
    }
  }, [selectedParentClient]);

  const handleListCredentialJobs = (credential) => {
    setActiveCredential(credential);
    setIsJobListingMode(true);
  };

  const handleCreateCredential = () => {
    const obj = {
      id: 0,
      isActive: true,
      childClientId: selectedChildClient.value.id,
      username: '',
      password: '',
      jobGroupId: null,
    };
    setActiveCredential(obj);
    setFormMode('create');
    setIsJobListingMode(false);
    setIsEditMode(false);
    setIsCreateMode(true);
    handleOpenModal();
  };

  const handleEditCredential = (credential) => {
    setActiveCredential(credential);
    setFormMode('edit');
    setIsJobListingMode(false);
    setIsCreateMode(false);
    setIsEditMode(true);
    handleOpenModal();
  };

  const submitJobForm = async (job) => {
    let success = false;

    if (jobFormMode == 'edit') {
      success = await updateJob(job);
      if (!success) {
        showSnackbar('error', 'Editing Job list failed. Please try again later; if the issue persists, contact dev.');
      } else {
        showSnackbar('success', 'edited successfully!');

        setCredentialJobsList((credentialJobsList) => {
          return credentialJobsList.map((item) => (item.id === job.id ? job : item));
        });
        setFilteredCredentialJobsList((filteredCredentialsJobList) => {
          return filteredCredentialsJobList.map((item) => (item.id === job.id ? job : item));
        });
      }
    } else {
      success = await createJob(job);
      if (!success) {
        showSnackbar('error', 'Creating Job failed. Please try again later; if the issue persists, contact dev.');
      } else {
        const newJobList = job.credentialId === undefined ? await getSystemJobs() : await getJobsByCredential(job.credentialId);
        setCredentialJobsList(newJobList);
        setFilteredCredentialJobsList(newJobList);
        showSnackbar('success', 'created successfully!');
      }
    }
    setActiveJob({});
    setShowJobForm(false);
  };

  const createJobOnClick = () => {
    let obj = { id: 0, jobTemplateId: null };
    if (activeCredential?.id && !showSystemJobsModal) {
      obj = { ...obj, credentialId: activeCredential.id };
    }
    setJobFormMode('create');
    setActiveJob(obj);
    setShowJobForm(true);
  };

  useEffect(() => {
    const tableAsArray = filterTableData(credentials, searchValues);
    setFilteredCredentials(tableAsArray);
  }, [searchValues]);

  useEffect(async () => {
    const allRetailers = await getRetailers();
    setRetailers(allRetailers);
    const options = await getAmazonSPAPIMarketplaceOptions();
    setAmazonSPAPIMarketplaceOptions(options);
  }, []);

  const actionButtons = (credential) => {
    return (
      <CredentialsActionButtons
        credential={credential}
        handleListCredentialJobs={handleListCredentialJobs}
        handleDeleteCredential={handleDeleteCredential}
        handleEditCredential={handleEditCredential}
        handleSendResetTokenEmail={handleSendResetTokenEmail}
        handleCopyResetLink={handleCopyResetLink}
        handleCredentialActivationOnClick={handleCredentialActivationOnClick}
        setCredentialsByChildClientId={setCredentialsByChildClientId}
      />
    );
  };

  return (
    <>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
        <Grid item xs={6} sx={{ mb: 5, mt: 2 }}>
          <ClientDropdown
            isChildClient={false}
            selectedParentClient={selectedParentClient}
            setSelectedParentClient={setSelectedParentClient}
            parentClients={parentClients}
            setParentClients={setParentClients}
            childClients={childClients}
            setChildClients={setChildClients}
            setActiveJob={setActiveJob}
          />
          <Box display="flex" justifyContent="flex-end" sx={{ m: 0.5 }}>
            <ActionButtons
              componentName="Parent Client"
              isChildClient={false}
              activeParentClient={activeParentClient}
              setActiveParentClient={setActiveParentClient}
              selectedParentClient={selectedParentClient}
              setSelectedParentClient={setSelectedParentClient}
              activeChildClient={activeChildClient}
              setActiveChildClient={setActiveChildClient}
              selectedChildClient={selectedChildClient}
              createAction={createParentClientOnClick}
              editAction={editParentClientOnClick}
              deleteAction={deleteParentClientOnClick}
            />
          </Box>
        </Grid>
        <Grid item xs={6} sx={{ mb: 5, mt: 2 }}>
          {selectedParentClient?.value.id != 0 && (
            <>
              <ClientDropdown
                isChildClient={true}
                selectedChildClient={selectedChildClient}
                setSelectedChildClient={setSelectedChildClient}
                parentClients={parentClients}
                setParentClients={setParentClients}
                childClients={childClients}
                setChildClients={setChildClients}
              />
              <Box display="flex" justifyContent="flex-end" sx={{ m: 0.5 }}>
                <ActionButtons
                  componentName="Child Client"
                  isChildClient={true}
                  activeParentClient={activeParentClient}
                  setActiveParentClient={setActiveParentClient}
                  selectedParentClient={selectedParentClient}
                  setSelectedParentClient={setSelectedParentClient}
                  activeChildClient={activeChildClient}
                  setActiveChildClient={setActiveChildClient}
                  selectedChildClient={selectedChildClient}
                  setSelectedChildClient={setSelectedChildClient}
                  retailers={retailers}
                  createAction={createChildClientOnClick}
                  editAction={editChildClientOnClick}
                  deleteAction={deleteChildClientOnClick}
                />
              </Box>
            </>
          )}
        </Grid>
        <Grid item xs={12}>
          {selectedChildClient ? (
            <Paper elevation={0}>
              {selectedCredentials.length > 0 ? (
                <TableToolbar
                  selected={selectedCredentials}
                  setSelected={setSelectedCredentials}
                  modalType={'Credentials'}
                  warningMessage="Deleting the Credentials will affect the Jobs that reference them."
                  handleDeleteMultiple={confirmDeleteMultiple}
                  filteredItems={credentials}
                  showModal={showMultipleDeleteModal}
                  setShowModal={setShowMultipleDeleteModal}
                />
              ) : (
                <Grid container rowSpacing={1} sx={{ p: 1 }}>
                  <Grid item xs={10}>
                    <Typography variant="h6" sx={{ bgcolor: 'background.paper' }}>
                      Credentials
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    {isJobListingMode ? (
                      <>
                        <Button
                          variant="contained"
                          size="md"
                          sx={styles.CreateButton}
                          startIcon={<AddOutlinedIcon />}
                          onClick={() => createJobOnClick()}
                        >
                          Create Job
                        </Button>
                      </>
                    ) : (
                      <Button
                        variant="contained"
                        size="md"
                        sx={styles.CreateButton}
                        startIcon={<AddOutlinedIcon />}
                        onClick={() => handleCreateCredential()}
                        disabled={selectedChildClient && !selectedChildClient.value.isActive}
                      >
                        Create Credential
                      </Button>
                    )}
                  </Grid>
                </Grid>
              )}
              {isJobListingMode ? (
                <CredentialJobsManager
                  credential={activeCredential}
                  setIsJobListingMode={setIsJobListingMode}
                  childClient={activeChildClient}
                  formMode={jobFormMode}
                  setFormMode={setJobFormMode}
                  showForm={showJobForm}
                  setShowForm={setShowJobForm}
                  activeJob={activeJob}
                  setActiveJob={setActiveJob}
                  submitJobForm={submitJobForm}
                  credentialJobsList={credentialJobsList}
                  setCredentialJobsList={setCredentialJobsList}
                  FilteredredentialJobsList={FilteredredentialJobsList}
                  setFilteredCredentialJobsList={setFilteredCredentialJobsList}
                />
              ) : (
                <BasicTable
                  data={filteredCredentials}
                  columns={credentialsColumns}
                  actionButtons={actionButtons}
                  setSearchValues={setSearchValues}
                  searchValues={searchValues}
                  isLoading={isLoading}
                  selected={selectedCredentials}
                  setSelected={setSelectedCredentials}
                  isFullHeight={false}
                  hasSelectionCheckboxes={true}
                  noPagination={true}
                />
              )}
            </Paper>
          ) : showSystemJobsModal ? (
            <CredentialJobsManager
              createJobOnClick={createJobOnClick}
              formMode={jobFormMode}
              setFormMode={setJobFormMode}
              showForm={showJobForm}
              setShowForm={setShowJobForm}
              activeJob={activeJob}
              setActiveJob={setActiveJob}
              submitJobForm={submitJobForm}
              showSystemJobsModal={showSystemJobsModal}
              credentialJobsList={credentialJobsList}
              setCredentialJobsList={setCredentialJobsList}
              FilteredredentialJobsList={FilteredredentialJobsList}
              setFilteredCredentialJobsList={setFilteredCredentialJobsList}
            />
          ) : (
            <>
              <Grid container spacing={1} sx={styles.SelectContainer}>
                <Grid item xs={12} align="center">
                  <AdsClickIcon sx={styles.SelectCredential} />
                </Grid>
                <Grid item xs={12} align="center">
                  <Typography sx={styles.SelectText}>Select a Credential</Typography>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      {showJobForm && (
        <JobForm
          job={activeJob}
          mode={jobFormMode}
          childClient={selectedChildClient}
          handleClose={() => {
            setShowJobForm(false);
            setActiveJob({});
          }}
          submitForm={submitJobForm}
          credential={activeCredential}
          open={showJobForm}
        />
      )}
      {showDeleteCredentialModal && deleteCredentialConfirmationModal()}
      {showDeactivateCredentialModal && deactivateCredentialConfirmationModal()}
      {showDeleteChildClientModal && deleteChildClientConfirmationModal()}
      {showDeleteParentClientModal && deleteParentClientConfirmationModal()}
      {showSendResetTokenEmailConfirmationModal && SendResetTokenEmailConfirmationModal()}
      {showResetTokenUrlDialog && ResetTokenUrlDialog()}
      {showChildClientFormModal && (
        <ChildClientForm
          open={showChildClientFormModal}
          handleClose={handleCloseChildClientModal}
          setShowChildClientFormModal={setShowChildClientFormModal}
          mode={childClientFormMode}
          childClient={activeChildClient.value ? activeChildClient.value : activeChildClient}
          parentClient={selectedParentClient.value ? selectedParentClient.value : selectedParentClient}
          retailers={retailers}
          setChildClients={setChildClients}
          childClients={childClients}
          setSelectedChildClient={setSelectedChildClient}
        />
      )}
      {showParentClientFormModal && (
        <ParentClientForm
          open={showParentClientFormModal}
          handleClose={handleCloseParentClientModal}
          setShowParentClientFormModal={setShowParentClientFormModal}
          mode={parentClientFormMode}
          retailers={retailers}
          parentClient={activeParentClient.value ? activeParentClient.value : activeParentClient}
          setParentClients={setParentClients}
          parentClients={parentClients}
          setSelectedParentClient={setSelectedParentClient}
        />
      )}
      {isCredentialsModalOpen && (
        <CredentialsFormModal
          open={isCredentialsModalOpen}
          handleCloseModal={handleCloseModal}
          handleOpenModal={handleOpenModal}
          isCreateMode={isCreateMode}
          isEditMode={isEditMode}
          credential={activeCredential}
          mode={formMode}
          isJobListingMode={isJobListingMode}
          retailers={retailers}
          childClient={selectedChildClient}
          setFilteredCredentials={setFilteredCredentials}
          setCredentials={setCredentials}
          credentials={credentials}
          selectedChildClient={selectedChildClient}
          setCredentialsByChildClientId={setCredentialsByChildClientId}
          setAmazonSPAPIMarketplaceOptions={setAmazonSPAPIMarketplaceOptions}
          amazonSPAPIMarketplaceOptions={amazonSPAPIMarketplaceOptions}
        />
      )}
    </>
  );
}
