import { Autocomplete, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import { getChildClients, getParentClients } from '../../../../Utilities/ForemanApiClient';
import { useNavigate, useLocation } from 'react-router-dom';

function ClientDropdown({
  isChildClient,
  setSelectedChildClient,
  setSelectedParentClient,
  selectedChildClient,
  selectedParentClient,
  parentClients,
  setParentClients,
  childClients,
  setChildClients,
  setParentClientErrorMessage,
  setActiveJob,
}) {
  const navigate = useNavigate();
  const location = useLocation();

  const handleDeleteDynamicPartOfUrl = () => {
    const { pathname } = location;
    //regular expression pattern to match the dynamic parameters in the url
    const dynamicPartPattern = /\/(\d+)\/(\d+)/;
    const matches = pathname.match(dynamicPartPattern);

    if (matches && matches.length === 3) {
      const [, dynamicParentIdParam, dynamicChildIdParam] = matches;

      const newUrl = pathname.replace(matches[0], '');

      navigate(newUrl, { replace: true });
    }
  };

  const isActive = (client) => {
    return client && client.isActive;
  };

  const getDropdownOptions = () => {
    return isChildClient
      ? childClients.map((childClient) => ({
          value: childClient,
          label: childClient.id === 0 ? childClient.name : `${childClient.name} - ${childClient.id}`,
        }))
      : parentClients.map((parentClient) => ({
          value: parentClient,
          label: parentClient.id === 0 ? parentClient.name : `${parentClient.name} - ${parentClient.id}`,
        }));
  };

  useEffect(() => {
    if (!isChildClient) {
      getAllParentClients();
    } else {
      getAllChildClients();
    }
  }, []);

  const getAllParentClients = async () => {
    const allParentClients = await getParentClients();

    const systemParent = {
      id: 0,
      name: 'SYSTEM',
      externalId: '',
      created: '',
      securityGroupName: '',
      isActive: true,
    };

    allParentClients.unshift(systemParent);
    setParentClients(allParentClients);
  };

  const getAllChildClients = async () => {
    const allChildClients = await getChildClients();
    setChildClients(allChildClients);
  };

  const InitializeClientList = async () => {
    if (!isChildClient) {
      const allParentClients = await getAllParentClients();
    } else {
      const allChildClients = await getChildClients();

      setChildClients(allChildClients);
    }
  };

  useEffect(() => {
    InitializeClientList();
  }, []);

  useEffect(async () => {
    if (!selectedParentClient) {
      const allChildClients = await getChildClients();
      setChildClients(allChildClients);
    }
  }, [selectedParentClient]);

  return (
    <>
      <Autocomplete
        options={getDropdownOptions()}
        id={`Select-Client ${isChildClient}`}
        value={isChildClient ? selectedChildClient || '' : selectedParentClient || ''}
        onChange={(event, value) => {
          if (isChildClient) {
            setSelectedChildClient(value);
            handleDeleteDynamicPartOfUrl();
          } else {
            setSelectedParentClient(value);
            setActiveJob({});
          }
        }}
        getOptionLabel={(option) => option.label || ''}
        renderOption={(props, option) => {
          const isSelected = isChildClient ? selectedChildClient === option.value : selectedParentClient === option.value;

          return (
            <li {...props} style={{ color: isActive(option.value) ? isSelected && '#cd525b' : '#cd525b' }}>
              {option.label}
            </li>
          );
        }}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              label={isChildClient ? 'Select a Child Client ..' : 'Select a Parent Client ..'}
              variant="standard"
            />
          );
        }}
      />
    </>
  );
}

export default ClientDropdown;
