import {
  AppBar,
  Button,
  Checkbox,
  Chip,
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { styles } from '../Credentials.styles';

function AmazonProfilesAndAdvertisersCleanUp({ data, isProfileComponent, modifiedCredential, setModifiedCredential }) {
  const [checkedItems, setCheckedItems] = useState([]);
  const [selectedJson, setSelectedJson] = useState([]);
  const [isEnabledSaveButton, setIsEnabledSaveButton] = useState(true);

  const handleToggle = (value) => () => {
    const currentIndex = checkedItems.indexOf(value);
    const newChecked = [...checkedItems];

    if (currentIndex === -1) {
      newChecked.push(value);
      setSelectedJson((prevSelectedJson) => {
        if (!prevSelectedJson.includes(data[value])) {
          return [...prevSelectedJson, data[value]];
        }
        return prevSelectedJson;
      });
    } else {
      newChecked.splice(currentIndex, 1);
      setSelectedJson((prevSelectedJson) => prevSelectedJson.filter((item) => item !== data[value]));
    }

    setCheckedItems(newChecked);
  };

  const handleSave = () => {
    isProfileComponent
      ? setModifiedCredential({
          ...modifiedCredential,
          parameterValues: {
            ...modifiedCredential.parameterValues,
            Profiles: JSON.stringify(selectedJson),
          },
        })
      : setModifiedCredential({
          ...modifiedCredential,
          parameterValues: {
            ...modifiedCredential.parameterValues,
            Advertisers: JSON.stringify(selectedJson),
          },
        });

    setIsEnabledSaveButton(false);
  };

  const handleRefresh = () => {
    setCheckedItems([]);
    setSelectedJson([]);
    setIsEnabledSaveButton(true);
  };

  return (
    <div style={{ height: '400px', display: 'flex' }}>
      <div style={{ flex: 1, padding: '20px', overflowY: 'auto', borderRight: '1px solid #ddd' }}>
        <Typography variant="h5">All {isProfileComponent ? ' Profiles' : 'Advertisers'}</Typography>
        <List>
          {data.map((item, index) => (
            <ListItem key={index} button onClick={handleToggle(index)}>
              <ListItemIcon>
                <Checkbox edge="start" checked={checkedItems.indexOf(index) !== -1} tabIndex={-1} disableRipple />
              </ListItemIcon>
              <ListItemText
                primary={
                  <div style={{ display: 'flex', alignItems: 'center', margin: 0, padding: 0 }}>
                    <Typography style={{ margin: 0, padding: 0 }}>
                      {isProfileComponent ? item.accountInfo.name : item.name}
                    </Typography>
                    <Chip
                      label={isProfileComponent ? item.accountInfo.type : item.country}
                      style={{ marginLeft: '8px', padding: 0 }}
                    />
                  </div>
                }
                style={{ margin: 0, padding: 0, border: 2 }}
              />
            </ListItem>
          ))}
        </List>
      </div>
      <div style={{ flex: 1, padding: '20px', overflowY: 'auto' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h5">Cleaned up {isProfileComponent ? ' Profiles' : 'Advertisers'}</Typography>
          <Tooltip title={isProfileComponent ? 'Reset Profiles selection' : 'Reset Advertisers selection'} placement="bottom">
            <AutorenewIcon onClick={handleRefresh} />
          </Tooltip>
        </div>
        <Typography style={{ whiteSpace: 'pre-wrap', fontFamily: 'monospace', marginBottom: 6 }}>
          {JSON.stringify(selectedJson, null, 2)}
        </Typography>
        <Button variant="contained" color="primary" onClick={handleSave} disabled={!isEnabledSaveButton}>
          Save changes
        </Button>
      </div>
    </div>
  );
}

export default AmazonProfilesAndAdvertisersCleanUp;
