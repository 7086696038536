import { Button, Divider, Grid, Box, Link, Breadcrumbs, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { getDataRuns } from '../../../Utilities/ForemanApiClient';
import { dataRunsColumns } from '../../Components/TableColumns/dataRunsColumns';
import moment from 'moment';
import { styles } from './dataRunsStyles';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { BasicTable } from '../../Components/Table/Table';
import { downloadArrayDataCSV } from '../../Utils/JobRunUtilities';
import { showSnackbar } from '../../Utils/showSnackBar';
import CircularProgress from '@mui/material/CircularProgress';
import { useParams } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

export default function DataRuns() {
  const params = useParams();
  const id = params.id;
  const startTime = params.startTime;
  const [sortDirection, setSortDirection] = useState('ascending');
  const [sort, setSort] = useState('');
  const [filters, setFilters] = useState('');
  const [exportLoading, setExportLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [searchValues, setSearchValues] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [filteredDataRuns, setFilteredDataRuns] = useState(null);
  const [timer, setTimer] = useState(null);

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      sx={{
        '&:hover': {
          color: 'inherit',
        },
      }}
      href="/V2/jobruns"
    >
      Job Runs
    </Link>,
    <Typography key="3" color="text.primary">
      Data Runs
    </Typography>,
  ];

  const getFilteredRuns = async (limit, offset, paginationFilters, paginationSort, paginationSortDirection, excludeFilter) => {
    setSort(paginationSort);
    setSortDirection(paginationSortDirection);
    setFilters(paginationFilters);

    return await getDataRuns(
      moment(startTime).format('MM-DD-YYYY'),
      moment(new Date()).format('MM-DD-YYYY'),
      limit,
      offset,
      paginationFilters,
      paginationSort,
      paginationSortDirection,
      excludeFilter
    );
  };

  const getRunsForDownload = async () => {
    setExportLoading(true);

    await getDataRuns(
      moment(startTime).format('MM-DD-YYYY'),
      moment(new Date()).format('MM-DD-YYYY'),
      0,
      0,
      filters,
      sort,
      sortDirection,
      false
    ).then((response) => {
      if (response?.length !== 0) downloadArrayDataCSV(false, response.records, `DataRuns_For_JobRun_${id}.csv`);
      else {
        showSnackbar('error', `The data export process timed out due to the large data size. Please try again using filters`);
      }
    });

    setExportLoading(false);
  };

  const getFilters = () => {
    let filters = searchValues
      ? Object.entries(searchValues)
          .map(([key, value]) => {
            if (value?.length > 0) return `&${key}=${value}`;
          })
          .join('')
      : '';
    filters = filters.concat(`&jobRunId=${id}`);
    return filters;
  };

  useEffect(async () => {
    clearTimeout(timer);

    const newTimer = setTimeout(async () => {
      setIsLoading(true);
      let response = await getFilteredRuns(rowsPerPage, page * rowsPerPage, getFilters(), '', 'Ascending', false);
      if (response) {
        setCount(response.totalFilteredRecords ? response.totalFilteredRecords : 0);
        setFilteredDataRuns(response.records);
      }
      setIsLoading(false);
    }, 500);

    setTimer(newTimer);
  }, [searchValues, page, rowsPerPage]);

  return (
    <>
      <Grid container rowSpacing={0}>
        <Grid item xs={6} align="left">
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </Grid>
        <Grid item xs={6} align="right">
          <Button
            variant="contained"
            outline
            disabled={exportLoading}
            sx={styles.DownloadButton}
            startIcon={!exportLoading ? <CloudDownloadIcon sx={styles.DownloadIcon} /> : null}
            onClick={() => {
              !exportLoading && getRunsForDownload();
            }}
          >
            {exportLoading && (
              <div style={{ width: 'inherit' }}>
                <Box>
                  <CircularProgress size={20} />
                </Box>
              </div>
            )}
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ mt: 1, mb: 1 }} />
      </Grid>
      <Grid item xs={12}>
        <BasicTable
          data={filteredDataRuns}
          columns={dataRunsColumns}
          actionButtons={null}
          setSearchValues={setSearchValues}
          searchValues={searchValues}
          isLoading={isLoading}
          isFullHeight={true}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          page={page}
          setPage={setPage}
          count={count}
          hasSelectionCheckboxes={false}
        />
      </Grid>
    </>
  );
}
