import React, { useState, useEffect, useMemo } from 'react';
import { Divider, Grid, Tab, Typography, Tooltip } from '@mui/material';
import {
  patchJobGroup,
  runJobGroupJobsByTemplate,
  getJobGroupById,
  getJobInterfacesByServiceType,
} from '../../../Utilities/ForemanApiClient';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import TabList from '@mui/lab/TabList';
import JobGroupTemplatesActionButtons from '../../Components/TableActionButtons/JobGroupTemplatesActionButtons';
import AddIcon from '@mui/icons-material/Add';
import { BasicTable } from '../../Components/Table/Table';
import { jobTemplatesColumns } from '../../Components/TableColumns/jobTemplateColumns';
import { filterTableData } from '../../Utils/filterTableData';
import { styles } from '../job-templates/jobTemplates.styles';
import BasicConfirmationModal from '../../Components/DeleteComponent/ConfirmationModal';
import { showSnackbar } from '../../Utils/showSnackBar';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

export default function JobGroupTemplateManager({ jobGroup, jobTemplates, setIsEditTemplateMode }) {
  const [value, setValue] = useState('0');
  const [activeJobTemplates, setActiveJobTemplates] = useState([]);
  const [filteredJobTemplates, setFilteredJobTemplates] = useState([]);
  const [searchValues, setSearchValues] = useState({});
  const [modifiedJobGroup, setModifiedJobGroup] = useState({});
  const [filteredUnusedJobTemplates, setFilteredUnusedJobTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [showRunModal, setShowRunModal] = useState(false);
  const [showRemoveTemplateModal, setShowRemoveTemplateModal] = useState(false);
  const [showAddTemplateModal, setShowAddTemplateModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [interfaces, setInterfaces] = useState([]);

  let templatesColumns = jobTemplatesColumns.filter((column) => column.Header !== 'Retailer');

  const handleValueChange = (event, newValue) => {
    setValue(newValue);
    setSearchValues({});
  };

  function isSystemHarvester(template, interfaces) {
    return interfaces.find((jobInterface) => jobInterface.className === template.className && jobInterface.systemHarvester);
  }

  function isMatchingRetailerServiceType(template, interfaces) {
    const result = interfaces.find((jobInterface) => jobInterface.className === template.className);
    return result && (result.retailerServiceType == jobGroup.retailerServiceType || result.retailerType == 99);
  }

  useEffect(async () => {
    const interfaces = await getJobInterfacesByServiceType(jobGroup.retailerServiceType);
    setInterfaces(interfaces);
  }, []);

  const unusedTemplates = useMemo(() => {
    let unusedData =
      modifiedJobGroup && modifiedJobGroup.jobTemplateIds
        ? jobTemplates?.filter(
            (template) =>
              !modifiedJobGroup.jobTemplateIds.includes(template.id) && template.retailerType == modifiedJobGroup.retailerType
          )
        : jobTemplates?.filter((template) => template.retailerType == modifiedJobGroup.retailerType);
    return unusedData?.filter(
      (template) => isMatchingRetailerServiceType(template, interfaces) || isSystemHarvester(template, interfaces)
    );
  }, [jobTemplates, modifiedJobGroup, interfaces]);

  const actionButtons = (jobTemplate) => {
    return JobGroupTemplatesActionButtons({ handleRunOnClick, handleRemoveClick, jobTemplate });
  };

  const addActionButtons = (jobTemplate) => {
    return (
      <>
        <Tooltip title="Assign Template " placement="left">
          <AddIcon sx={styles.ActionButtonsRun} onClick={() => handleAddClick(jobTemplate)} />
        </Tooltip>
      </>
    );
  };

  const handleRunOnClick = async (jobTemplate) => {
    setSelectedTemplate(jobTemplate);
    setShowRunModal(true);
  };

  const confirmedRunAction = async () => {
    await runJobGroupJobsByTemplate(modifiedJobGroup.id, selectedTemplate.id);
    setShowRunModal(false);
  };

  const handleAddClick = (jobTemplate) => {
    setSelectedTemplate(jobTemplate);
    setShowAddTemplateModal(true);
  };

  const addJobTemplate = async () => {
    const { jobTemplateIds } = modifiedJobGroup;
    const newTemplateIds = [...jobTemplateIds, selectedTemplate.id];
    const newTemplates = [...modifiedJobGroup.jobTemplates, selectedTemplate];
    setShowAddTemplateModal(false);

    await patchJobGroup(modifiedJobGroup.id, '/jobTemplateIds', newTemplateIds).then((response) => {
      if (!response) {
        showSnackbar(
          'error',
          `there was a problem adding the job template, Please try again later; if the issue persists, contact Dev : foremandev@harvestgroup.com.`
        );
      } else {
        setFilteredUnusedJobTemplates(filteredUnusedJobTemplates.filter((template) => template.id != selectedTemplate.id));
        setFilteredJobTemplates((templates) => templates.concat([selectedTemplate]));
        setActiveJobTemplates((templates) => templates.concat([selectedTemplate]));
        setModifiedJobGroup({ ...modifiedJobGroup, jobTemplateIds: newTemplateIds, jobTemplates: newTemplates });
        showSnackbar('success', 'Added successfully!');
      }
    });
  };

  const handleRemoveClick = (jobTemplate) => {
    setSelectedTemplate(jobTemplate);
    setShowRemoveTemplateModal(true);
  };

  const removeJobTemplate = async () => {
    const newTemplateIds = modifiedJobGroup.jobTemplateIds.filter((id) => id != selectedTemplate.id);
    setShowRemoveTemplateModal(false);

    const response = await patchJobGroup(modifiedJobGroup.id, '/jobTemplateIds', newTemplateIds);

    const newTemplates = modifiedJobGroup.jobTemplates.filter((template) => template.id != selectedTemplate.id);

    if (response == true) {
      setModifiedJobGroup({ ...modifiedJobGroup, jobTemplateIds: newTemplateIds, jobTemplates: newTemplates });
      setFilteredJobTemplates(filteredJobTemplates.filter((template) => template.id != selectedTemplate.id));
      showSnackbar('success', 'removed successfully!');
    } else {
      showSnackbar(
        'error',
        `there was a problem removing the job template, Please try again later; if the issue persists, contact Dev : foremandev@harvestgroup.com.`
      );
    }
  };

  useEffect(() => {
    setFilteredUnusedJobTemplates(unusedTemplates?.filter((template) => template.retailerType == modifiedJobGroup.retailerType));
  }, [unusedTemplates, modifiedJobGroup]);

  useEffect(async () => {
    setIsLoading(true);
    setModifiedJobGroup(jobGroup);
    const response = await getJobGroupById(jobGroup.id);
    setActiveJobTemplates(response.jobTemplates);
    setFilteredJobTemplates(response.jobTemplates);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (activeJobTemplates) {
      const tableAsArray = filterTableData(activeJobTemplates, searchValues);
      setFilteredJobTemplates(tableAsArray);
    }
    if (unusedTemplates) {
      const tableAsArray = filterTableData(unusedTemplates, searchValues);
      setFilteredUnusedJobTemplates(tableAsArray);
    }
  }, [searchValues]);

  return (
    <>
      <TabContext value={value}>
        <Grid container>
          <Grid item xs={6} align="left">
            <span style={{ cursor: 'pointer' }} onClick={() => setIsEditTemplateMode(false)}>
              <NavigateBeforeIcon />
            </span>
            {jobGroup?.name}
          </Grid>
        </Grid>
        <TabList onChange={handleValueChange} aria-label="jobTemplates_tabs" sx={styles.JobTemplatesTabs}>
          <Tab label="Assigned Templates" value="0" />
          <Tab label="Unassigned Templates" value="1" />
        </TabList>
        <TabPanel value="1" sx={styles.TabPanels}>
          <Typography variant="h7" sx={{ textAlign: 'center' }}>
            <BasicTable
              data={filteredUnusedJobTemplates}
              columns={templatesColumns}
              actionButtons={addActionButtons}
              setSearchValues={setSearchValues}
              searchValues={searchValues}
              isFullHeight={true}
              noPagination={true}
              isLoading={isLoading}
              hasSelectionCheckboxes={false}
            />
          </Typography>
        </TabPanel>
        <TabPanel value="0" sx={styles.TabPanels}>
          <Typography variant="h7" sx={{ textAlign: 'center' }}>
            <BasicTable
              data={filteredJobTemplates}
              columns={templatesColumns}
              actionButtons={actionButtons}
              setSearchValues={setSearchValues}
              searchValues={searchValues}
              isFullHeight={true}
              noPagination={true}
              isLoading={isLoading}
              hasSelectionCheckboxes={false}
            />
          </Typography>
          <Divider sx={styles.Dividers} />
        </TabPanel>
      </TabContext>
      <BasicConfirmationModal
        modalHeader={<>Confirm Should Run Now</>}
        modalBody={<>Are you sure you want to run all jobs?</>}
        warningMessage={
          <>{`Clicking yes will set all Jobs with Job Template "${selectedTemplate?.name}" and Job Group "${modifiedJobGroup?.name}" to run.`}</>
        }
        setShowModal={setShowRunModal}
        showModal={showRunModal}
        confirmedAction={confirmedRunAction}
        enabled={true}
      />
      <BasicConfirmationModal
        modalHeader={<>Confirm Job Template Change</>}
        modalBody={<>Are you sure you want to remove this Job Template?</>}
        warningMessage={
          <>{`This will remove this Job for all Credentials that use this Job Group.
          `}</>
        }
        setShowModal={setShowRemoveTemplateModal}
        showModal={showRemoveTemplateModal}
        confirmedAction={removeJobTemplate}
        enabled={true}
      />
      <BasicConfirmationModal
        modalHeader={<>Confirm Job Template Change</>}
        modalBody={<>Are you sure you want to Add this Job Template?</>}
        warningMessage={
          <>{`This will add this Job for all Credentials that use this Job Group.
          `}</>
        }
        setShowModal={setShowAddTemplateModal}
        showModal={showAddTemplateModal}
        confirmedAction={addJobTemplate}
        enabled={true}
      />
    </>
  );
}
