import React, { createContext, useState, useEffect } from 'react';
import { getCredentialsWithBadPasswords } from '../../Utilities/ForemanApiClient';

export const CredentialsContext = createContext();

export const CredentialsWithBadPasswordProvider = ({ children }) => {
  const [credentialsWithBadPassword, setCredentialsWithBadPassword] = useState([]);

  const getCredentialsWithBadPassword = async () => {
    const credentials = await getCredentialsWithBadPasswords();
    setCredentialsWithBadPassword(credentials);
  };

  useEffect(() => {
    const interval = setInterval(getCredentialsWithBadPassword, 2 * 60 * 1000);

    getCredentialsWithBadPassword();

    return () => {
      clearInterval(interval);
    };
  }, []);

  const contextValue = {
    credentialsWithBadPassword,
    getCredentialsWithBadPassword,
  };

  return <CredentialsContext.Provider value={contextValue}>{children}</CredentialsContext.Provider>;
};
