import { Box, Typography } from '@mui/material';
import React from 'react';
import BrowserUpdatedOutlinedIcon from '@mui/icons-material/BrowserUpdatedOutlined';

const EmptyBox = (props) => {
  return (
    <Box
      sx={{
        height: '30vh',
        border: '2px dashed',
        borderRadius: '10px',
        flexDirection: 'column',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderColor: 'text.secondary',
      }}
    >
      <Box sx={{ marginRight: '10px', display: 'block' }}>
        <BrowserUpdatedOutlinedIcon sx={{ fontSize: 64, opacity: 0.75 }} />
      </Box>
      <Typography>Select a Job Run to show the list of Data Runs</Typography>
    </Box>
  );
};
export default EmptyBox;
